import React from "react";
import profileIcon from "./../../assets/defaultprofile.jpeg";
import schoolIcon from "./../../assets/logo.png";
import { Col, Row, Typography, QRCode, List, Avatar } from "antd";

import { MdCheckCircle } from "react-icons/md";
import { PiScissorsFill } from "react-icons/pi";

const styles = {
  flexCenter: {
    display: "flex",
    justifyContent: "flexStart",
  },
  flexCenters: {
    display: "flex",
    justifyContent: "center",
  },
  wave: {
    position: "absolute",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    marginTop: -10,
    width: 60,
    height: 45,
    objectFit: "cover",
    marginBottom: 15,
  },
  title: {
    backgroundColor: "#fff",
    textAlign: " center",
    display: "flex",
    justifyContent: "center",
    lineHeight: 1.1,
    marginTop: 22,
  },
  borderStyle: {
    padding: 4,
    width: "160px",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    position: "relative",
    top: -10,
  },
  topBorderStyle: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    height: "1px",
    backgroundImage: "linear-gradient(to right, #000 20%, transparent 40%)",
  },
  bottomBorderStyle: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    height: "1px",
    backgroundImage: "linear-gradient(to left, #000 20%, transparent 40%)",
  },
  profileImageContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  imageDiv: {
    height: 60,
    width: 60,
    border: "4px solid #C2D0D1",
  },
  image: {
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
  },
  field: {
    marginTop: -9,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-etween",
  },
  detailsTitle: {
    // width:'120px',
    marginRight: "8px",
    color: "#474747",
    fontSize: "12px",
    // flex: 1,
    textAlign: "start",
  },
  value: {
    flex: 1,
    paddingBottom: "6px",
    borderBottom: "1px solid #000",
    textAlign: "start",
    fontSize: "12px",
  },
  svgContainer: {
    position: "relative",
    width: "100%",
    height: "58px",
    marginBottom: "-1px",
  },
  svg1: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  svg2: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  svg3: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  card: {
    height: "105mm",
    width: "66.80mm",
    position: "relative",
  },
};

const BusPdf = ({ details, contentRef }) => {
  const { Title, Text } = Typography;

  const filterRequiredKey = (obj) => {
    if (obj == null) {
      return;
    } else {
      const { _id, vanNo } = obj;
      return { _id, vanNo };
    }
  };

  const filteredObj = filterRequiredKey(details);

  const searchParams = new URLSearchParams(filteredObj);
  const queryString = searchParams.toString();

  const name = details?.driverName || "Smith";
  const organizationTitle = details?.organizationTitle || "Nubit Software";
  const RegistrationNum = `${details?.vanReg}` || "Grade X";
  const VanNum = `${details?.vanNo}` || "abc-1234";
  //   const parentName = details?.parentName || "John Doe";
  const imageSrc = details?.image || schoolIcon;
  const profileImageSrc = details?.profile || profileIcon;
  const id = details?._id || "Id not be generated";
  const rollnum = `Van # ${details?.vanNo}` || "Van # 1";
  const note =
    details?.note ||
    "Note: This card is for organization premises only. If found please return to organization";

  return (
    <div>
      <div
        id="content"
        ref={contentRef}
        style={{
          width: "210mm",
          height: "297mm",
          // padding: "20mm",
          boxSizing: "border-box",
          backgroundColor: "#fff",
          overflow: "hidden",
        }}
      >
        <Row
          gutter={24}
          style={{
            padding: "20px",
            ...styles.flexCenter,
          }}
        >
          {/* <Col
            span={12}
            style={{ ...styles.flexCenter, borderRight: "1px dashed #000" }}
          >
            <section
              style={{
                ...styles.card,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                  style={{ ...styles.wave, zIndex: 99 }}
                >
                  <path
                    fill="#C2D0D1"
                    fillOpacity="1"
                    d="M0,256L120,213.3C240,171,480,85,720,58.7C960,32,1200,64,1320,80L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                  ></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                  style={styles.wave}
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,224L120,192C240,160,480,96,720,106.7C960,117,1200,203,1320,245.3L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                  ></path>
                </svg>
              </div>

              <section>
                <picture style={styles.logoContainer}>
                  <img style={styles.logo} src={imageSrc} alt="Logo" />
                </picture>

                <Title level={4} style={styles.title}>
                  <span style={styles.borderStyle}>
                    <span style={styles.topBorderStyle}></span>
                    <span style={styles.bottomBorderStyle}></span>
                    <span
                      style={{
                        position: "relative",
                        top: "-10px",
                        fontSize: "18px",
                      }}
                    >
                      {organizationTitle}
                    </span>
                  </span>
                </Title>

                <picture style={styles.profileImageContainer}>
                  <span style={styles.imageDiv}>
                    <img
                      style={styles.image}
                      src={profileImageSrc}
                      alt="profile image"
                    />
                  </span>
                </picture>

                <Title
                  level={2}
                  style={{
                    textAlign: "center",
                    marginTop: 14,
                    fontSize: "18px",
                    textTransform: "capitalize",
                  }}
                >
                  {name}
                </Title>

                <Title
                  level={4}
                  style={{
                    textAlign: "center",
                    marginTop: -6,
                    fontSize: "14px",
                  }}
                >
                  {rollnum}
                </Title>

                <Title
                  level={2}
                  style={{
                    textAlign: "center",
                    marginTop: 4,
                    fontSize: "18px",
                    textTransform: "uppercase",
                  }}
                >
                  {classGrade}
                </Title>
              </section>

              <div style={styles.svgContainer}>
                <svg
                  style={styles.svg1}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,320L720,320L1440,128L1440,320L720,320L0,320Z"
                  ></path>
                </svg>

                <svg
                  style={styles.svg3}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,320L220,32L1440,380L1440,320L720,320L0,320Z"
                  ></path>
                </svg>

                <svg
                  style={styles.svg2}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#C2D0D1"
                    fillOpacity="1"
                    d="M0,320L240,102L1440,320L1440,320L720,320L0,320Z"
                  ></path>
                </svg>
              </div>
            </section>
          </Col> */}

          <Col span={12} style={{ ...styles.flexCenter }}>
            <section
              style={{
                ...styles.card,
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                  style={{ ...styles.wave, zIndex: 99 }}
                >
                  <path
                    fill="#C2D0D1"
                    fillOpacity="1"
                    d="M0,256L120,213.3C240,171,480,85,720,58.7C960,32,1200,64,1320,80L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                  ></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                  style={styles.wave}
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,224L120,192C240,160,480,96,720,106.7C960,117,1200,203,1320,245.3L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                  ></path>
                </svg>
              </div>

              <div style={{ padding: "0px 10px", marginTop: 58 }}>
                <picture style={styles.logoContainer}>
                  <img style={styles.logo} src={imageSrc} alt="Logo" />
                </picture>

                <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Driver Name:</span>
                  <span style={styles.value}>{name}</span>
                </Title>

                {/* <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Father Name:</span>
                  <span style={styles.value}>{parentName}</span>
                </Title> */}

                <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Van Registration No:</span>
                  <span style={styles.value}>{RegistrationNum}</span>
                </Title>

                <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Van No:</span>
                  <span style={styles.value}>{VanNum}</span>
                </Title>

                <div style={{ ...styles.flexCenters, margin: "12px 0px" }}>
                  <QRCode
                    errorLevel="Q"
                    value={queryString}
                    // icon={imageSrc}
                    size={90}
                    style={{ padding: 4, border: 0, marginTop: 4 }}
                  />
                </div>

                <div
                  style={{
                    margin: "8px 0px",
                    lineHeight: "12px",
                  }}
                >
                  <Text
                    strong
                    style={{
                      fontSize: "8px",
                      lineHeight: "4px",
                    }}
                  >
                    {note}
                  </Text>
                </div>

                <div
                  style={{
                    margin: "8px 0px",
                    lineHeight: "12px",
                  }}
                >
                  <div className="flex justify-center gap-1">
                    <p className="text-[12px] mt-[5px]">Powered by</p>{" "}
                    <img
                      src={imageSrc}
                      style={{
                       width:"40px",
                       margin: "8px 0px",
                      
                      }}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  ...styles.svgContainer,
                  position: "absolute",
                  bottom: "0px",
                }}
              >
                <svg
                  style={styles.svg1}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,320L720,320L1440,128L1440,320L720,320L0,320Z"
                  ></path>
                </svg>

                <svg
                  style={styles.svg3}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,320L220,32L1440,380L1440,320L720,320L0,320Z"
                  ></path>
                </svg>

                <svg
                  style={styles.svg2}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#C2D0D1"
                    fillOpacity="1"
                    d="M0,320L240,102L1440,320L1440,320L720,320L0,320Z"
                  ></path>
                </svg>
              </div>
            </section>
          </Col>
        </Row>

        {/* <div style={{ marginTop: 24 }}>
          {term.map((v, index) => (
            <div
              key={index}
              style={{ display: "flex", gap: 10, marginTop: 12 }}
            >
              <div style={{ marginTop: 8 }}>
                <MdCheckCircle style={{ fontSize: "20px" }} />
              </div>
              <Title level={5} style={{ textAlign: "start" }}>
                {v}
              </Title>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default BusPdf;
