import React, { useContext } from "react";
import "../css/classes.css";
import { ArrivalContext } from "../../../context/arrivalScreen/arrivalcontext";
const Class2 = () => {
  const { arrivals } = useContext(ArrivalContext);
  return (
    <div className="class-main">
      <h1 className="class-head">Class 2</h1>
      <div className="class-student-name">
        <table>
          <tbody>
            <tr>
              {arrivals
                .filter(
                  (item) =>
                    item.arrivalType === "Student" &&
                    item.student?.classGrade === "2"
                )
                .map((arrival) => (
                  <td>{`${arrival.student?.name} (${arrival.student?.classGrade} - ${arrival.student?.section})`}</td>
                ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Class2;
