import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import AdminDashboard from "../pages/AdminDashboard";
import StudentManagement from "../pages/StudentManagement";
import BusManagement from "../pages/BusManagement";
import ArrivalManagement from "../pages/ArrivalManagement";
import AnnouncementManagement from "../pages/AnnouncementManagement";
import EmployeeRegistration from "../pages/EmployeeRegistration";
import Attendance from "../pages/attendance";
import NotFound from "../pages/NotFound";
import Login from "../pages/Login";
import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";
import BusReports from "../pages/reports/BusReports";
import ArrivalScreen from "../pages/arrivalScreen";
import AnnouncementScreen from "../pages/announcementScreen";
import ArrivalScreens from "../pages/arrivalScreens";
import AttendanceReports from "../pages/reports/AttendanceReport";
import TeacherList from "../pages/TeacherList";
import TeacherAttendance from "../pages/TeacherAttendance";
import TeacherReport from "../pages/reports/TeacherReport";

const Router = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const isAuthenticated = localStorage.getItem("authToken") !== null;
  const isUser = JSON.parse(localStorage.getItem("userdetails"));
  const find001 = isUser?.userId === "001";
  const find002 = isUser?.userId === "002";
  const findAdmin = isUser?.userType === "admin";
  const findTeacher = isUser?.userType === "teacher";
  const findGateKeeper = isUser?.userType === "gatekeeper";

  useEffect(() => {
    setShowSidebar(false);
  }, [location]);

  const isLoginPage =
    location.pathname === "/" ||
    location.pathname === "/arrivalScreen" ||
    location.pathname === "/announcementScreen" ||
    location.pathname === "/arrivalScreens" ;

  const redirectUser = () => {
    if (find001) return "/arrivalScreens";
    if (find002) return "/announcementScreen";
    if (findTeacher) return "/attendance";
    if (findGateKeeper) return "/arrivals";
    return null;
  };

  const restrictedAccess = (element) => {
    const userRedirect = redirectUser();
    if (!findAdmin && userRedirect && location.pathname !== userRedirect) {
      return <Navigate to={userRedirect} />;
    }
    return element;
  };
  const locations = window.location.pathname;
  return (
    <>
      {!isLoginPage && <Header toggleSidebar={toggleSidebar} />}
      {!isLoginPage && showSidebar && <Sidebar show={showSidebar} />}
      <Routes>
        <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/dashboard" element={ isAuthenticated ? (
              find001 ? (
                <Navigate to="/arrivalScreens" />
              ) : find002 ? ( 
                <Navigate to="/announcementScreen" />
              ) : findAdmin ? (
                <AdminDashboard />
              ) : (
                restrictedAccess(<AdminDashboard />)
              )
            ) : (
              <Navigate to="/" />
            )}
        />
        <Route path="/students" element={
            isAuthenticated && findAdmin ? (
              <StudentManagement />
            ) : (
              restrictedAccess(<StudentManagement/>)
            )}
        />
        <Route path="/teachers" element={
            isAuthenticated && findAdmin ? (
              <TeacherList />
            ) : (
              restrictedAccess(<TeacherList />)
            )}
        />
        <Route path="/bus" element={
            isAuthenticated && findAdmin ? (
              <BusManagement />
            ) : (
              restrictedAccess(<BusManagement />)
            )}
        />
        <Route path="/teacherattendance" element={
            isAuthenticated && findAdmin ? (
              <TeacherAttendance/>
            ) : (
              restrictedAccess(<TeacherAttendance/>)
            )}
        />
        <Route path="/arrivals" element={
            isAuthenticated && (findAdmin || findGateKeeper) ? (
              <ArrivalManagement />
            ) : (
              restrictedAccess(<ArrivalManagement />)
            )}
        />
        <Route path="/announcements" element={
            isAuthenticated && findAdmin ? (
              <AnnouncementManagement />
            ) : (
              restrictedAccess(<AnnouncementManagement />)
            )}
        />
        <Route path="/registerEmployee" element={
            isAuthenticated && findAdmin ? (
              <EmployeeRegistration />
            ) : (
              restrictedAccess(<EmployeeRegistration />)
            )}
        />
        <Route
          path="/attendance"
          element={
            isAuthenticated && (findAdmin || findTeacher) ? (
              <Attendance />
            ) : (
              restrictedAccess(<Attendance />)
            )}
        />
        <Route path="/busReport" element={
            isAuthenticated && findAdmin ? (
              <BusReports />
            ) : (
              restrictedAccess(<BusReports />)
            )}
        />
        <Route path="/attendanceReport" element={
            isAuthenticated && findAdmin ? (
              <AttendanceReports/>
            ) : (
              restrictedAccess(<AttendanceReports />)
            )}
        />
        <Route path="/teacherReport" element={
            isAuthenticated && findAdmin ? (
              <TeacherReport/>
            ) : (
              restrictedAccess(<TeacherReport/>)
            )}
        />
        <Route path="/arrivalScreen" element={
            find001 || findAdmin ? <ArrivalScreen /> : <Navigate to="/dashboard" />
          }
        />
        <Route path="/arrivalScreens" element={
            find001 || findAdmin ? <ArrivalScreens /> : <Navigate to="/dashboard" />
          }
        />
        <Route path="/announcementScreen" element={
            find002 || findAdmin ? <AnnouncementScreen /> : <Navigate to="/dashboard" />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Router;
