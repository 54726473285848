import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";
import { ScannerQRProvider } from "./context/scannerqr";
import { FetchProvider } from "./context/fetch";
import { ArrivalProvider } from "./context/arrivalScreen/arrivalcontext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ScannerQRProvider>
      <FetchProvider>
        <ArrivalProvider>
          <App />
        </ArrivalProvider>
      </FetchProvider>
    </ScannerQRProvider>
  </React.StrictMode>
);
