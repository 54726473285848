// import React from "react";
// import bannerImage from "../../assets/banner.jpg";
// import NavLogo from "./Logo";
// import NavText from "./NavText";
// import Clock from "./Clock";
// import { useNavigate } from "react-router-dom";
// import { RiLogoutBoxLine } from "react-icons/ri";
// const Navbar = () => {
//   const banner = {
//     backgroundImage: `url(${bannerImage})`,
//     backgroundSize: "cover",
//     backgroundRepeat: "no-repeat",
//     backgroundPosition: "center center",
//     overflow: "hidden",
//   };
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     localStorage.clear();
//     setTimeout(() => {
//       navigate("/");
//     }, 2000);
//   };

//   return (
//     <>
//       <div
//         className="navbar w-full flex h-[10vh] md:h-[15vh] lg:h-[20vh] bg-black justify-between   items-center border-black border-2"
//         style={banner}
//       >
//         <NavLogo />
//         <NavText />
//           <Clock />
//         {/* <div className="flex ">
//           <button
//             onClick={handleLogout}
//             className="text-white focus:outline-none px-3 hover:text-gray-600 transition-all duration-300"
//           >
//             <RiLogoutBoxLine size={24} />
//           </button>
//         </div> */}
//       </div>
//     </>
//   );
// };

// export default Navbar;

import React from "react";
import Clock from "./Clock";
import { useNavigate } from "react-router-dom";
import { RiLogoutBoxLine } from "react-icons/ri";
import Logo from "../../assets/schoollogo.png";
import NubitLogo from "../../assets/logo.png";
import BG from "../../assets/banner.jpg";

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };
  return (
    <div
      className="h-[10vh] sm:h-[14vh] md:h-[17vh] sm: w-full m-0 bg-cover bg-no-repeat flex justify-between items-center text-white"
      style={{ backgroundImage: `url(${BG})` }}
    >
      <div className=" bg-white w-[13vh] h-[10vh] sm:w-[17vh] sm:h-[14vh] md:w-[25vh] md:h-[17vh]">
        <img src={Logo} className="m-auto w-[full] h-full" alt="" />
      </div>
      <div>
        <h1 className="hidden sm:flex justify-center items-center overflow-hidden text-white sm:text-3xl font-bold">
          Tipu Sultan Branch
        </h1>
      </div>
      <div className="lg:mr-[80px] flex flex-col gap-3 items-center">
        <div className="flex gap-3 items-center bg-zinc-400 p-2">
          <p className="text-black font-bold text-sm ">Powered By</p>
          <img src={NubitLogo} alt="" width={70}/>
        </div>
        <div className="flex">
          <Clock />
          <button
            onClick={handleLogout}
            className="text-white focus:outline-none px-3 hover:text-gray-600 transition-all duration-300"
          >
            <RiLogoutBoxLine size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
