import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Select, message } from "antd";
import moment from "moment";
import image from "../assets/loginImg.jpg";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import axios from "axios";
import "./CSS/dashboard.css";
import QRScanner from "../components/common/QrModal";
import { ScannerQRContext } from "../context/scannerqr";
import { Link } from "react-router-dom";
import { set } from "react-hook-form";
import { sectionColors } from "../components/constants/constant";
import Footer from "../components/common/Footer";
// import io from "socket.io-client";

const ArrivalManagement = () => {
  // const socket = io("https://apischool.lineup.pk");
  const { id, setId } = useContext(ScannerQRContext);
  const { state, setState } = useContext(ScannerQRContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [arrivalsSearchQuery, setArrivalsSearchQuery] = useState("");
  const [students, setStudents] = useState([]);
  const [buses, setBuses] = useState([]);
  const [parentArrivals, setParentArrivals] = useState([]);
  const [vanArrivals, setVanArrivals] = useState([]);
  const [activeTab, setActiveTab] = useState("parents");
  const [selectedVans, setSelectedVans] = useState([]);
  const [deletedVans, setDeletedVans] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [deletedStudents, setDeletedStudents] = useState([]);
  const [selectAllStudents, setSelectAllStudents] = useState(false);
  const [selectAllVans, setSelectAllVans] = useState(false);
  const [selectAllParentArrivals, setSelectAllParentArrivals] = useState(false);
  const [selectAllVanArrivals, setSelectAllVanArrivals] = useState(false);
  const [parentReminders, setParentReminders] = useState([]);
  const [reminders, setReminders] = useState([]);
  const [error, setError] = useState(null);
  const [sentReminders, setSentReminders] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [vanArrived, setVanArrived] = useState([]);
  const [attendancedata, setAttendanceData] = useState([]);
  const [scanMessage, setScanMessage] = useState("");
  const parArrival = parentArrivals;
  const parents = JSON.parse(localStorage.getItem("parentArrivals"));
  const reminder = JSON.parse(localStorage.getItem("reminders"));
  const isAuthenticated = localStorage.getItem("authToken");
  const handleSelectAllParentArrivals = () => {
    setSelectAllParentArrivals(!selectAllParentArrivals);
    setDeletedStudents((prevStudentList) => {
      if (!selectAllParentArrivals) {
        return [...parentArrivals];
      } else {
        return [];
      }
    });

    const checkboxes = document.querySelectorAll(".parent-arrival-checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !selectAllParentArrivals;
    });
  };

  const handleSelectAllVanArrivals = () => {
    setSelectAllVanArrivals(!selectAllVanArrivals);
    setDeletedVans((prevVanList) => {
      if (!selectAllVanArrivals) {
        console.log("deletedVans", deletedVans);
        return [...vanArrivals];
      } else {
        console.log("deletedVans", deletedVans);
        return [];
      }
    });
    const checkboxes = document.querySelectorAll(".bus-arrival-checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !selectAllVanArrivals;
    });
  };
  const handleVanArrival = async (id) => {
    console.log("id", id);
    setSelectedVans((prevVanList) => {
      if (prevVanList.includes(id)) {
        return prevVanList.filter((van) => van !== id);
      } else {
        return [...prevVanList, id];
      }
    });
  };
  // Filter the students based on the attendance data
  const filteredStudents = students.filter((student) =>
    attendancedata.some((s) => s.students.some((stu) => stu.id === student._id))
  );
  const parentStudentIds =
    parents && parents.map((arrival) => arrival.student._id);
  const filteredFindStudent = filteredStudents.filter(
    (student) => !parentStudentIds.includes(student._id)
  );
  const vanArrivalIds = vanArrivals.map((arrival) => arrival.bus?._id);
  const filteredBuses = buses.filter((bus) => !vanArrivalIds.includes(bus._id));
  // console.log(filteredFindStudent);
  // setStudents(filteredStudents);
  // console.log("Filtered students:", filteredStudents);
  const handleStudentArrival = async (id) => {
    setSelectedStudents((prevStudentList) => {
      if (prevStudentList.includes(id)) {
        return prevStudentList.filter((student) => student !== id);
      } else {
        return [...prevStudentList, id];
      }
    });
  };

  const handleVanDeletion = async (arrival) => {
    if (selectAllVanArrivals) {
      setDeletedVans((prevVanList) => {
        if (prevVanList.some((van) => van._id === arrival._id)) {
          return prevVanList.filter((van) => van._id !== arrival._id);
        } else {
          return [...prevVanList, arrival];
        }
      });
    } else {
      setDeletedVans((prevVanList) => {
        if (prevVanList.includes(arrival)) {
          return prevVanList.filter((van) => van !== arrival);
        } else {
          return [...prevVanList, arrival];
        }
      });
    }
  };

  const handleStudentDeletion = async (arrival) => {
    if (selectAllParentArrivals) {
      setDeletedStudents((prevStudentList) => {
        if (prevStudentList.some((student) => student._id === arrival._id)) {
          return prevStudentList.filter(
            (student) => student._id !== arrival._id
          );
        } else {
          return [...prevStudentList, arrival];
        }
      });
    } else {
      setDeletedStudents((prevStudentList) => {
        if (prevStudentList.includes(arrival)) {
          return prevStudentList.filter((student) => student !== arrival);
        } else {
          return [...prevStudentList, arrival];
        }
      });
    }
  };

  let authToken = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchReminders = async () => {
      try {
        const response = await axios.get(
          "https://apischool.lineup.pk/reminder/allReminders",
          {
            maxBodyLength: Infinity, // This is necessary to prevent data truncation
          }
        );
        const fetchedReminders = await response.data;
        setReminders(fetchedReminders);

        // Assuming parentArrivals and reminders are arrays
        const alreadysentreminder = parentArrivals.filter((arrival) =>
          fetchedReminders.some(
            (reminder) => arrival?.student?._id === reminder?.student?._id
          )
        );
        setSentReminders(alreadysentreminder);
        localStorage.setItem("reminders", JSON.stringify(fetchedReminders));
        // console.log(alreadysentreminder);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching reminders:", error.message);
      }
    };

    fetchReminders();
  }, [parentReminders, parentArrivals, id]);

  useEffect(() => {
    const fetchArrivals = async () => {
      try {
        const response = await fetch(
          "https://apischool.lineup.pk/arival/getAllArrivals/",
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setVanArrived(data);
          const formattedParentArrivals = data
            .filter((item) => item.arrivalType === "Student")
            .map((item) => ({
              ...item,
              arrivalTime: moment(item.arrivalTime).format("h:mm A"), // Format time
            }));

          const formattedVanArrivals = data
            .filter((item) => item.arrivalType === "Bus")
            .map((item) => ({
              ...item,
              arrivalTime: moment(item.arrivalTime).format("h:mm A"), // Format time
            }));
          setParentArrivals(formattedParentArrivals);

          setVanArrivals(formattedVanArrivals);

          localStorage.setItem(
            "parentArrivals",
            JSON.stringify(formattedParentArrivals)
          );
          localStorage.setItem(
            "vanArrivals",
            JSON.stringify(formattedVanArrivals)
          );
        } else {
          console.error("Error fetching arrivals:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching arrivals:", error);
      }
    };

    fetchArrivals();
  }, [selectedVans, deletedVans, selectedStudents, deletedStudents, id]);

  const findMatchingElements = async () => {
    let matchingElements = [];

    if (!Array.isArray(parentArrivals)) {
      console.error("parentArrivals is not an array");
      return;
    }
    for (let localStorageItem of parentArrivals) {
      for (let stateItem of filteredStudents) {
        if (localStorageItem.student?._id === stateItem?._id) {
          matchingElements.push(localStorageItem);
          break;
        }
      }
    }

    const newStudents = filteredStudents.filter((student) => {
      return !matchingElements.some(
        (element) => element.student?._id === student?._id
      );
    });
    setStudents(newStudents); // Update the state with the new students array
  };

  const findMatchingVans = async () => {
    let matchingVans = [];
    const vanArrivals = JSON.parse(localStorage.getItem("vanArrivals"));
    if (!Array.isArray(vanArrivals)) {
      console.error("vanArrivals is not an array");
      return;
    }
    for (let localStorageItem of vanArrivals) {
      for (let stateItem of buses) {
        if (localStorageItem.bus?._id === stateItem?._id) {
          matchingVans.push(localStorageItem);
          break;
        }
      }
    }
    const newVans = buses.filter((bus) => {
      return !matchingVans.some((van) => van.bus?._id === bus?._id);
    });

    setBuses(newVans); // Update the state with the new buses array
  };
  useEffect(() => {
    findMatchingElements();
    findMatchingVans();
  }, [parentArrivals, vanArrivals, id]);

  useEffect(() => {
    const fetchStudents = async () => {
      const authtoken = localStorage.getItem("authToken");
      if (!authtoken) {
        console.error("Auth token not found.");
        return;
      }

      try {
        const response = await fetch(
          "https://apischool.lineup.pk/student/getstudents",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": authtoken,
            },
          }
        );

        const data = await response.json();

        if (Array.isArray(data)) {
          setStudents(data);
          // console.log("Fetched students:", data);
        } else {
          // console.error("Expected an array but got:", data);
          setStudents([]);
        }
      } catch (error) {
        console.error("Error fetching students:", error);
        setStudents([]);
      }
    };

    const fetchAttendance = async () => {
      if (date) {
        // console.log("Fetching attendance for date:", date);
        try {
          const authtoken = localStorage.getItem("authToken");
          const response = await fetch(
            `https://apischool.lineup.pk/leave/${date}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-token": authtoken,
              },
            }
          );
          const data = await response.json();

          // console.log("Fetched attendance data:", data);
          setAttendanceData(data);

          // setStudents(filteredStudents);
        } catch (error) {
          console.error("Error getting attendance:", error);
        }
      }
    };

    const fetchBuses = async () => {
      const authtoken = localStorage.getItem("authToken");

      if (!authtoken) {
        console.error("Auth token not found.");
        return;
      }

      try {
        const response = await fetch(
          "https://apischool.lineup.pk/bus/getbuses",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": authtoken,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setBuses(data);
        } else {
          console.error("Failed to fetch buses:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching buses:", error.message);
      }
    };

    fetchStudents().then(fetchAttendance).then(fetchBuses);
  }, [fetching, id, date]);
  // useEffect(() => {
  //   getStudentById(id);
  // }, [id._id]);
  const getStudentById = async (id) => {
    if (!id) {
      // console.log("No ID found in scanner");
      return null;
    }
    const parents = JSON.parse(localStorage.getItem("parentArrivals"));
    const existingArrival = parents.find(
      (arrival) => arrival.student?._id === id
    );

    if (existingArrival) {
      // console.log("Arrival is already sent", existingArrival);
      // setScanMessage("Arrival is already sent");
      const reminders = JSON.parse(localStorage.getItem("reminders"));
      const studentRem =
        reminders &&
        reminders.find(
          (student) => student.student?._id === existingArrival?.student?._id
        );
      // console.log(studentRem);
      if (!studentRem) {
        await handleReminderSelection(existingArrival.student);
      } else {
        console.log("Chal Bhag yahan se");
      }
      // if(!studentRem){
      //   await handleReminderSelection(existingArrival.student);
      // }
      // if (studentRem) {
      //   // console.log("Find result", studentRem);

      //   const reminderUnselectionPromise =
      //     handleReminderUnselection(existingArrival);
      //   const deleteArrivalPromise = fetch(
      //     `https://apischool.lineup.pk/arival/removeArrival/${existingArrival._id}`,
      //     {
      //       method: "DELETE",
      //       headers: {
      //         "Content-Type": "application/json",
      //         "auth-token": authToken,
      //       },
      //     }
      //   );

      //   // Execute both promises concurrently
      //   try {
      //     await Promise.all([reminderUnselectionPromise, deleteArrivalPromise]);
      //     // await Promise.all([deleteArrivalPromise]);
      //     // alert("Arrival removed and reminder unselected");
      //     setScanMessage("Arrival removed and reminder unselected");
      //   } catch (error) {
      //     // console.error("Error in handleStudentDeletion:", error);
      //     setScanMessage("Error in Student Deletion");
      //   }

      //   return;
      // }
      // await handleReminderSelection(existingArrival.student);

      // console.log("Sent reminder", existingArrival.student);
      message.success("Reminder sent");
      setScanMessage("Reminder sent");
      // alert("Reminder sent");
      return existingArrival.student;
    }
    // Find the student by ID
    const student = filteredStudents.find((student) => student._id === id);
    if (!student) {
      message.warning("Today Student is Absent");
      // console.error("Student object does not have an _id field");
      return null;
    }

    // console.log("Student found:", student);

    // Prepare the request to check the student's arrival
    const requestBody = {
      arrivalType: "Student",
      student: id,
    };

    try {
      const response = await fetch(
        "https://apischool.lineup.pk/arival/checkArrival/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
          body: JSON.stringify(requestBody),
        }
      );
      const arrival = await response.json();
      // console.log(arrival);
      if (arrival.message === "Arrival Already Send") {
        message.warning(arrival.message);
        return;
      } else if (arrival.message === "Student Not Founded") {
        message.warning("Student Not Founded");
        return;
      }
      // console.log("Remove Parents Arrival");
      setParentArrivals((prevArrivals) => [...prevArrivals, arrival]);
      // console.log(parentArrivals);
      // setState(true);

      // console.log("Arrival succeeded");
      message.success("Arrival succeeded");
      setScanMessage("Arrival succeeded");

      // if (!response2.ok) {
      //   console.error("Failed to add attendance:", response2.statusText);
      //   // Handle failure to add attendance
      //   return null;
      // }

      // Handle successful addition of attendance

      return {
        student: id,
        arrivalTime: moment().format("h:mm A"),
        _id: arrival._id,
      };
    } catch (error) {
      console.error("Error in handleStudentSelection:", error);
      return null;
    }
  };

  // Van Driver Scanned function
  const VanDriverScan = async (id) => {
    // console.log("id", id);
    if (!id) {
      // console.log("No ID found in scanner");
      return null;
    }
    const requestBody = {
      arrivalType: "Bus",
      bus: id,
    };

    try {
      const response = await fetch(
        "https://apischool.lineup.pk/arival/checkArrival/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const arrival = await response.json();
      message.success("Arrival Send");
      console.log(arrival);
      if (arrival.message === "Arrival Already Send") {
        message.warning(arrival.message);
        return;
      }

      return {
        student: id,
        arrivalTime: moment().format("h:mm A"),
        _id: arrival._id,
      };
    } catch (error) {
      console.error("Error in handleStudentSelection:", error);
      return null;
    }
  };

  const handleStudentSelection = async (students) => {
    console.log("student");
    try {
      // Create an array to store all the fetch promises
      const fetchPromises = selectedStudents.map(async (student) => {
        if (!student?._id) {
          console.error("Student object does not have an _id field");
          return null;
        }

        const arrivalType = "Student";
        const studentId = student._id;

        const requestBody = {
          arrivalType,
          student: studentId,
        };

        const response = await fetch(
          "https://apischool.lineup.pk/arival/checkArrival/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          console.error("Failed to save student arrival:", response.statusText);
          return null;
        }

        const arrival = await response.json();

        return {
          student,
          arrivalTime: moment().format("h:mm A"),
          _id: arrival._id,
        };
      });

      const arrivals = await Promise.all(fetchPromises);
      // console.log(arrivals);
      const successfulArrivals = arrivals.filter((arrival) => arrival !== null);

      setParentArrivals((prev) => [...prev, ...successfulArrivals]);

      setSelectedStudents([]);
      // console.log(students);
      setStudents((prev) =>
        prev.filter(
          (s) => !selectedStudents.some((student) => student._id === s._id)
        )
      );
      setSelectAllStudents(false);
    } catch (error) {
      console.error("Error in handleStudentSelection:", error);
    }
  };

  const handleBusSelection = async () => {
    // console.log(selectedVans);
    try {
      const fetchPromises = selectedVans.map(async (van) => {
        if (!van?._id) {
          console.error("Bus object does not have an _id field");
          return null;
        }

        const arrivalType = "Bus"; // The type of arrival
        const busId = van._id; // The ID of the selected bus

        // Create the request body with the required data
        const requestBody = {
          arrivalType,
          bus: busId,
        };

        // Perform the fetch request
        const response = await fetch(
          "https://apischool.lineup.pk/arival/checkArrival/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
            body: JSON.stringify(requestBody), // Convert the request body to JSON
          }
        );
        // console.log(response);
        if (!response.ok) {
          console.error("Failed to save bus arrival:", response.statusText);
          // Handle specific errors, such as invalid data or unauthorized
          return null;
        }

        const arrival = await response.json(); // Parse the created record

        // Return the new bus arrival object
        return {
          van,
          arrivalTime: moment().format("h:mm A"), // Record the formatted time
          _id: arrival._id, // Store the ID from the response
        };
      });

      // Execute all the fetch promises concurrently
      const arrivals = await Promise.all(fetchPromises);

      // Filter out any failed requests (null values)
      const successfulArrivals = arrivals.filter((arrival) => arrival !== null);

      // Update state with the successful arrivals
      setVanArrivals((prev) => [...prev, ...successfulArrivals]);

      // Clear the selected vans and remove them from the buses list
      setSelectedVans([]);
      setBuses((prev) =>
        prev.filter((b) => !selectedVans.some((van) => van._id === b._id))
      );
      setSelectAllVans(false);
    } catch (error) {
      console.error("Error in handleBusSelection:", error);
      // Handle unexpected errors, such as network issues or server errors
    }
  };

  const handleStudentUnselection = async () => {
    if (deletedStudents.length === 0) {
      // console.log("no students selected");
      return;
    }
    try {
      // Create an array to store all the delete promises
      const deletePromises = deletedStudents.map((arrival) => {
        const arrivalId = arrival._id;
        return fetch(
          `https://apischool.lineup.pk/arival/removeArrival/${arrivalId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );
      });

      // Execute all the delete promises in parallel
      const responses = await Promise.all(deletePromises);
      setLoading(true);
      // Check if all responses are successful
      const allResponsesSuccessful = responses.every((response) => response.ok);

      if (allResponsesSuccessful) {
        for (const data of deletedStudents) {
          await handleReminderUnselection(data);
        }
        setParentArrivals((prev) =>
          prev.filter(
            (p) => !deletedStudents.some((arrival) => arrival._id === p._id)
          )
        );
        setStudents((prev) => [
          ...prev,
          ...deletedStudents.map((arrival) => arrival.student),
        ]);
        setDeletedStudents([]);
        setSelectAllParentArrivals(false);
        // setFetching(false);
        setFetching((prev) => !prev);
        setLoading(false);
      } else {
        // Handle cases where some deletions failed
        console.error("Failed to remove one or more student arrivals.");
      }
    } catch (error) {
      console.error("Error removing student arrivals:", error);
    }
  };

  const handleBusUnselection = async () => {
    try {
      // Create an array to store all the delete promises
      const deletePromises = deletedVans.map((arrival) => {
        const arrivalId = arrival._id;
        return fetch(
          `https://apischool.lineup.pk/arival/removeArrival/${arrivalId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );
      });

      // Execute all the delete promises in parallel
      const responses = await Promise.all(deletePromises);
      setLoading(true);

      // Check if all responses are successful
      const allResponsesSuccessful = responses.every((response) => response.ok);

      if (allResponsesSuccessful) {
        // If all deletions are successful, update state accordingly
        setVanArrivals((prev) =>
          prev.filter(
            (v) => !deletedVans.some((arrival) => arrival._id === v._id)
          )
        );
        setBuses((prev) => [
          ...prev,
          ...deletedVans.map((arrival) => arrival.bus),
        ]);
        setDeletedVans([]);
        setSelectAllVanArrivals(false);
        setFetching((prev) => !prev);
        setLoading(false);
      } else {
        console.error("Failed to remove one or more bus arrivals.");
      }
    } catch (error) {
      console.error("Error removing bus arrivals:", error);
    }
  };

  const handleReminderSelection = async (student) => {
    try {
      if (!student?._id) {
        console.error("Student object does not have an _id field");
        return null;
      }
      const arrivalType = "Student";
      const studentId = student._id;
      const requestBody = {
        arrivalType,
        student: studentId,
        ischecked: true,
      };
      const response = await fetch(
        "https://apischool.lineup.pk/reminder/addReminder",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (!response.ok) {
        console.error("Failed to save reminder:", response.statusText);
        return null;
      }
      const reminder = await response.json();
      // console.log("reminder result", reminder);
      setParentReminders((prev) => {
        if (prev.includes(student)) {
          return prev.filter((s) => s !== student);
        } else {
          return [...prev, student];
        }
      });
      return {
        student,
        reminderTime: moment(reminder.reminderTime).format("h:mm A"),
        _id: reminder._id,
      };
    } catch (error) {
      console.error("Error in handleReminderSelection:", error);
    }
  };
  const handleReminderUnselection = async (arrivals) => {
    const matchingReminder = reminders.find(
      (reminder) => reminder.student?._id === arrivals.student?._id
    );
    // console.log("clicked ", matchingReminder?._id);
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/reminder/removeReminder/${matchingReminder?._id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      if (!response.ok) {
        console.error("Failed to remove reminder:", response.statusText);
        return null;
      }
      setParentReminders((prev) => {
        return prev.filter((s) => s !== arrivals.student);
      });
    } catch (error) {
      console.error("Error in handleReminderUnselection:", error);
    }
  };

  const toggleTab = (tabName) => {
    setActiveTab(tabName);
  };

  const mystyle = {
    container: {
      fontFamily: "Arial, sans-serif",
      maxWidth: "800px",
      margin: "auto",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRadius: "10px",
      backgroundImage: `url(${image})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    input: {
      width: "100%",
      padding: "10px",
      marginBottom: "20px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      boxSizing: "border-box",
      fontSize: "16px",
    },
    input2: {
      width: "100%",
      padding: "10px",
      marginTop: "20px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      boxSizing: "border-box",
      fontSize: "16px",
    },
    list: {
      listStyleType: "none",
      padding: "0",
      margin: "0",
      overflowY: "auto",
      height: "35vh",
    },
    listItem: {
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
    },
    column: {
      flex: "1",
    },
    heading: {
      fontSize: "24px",
      marginBottom: "20px",
      color: "#333",
    },
    checkbox: {
      marginRight: "10px",
    },
    tab: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "30%",
      height: "80%",
      border: "1px solid black",
      cursor: "pointer",
      marginRight: "20px",
      transition: "0.3s ease-in-out",
      color: "white",
    },
    tabHover: {
      backgroundColor: "#24282f",
      color: "white",
    },
    headings: {
      fontWeight: "800",
    },
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <div style={mystyle.container}>
        <div
          style={{
            marginBottom: "20px",
            height: "10hv",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              ...mystyle.tab,
              ...(activeTab === "parents" ? mystyle.tabHover : {}),
            }}
            onClick={() => toggleTab("parents")}
          >
            Parents
          </span>
          <span
            style={{
              ...mystyle.tab,
              ...(activeTab === "van" ? mystyle.tabHover : {}),
            }}
            onClick={() => toggleTab("van")}
          >
            Van
          </span>
          <Link
            target="_blank"
            to={`/arrivalScreens`}
            rel="noopener noreferrer"
            className="hover:text-black hover:no-underline"
            style={{
              ...mystyle.tab,
              ...(activeTab === "screen" ? mystyle.tabHover : {}),
            }}
          >
            Screen
          </Link>
        </div>
        {activeTab === "parents" && (
          <div>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={mystyle.input}
            />
            <div className="flex justify-between pt-4">
              <h2 style={mystyle.headings}>Students list</h2>
              <div className="flex items-center gap-2  ">
                <QRScanner
                  message={scanMessage}
                  button={"Scan me"}
                  handleStudentSelection={handleStudentSelection}
                  getStudentById={getStudentById}
                />
                <button
                  onClick={() => handleStudentSelection()}
                  className="flex items-center gap-2  border  p-2 rounded-full transition duration-500  hover:bg-[#678a86] hover:text-white "
                >
                  <FaArrowDown />
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "85%",
                alignItems: "center",
              }}
            >
              <h5 style={mystyle.headings}> Student Name</h5>
              <h5 style={mystyle.headings}> Class</h5>
              <h5 style={mystyle.headings}> Section</h5>
              <h5 style={mystyle.headings}> Parent Name</h5>
            </div>
            {loading ? (
              <div className={`loader1  mx-auto my-32`}></div>
            ) : (
              <ul style={mystyle.list}>
                {filteredFindStudent
                  .filter((student) =>
                    student?.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  )
                  .map((student) => (
                    <li key={student?._id} style={mystyle.listItem}>
                      <input
                        type="checkbox"
                        style={mystyle.checkbox}
                        onClick={() => handleStudentArrival(student)}
                      />
                      <span className="capitalize" style={mystyle.column}>
                        {student?.name}
                      </span>

                      <span style={mystyle.column}>{student.classGrade}</span>
                      <span
                        className={`capitalize ${
                          sectionColors[student.section]
                        }`}
                        style={mystyle.column}
                      >
                        {student.section}
                      </span>
                      <span className="capitalize" style={mystyle.column}>
                        {student.parentName}
                      </span>
                    </li>
                  ))}
              </ul>
            )}{" "}
          </div>
        )}
        {activeTab === "van" && (
          <div>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={mystyle.input}
            />
            <div className="flex justify-between ">
              <h2 style={mystyle.headings}>Buses List</h2>
              <div className="flex items-center gap-2  ">
                <QRScanner
                  message={scanMessage}
                  button={"Scan Driver"}
                  VanDriverScan={VanDriverScan}
                />
                <button
                  onClick={() => handleBusSelection()}
                  className="flex items-center gap-2 p-2 border   rounded-full transition duration-500  hover:bg-[#678a86] hover:text-white "
                >
                  <FaArrowDown />
                </button>
              </div>
            </div>
            {loading ? (
              <div className={`loader1  mx-auto my-32`}></div>
            ) : (
              <ul style={mystyle.list}>
                {filteredBuses
                  .sort((a, b) => parseInt(a.vanNo) - parseInt(b.vanNo))
                  .filter((bus) =>
                    bus.vanNo.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((bus) => (
                    <li key={bus._id} style={mystyle.listItem}>
                      <input
                        type="checkbox"
                        style={mystyle.checkbox}
                        onClick={() => handleVanArrival(bus)}
                      />
                      <span style={mystyle.column}>Van {bus?.vanNo}</span>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        )}

        {activeTab === "parents" && (
          <div>
            <input
              type="text"
              placeholder="Search..."
              value={arrivalsSearchQuery}
              onChange={(e) => setArrivalsSearchQuery(e.target.value)}
              style={mystyle.input2}
            />
            <div className="flex justify-between pt-4">
              <h2 style={mystyle.headings}>Parents Arrivals</h2>
              <div className="flex items-center gap-2  ">
                <input
                  id="select"
                  className=""
                  type="checkbox"
                  checked={selectAllParentArrivals}
                  onChange={handleSelectAllParentArrivals}
                />
                <label htmlFor="select" className="!mb-0">
                  Select All
                </label>

                <button
                  onClick={() => handleStudentUnselection()}
                  className="flex items-center gap-2  border  p-2 rounded-full transition duration-500  hover:bg-[#678a86] hover:text-white "
                >
                  <FaArrowUp />
                </button>
              </div>
            </div>
            <ul style={mystyle.list}>
              {parentArrivals
                .filter((arrival) =>
                  arrival?.student?.name
                    ?.toLowerCase()
                    .includes(arrivalsSearchQuery?.toLowerCase())
                )
                .map((arrival, index) => (
                  <li key={index} style={mystyle.listItem}>
                    <input
                      type="checkbox"
                      style={mystyle.checkbox}
                      checked={deletedStudents.some(
                        (student) => student._id === arrival._id
                      )}
                      onClick={() => handleStudentDeletion(arrival)}
                    />
                    <span className="capitalize" style={mystyle.column}>
                      {arrival.student.name}
                    </span>
                    <span className="uppercase" style={mystyle.column}>
                      {arrival.student.classGrade} -{" "}
                      <span
                        className={`${sectionColors[arrival.student.section]}`}
                      >
                        {arrival.student.section}
                      </span>
                    </span>
                    <span style={mystyle.column}>{arrival.arrivalTime}</span>
                    <span className="text-center" style={mystyle.column}>
                      {sentReminders.some(
                        (reminder) =>
                          reminder.student._id === arrival.student._id
                      ) ? (
                        <span className="flex items-center gap-2  border  w-fit  px-2 rounded-full transition duration-500  hover:bg-[#295550] bg-[#295550] hover:text-black text-white ">
                          Reminder Sent !
                        </span>
                      ) : (
                        <button
                          onClick={() =>
                            handleReminderSelection(arrival.student)
                          }
                          className="flex items-center gap-2  border    px-2 rounded-full transition duration-500  hover:bg-[#295550] bg-[#678a86] hover:text-black text-white "
                        >
                          Reminder
                        </button>
                      )}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        )}

        {activeTab === "van" && (
          <div>
            <input
              type="text"
              placeholder="Search..."
              value={arrivalsSearchQuery}
              onChange={(e) => setArrivalsSearchQuery(e.target.value)}
              style={mystyle.input2}
            />
            <div className="flex justify-between pt-4">
              <h2 style={mystyle.headings}>Van Arrivals</h2>
              <div className="flex items-center gap-2  ">
                <input
                  id="select"
                  className=""
                  type="checkbox"
                  checked={selectAllVanArrivals}
                  onChange={handleSelectAllVanArrivals}
                />
                <label htmlFor="select" className="!mb-0">
                  Select All
                </label>

                <button
                  onClick={() => handleBusUnselection()}
                  className="flex items-center gap-2  border  p-2 rounded-full transition duration-500  hover:bg-[#678a86] hover:text-white "
                >
                  <FaArrowUp />
                </button>
              </div>
            </div>
            <ul style={mystyle.list}>
              {vanArrivals
                .filter((arrival) =>
                  arrival.bus?.vanNo
                    .toLowerCase()
                    .includes(arrivalsSearchQuery.toLowerCase())
                )
                .sort((a, b) => {
                  // Assuming vanNo is numeric
                  return parseInt(a.bus?.vanNo) - parseInt(b.bus?.vanNo);
                })
                .map((arrival, index) => (
                  <li key={index} style={mystyle.listItem}>
                    <input
                      type="checkbox"
                      style={mystyle.checkbox}
                      checked={deletedVans.some(
                        (bus) => bus._id === arrival._id
                      )}
                      onClick={() => handleVanDeletion(arrival)}
                    />
                    <span style={mystyle.column}>Van {arrival.bus?.vanNo}</span>
                    <span style={mystyle.column}>{arrival.arrivalTime}</span>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ArrivalManagement;
