import React from "react";
import profileIcon from "./../../assets/defaultprofile.jpeg";
import schoolIcon from "./../../assets/logo.png";
import { Col, Row, Typography, QRCode, List, Avatar } from "antd";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import { useRef } from "react";
import { MdCheckCircle } from "react-icons/md";
import { PiScissorsFill } from "react-icons/pi";

const styles = {
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  wave: {
    position: "absolute",
    // left: "0.1rem",
    // width: "84.5mm",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    marginTop: 22,
    width: 60,
    height: 45,
    objectFit: "cover",
    marginBottom: 5,
  },
  logos: {
    marginTop: -10,
    width: 60,
    height: 45,
    objectFit: "cover",
    marginBottom: 15,
  },
  title: {
    backgroundColor: "#fff",
    textAlign: " center",
    display: "flex",
    justifyContent: "center",
    lineHeight: 1.1,
    marginTop: 22,
  },
  borderStyle: {
    padding: 4,
    width: "160px",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    position: "relative",
    top: -10,
  },
  topBorderStyle: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    height: "1px",
    backgroundImage: "linear-gradient(to right, #000 20%, transparent 40%)",
  },
  bottomBorderStyle: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    height: "1px",
    backgroundImage: "linear-gradient(to left, #000 20%, transparent 40%)",
  },
  profileImageContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  imageDiv: {
    height: 60,
    width: 60,
    border: "4px solid #C2D0D1",
  },
  image: {
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
    height: "100%",
  },
  field: {
    marginTop: -9,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
  },
  detailsTitle: {
    marginRight: "8px",
    color: "#474747",
    fontSize: "12px",
  },
  value: {
    flex: 1,
    paddingBottom: "6px",
    borderBottom: "1px solid #000",
    textAlign: "start",
    fontSize: "12px",
  },
  svgContainer: {
    position: "relative",
    width: "100%",
    height: "58px",
    marginBottom: "-1px",
  },
  svg1: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  svg2: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  svg3: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  card: {
    height: "98.60mm",
    width: "66.80mm",
    position: "relative",
  },
};

// const details = {
//   name: "Sharjeel hussain",
//   erolledIn: "Nursery",
//   id: "VmA-92658",
//   parentName: "Ghulam Nabi",
//   class: "III", // should in roman count
//   section: "B",
//   id: "o4897543g37t8734rbc78r",
//   organizationTitle: "Nubit Software",
//   conditions: [
//     "Lorem ipsum is placeholder text commonly used in the graphic, youts and visual mockups.",
//     "Lorem ipsum is placeholder text commonly used in the graphic",
//     "In publishing and grapstrate the visual form of a document or a typeface without relying on meaningful content. L",
//     "Lorem ipsum may be used as a placeholder before the final copy is available.",
//   ],
//   note: "Note if available", // if any
// };

const CustomPdf = ({ details, contentRef }) => {
  // const contentRef = useRef();
  // // // this function should be there where ypu creates ref
  // const generatePdf = async () => {
  //   const content = contentRef.current;
  //   const canvas = await html2canvas(content, { scale: 4 });
  //   const imgData = canvas.toDataURL("image/png");
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const imgWidth = 210;
  //   const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //   pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

  //   const pdfBlob = pdf.output("blob");
  //   const pdfFile = new Blob([pdfBlob], { type: "application/pdf" });
  //   pdf.save("identity-card.pdf");
  // };

  const { Title, Text } = Typography;

  // details = {
  //   ...details,
  //   note: "Something change",
  //   conditions: ["Must be something IF you cant customize", "new"],
  // };
  // const jsonString = JSON.stringify(details);

  const filterRequiredKey = (obj) => {
    if (obj == null) {
      return;
    } else {
      const { id, _id, name, parentName, classGrade, section } = obj;
      return { id, _id, name, parentName, classGrade, section };
    }
  };

  const filteredObj = filterRequiredKey(details);

  const searchParams = new URLSearchParams(filteredObj);
  const queryString = searchParams.toString();

  const name = details?.name || "Smith";
  const organizationTitle = details?.organizationTitle || "Nubit Software";
  const classGrade = `Grade - ${details?.classGrade}` || "Grade X";
  const section = details?.section || "A";
  const parentName = details?.parentName || "John Doe";
  const imageSrc = details?.image || schoolIcon;
  const profileImageSrc = details?.profile || profileIcon;
  const id = details || "Id not be generated";
  const rollnum = `Student-ID-${details?.id}` || "abc-1234";
  const note =
    details?.note ||
    "Note: This card is for organization premises only. If found please return to organization";
  const term = details?.conditions || [
    "This ID card is the property of school and must be presented upon request by school authorities.",
    "The ID card is non-transferable and should not be used by anyone other than the named student.",
    "Loss of this ID card should be reported immediately to the school office.",
    "The ID card must be worn visibly at all times during school hours.",
    "Misuse or alteration of this ID card may result in disciplinary action.",
    "This ID card grants access to school facilities and services according to school rules and regulations.",
    "Replacement of a lost ID card may incur a fee as per school policy.",
    "The ID card must be returned upon withdrawal or graduation from the school.",
  ];
  return (
    <div>
      <div
        id="content"
        ref={contentRef}
        style={{
          width: "210mm",
          height: "297mm",
          padding: "20mm",
          boxSizing: "border-box",
          backgroundColor: "#fff",
          overflow: "hidden",
        }}
      >
        <Row
          gutter={24}
          style={{
            padding: "30px 10px",
            border: "1px dashed #000",
            ...styles.flexCenter,
          }}
        >
          <Col
            span={12}
            style={{ ...styles.flexCenter, borderRight: "1px dashed #000" }}
          >
            <section
              style={{
                ...styles.card,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                  style={{ ...styles.wave, zIndex: 99 }}
                >
                  <path
                    fill="#C2D0D1"
                    fillOpacity="1"
                    d="M0,256L120,213.3C240,171,480,85,720,58.7C960,32,1200,64,1320,80L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                  ></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                  style={styles.wave}
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,224L120,192C240,160,480,96,720,106.7C960,117,1200,203,1320,245.3L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                  ></path>
                </svg>
              </div>

              <section>
                <picture style={styles.logoContainer}>
                  <img style={styles.logo} src={imageSrc} alt="Logo" />
                </picture>

                <Title level={4} style={styles.title}>
                  <span style={styles.borderStyle}>
                    <span style={styles.topBorderStyle}></span>
                    <span style={styles.bottomBorderStyle}></span>
                    <span
                      style={{
                        position: "relative",
                        top: "-10px",
                        fontSize: "18px",
                      }}
                    >
                      {organizationTitle}
                    </span>
                  </span>
                </Title>

                <picture style={styles.profileImageContainer}>
                  <span style={styles.imageDiv}>
                    <img
                      style={styles.image}
                      src={profileImageSrc}
                      alt="profile image"
                    />
                  </span>
                </picture>

                <Title
                  level={2}
                  style={{
                    textAlign: "center",
                    marginTop: 10,
                    fontSize: "18px",
                    textTransform: "capitalize",
                  }}
                >
                  {name}
                </Title>

                <Title
                  level={4}
                  style={{
                    textAlign: "center",
                    marginTop: -3,
                    fontSize: "16px",
                  }}
                >
                  {classGrade}
                </Title>

                <Title
                  level={2}
                  style={{
                    textAlign: "center",
                    marginTop: 4,
                    fontSize: "16px",
                    // textTransform: "Capitalize",
                  }}
                >
                  {rollnum}
                </Title>
              </section>

              <div style={styles.svgContainer}>
                <svg
                  style={styles.svg1}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,320L720,320L1440,128L1440,320L720,320L0,320Z"
                  ></path>
                </svg>

                <svg
                  style={styles.svg3}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,320L220,32L1440,380L1440,320L720,320L0,320Z"
                  ></path>
                </svg>

                <svg
                  style={styles.svg2}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#C2D0D1"
                    fillOpacity="1"
                    d="M0,320L240,102L1440,320L1440,320L720,320L0,320Z"
                  ></path>
                </svg>
              </div>
            </section>
          </Col>

          <Col span={12} style={{ ...styles.flexCenter }}>
            <section
              style={{
                ...styles.card,
                overflow: "hidden",
                position: "relative",
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                  style={{ ...styles.wave, zIndex: 99 }}
                >
                  <path
                    fill="#C2D0D1"
                    fillOpacity="1"
                    d="M0,256L120,213.3C240,171,480,85,720,58.7C960,32,1200,64,1320,80L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                  ></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                  style={styles.wave}
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,224L120,192C240,160,480,96,720,106.7C960,117,1200,203,1320,245.3L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                  ></path>
                </svg>
              </div>

              <div style={{ padding: "0px 20px", marginTop: 58 }}>
                <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Name:</span>
                  <span style={styles.value}>{name}</span>
                </Title>

                <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Father Name:</span>
                  <span style={styles.value}>{parentName}</span>
                </Title>

                <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Class:</span>
                  <span style={styles.value}>{classGrade}</span>
                </Title>

                <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Section:</span>
                  <span style={styles.value}>{section}</span>
                </Title>

                <div style={{ ...styles.flexCenter, margin: "12px 0px" }}>
                  <QRCode
                    errorLevel="L"
                    value={queryString}
                    // icon={imageSrc}
                    size={90}
                    style={{ padding: 4, border: 0, marginTop: 4 }}
                  />
                </div>
                
                <div
                  style={{
                    margin: "8px 0px",
                    lineHeight: "12px",
                  }}
                >
                  <Text
                    strong
                    style={{
                      fontSize: "8px",
                      lineHeight: "4px",
                    }}
                  >
                    {note}
                  </Text>
                </div>
                <div
                  style={{
                    margin: "8px 0px",
                    lineHeight: "12px",
                  }}
                >
                  <div className="flex justify-center gap-1">
                    <p className="text-[12px] mt-[5px]">Powered by</p>{" "}
                    <img
                      src={imageSrc}
                      style={{
                       width:"40px",
                       margin: "8px 0px",
                      
                      }}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  ...styles.svgContainer,
                  position: "absolute",
                  bottom: "0px",
                }}
              >
                <svg
                  style={styles.svg1}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,320L720,320L1440,128L1440,320L720,320L0,320Z"
                  ></path>
                </svg>

                <svg
                  style={styles.svg3}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,320L220,32L1440,380L1440,320L720,320L0,320Z"
                  ></path>
                </svg>

                <svg
                  style={styles.svg2}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#C2D0D1"
                    fillOpacity="1"
                    d="M0,320L240,102L1440,320L1440,320L720,320L0,320Z"
                  ></path>
                </svg>
              </div>
            </section>
          </Col>
        </Row>

        <PiScissorsFill
          style={{
            marginLeft: "-12px",
            marginTop: "-12px",
            fontSize: "24px",
            backgroundColor: "#fff",
            color: "#000",
          }}
        />

        <Row
          gutter={24}
          style={{
            padding: "30px 10px",
            ...styles.flexCenter,
          }}
        >
          <Col span={12} style={{ ...styles.flexCenter}}>
            <section
              style={{
                ...styles.card,
                overflow: "hidden",
                position: "relative",
                height:'420px'
              }}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                  style={{ ...styles.wave, zIndex: 99 }}
                >
                  <path
                    fill="#C2D0D1"
                    fillOpacity="1"
                    d="M0,256L120,213.3C240,171,480,85,720,58.7C960,32,1200,64,1320,80L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                  ></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                  style={styles.wave}
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,224L120,192C240,160,480,96,720,106.7C960,117,1200,203,1320,245.3L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                  ></path>
                </svg>
              </div>

              <div style={{ padding: "0px 20px", marginTop: 58 }}>
                <picture style={styles.logoContainer}>
                  <img style={styles.logos} src={imageSrc} alt="Logo" />
                </picture>
                <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Name:</span>
                  <span style={styles.value}>{name}</span>
                </Title>

                <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Father Name:</span>
                  <span style={styles.value}>{parentName}</span>
                </Title>

                <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Class:</span>
                  <span style={styles.value}>{classGrade}</span>
                </Title>

                <Title level={5} style={styles.field}>
                  <span style={styles.detailsTitle}>Section:</span>
                  <span style={styles.value}>{section}</span>
                </Title>

                <div style={{ ...styles.flexCenter, margin: "12px 0px" }}>
                  <QRCode
                    errorLevel="L"
                    value={queryString}
                    // icon={imageSrc}
                    size={90}
                    style={{ padding: 4, border: 0, marginTop: 4 }}
                  />
                </div>

                <div
                  style={{
                    margin: "8px 0px",
                    lineHeight: "12px",
                  }}
                >
                  <Text
                    strong
                    style={{
                      fontSize: "8px",
                      lineHeight: "4px",
                    }}
                  >
                    {note}
                  </Text>
                </div>
                <div
                  style={{
                    margin: "8px 0px",
                    lineHeight: "12px",
                  }}
                >
                  <div className="flex justify-center gap-1">
                    <p className="text-[12px] mt-[5px]">Powered by</p>{" "}
                    <img
                      src={imageSrc}
                      style={{
                       width:"40px",
                       margin: "8px 0px",
                      
                      }}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  ...styles.svgContainer,
                  position: "absolute",
                  bottom: "0px",
                }}
              >
                <svg
                  style={styles.svg1}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,320L720,320L1440,128L1440,320L720,320L0,320Z"
                  ></path>
                </svg>

                <svg
                  style={styles.svg3}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#999D9D"
                    fillOpacity="1"
                    d="M0,320L220,32L1440,380L1440,320L720,320L0,320Z"
                  ></path>
                </svg>

                <svg
                  style={styles.svg2}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#C2D0D1"
                    fillOpacity="1"
                    d="M0,320L240,102L1440,320L1440,320L720,320L0,320Z"
                  ></path>
                </svg>
              </div>
            </section>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CustomPdf;
