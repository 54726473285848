import { useState } from "react";
import Navbar from "../components/arrivalscreens/Navbar";
import ClassTable from "../components/arrivalscreens/ClassTable";
import VanContainer from "../components/arrivalscreens/VanContainer";
import ReminderContainer from "../components/arrivalscreens/ReminderContainer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ArrivalScreens = () => {
  const [excessStudents, setExcessStudents] = useState([]);
  return (
    <>
      <main className="bg-red-500 h-[100vh] overflow-hidden ">
        <Navbar />
        <div className="lg:flex lg:h-[90vh] w-[100vw] ">
          <div className="lg:h-full lg:w-[25vw]">
            <VanContainer />
          </div>
          <div className="h-full w-[100vw] md:w-[100vw] lg:w-[75vw]">
            <div className="h-[55vh] w-[100%] flex">
              <ClassTable setExcessStudents={setExcessStudents} />
            </div>
            <div className="flex items-center mt-5 justify-center">
              {excessStudents.length ? <ReminderContainer reminderStudents={excessStudents}/>:<div className="text-white">No Reminder</div>}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ArrivalScreens;
