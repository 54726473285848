import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ChangePasswordModal from "./changePasswordModal";

const Sidebar = ({ show }) => {
  const navigate = useNavigate();
  // const handleItemClick = (page) => {
  //   onPageChange(page);
  // };
  const handleLogout = () => {
    // localStorage.removeItem("authToken");
    // localStorage.removeItem("userdetails");
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };
  // const isAuthenticated = localStorage.getItem("authToken") !== null;
  // if (!isAuthenticated) {
  //   return null;
  // }

  const links = [
    { label: "Dashboard", path: "/dashboard", page: "Admin Panel" },
    { label: "Students", path: "/students", page: "Student Management" },
    { label: "Buses", path: "/bus", page: "Bus Management" },
    { label: "Arrivals", path: "/arrivals", page: "Arrival Management" },
    { label: "Teachers", path: "/teachers", page: "Teachers" },
    {
      label: "Announcements",
      path: "/announcements",
      page: "Announcements",
    },
    {
      label: "Register User",
      path: "/registerEmployee",
      page: "User Registration",
    },
    { label: "Student Attendance", path: "/attendance", page: "Attendance" },
    { label: "Teacher Attendance", path: "/teacherattendance", page: "Teacher Attendance" },
    {
      label: "Bus Report",
      path: "/busReport",
      page: "Bus Report",
    },
    {
      label: "Attendance Report",
      path: "/attendanceReport",
      page: "Attendance Report",
    },
    {
      label: "Teacher Report",
      path: "/teacherReport",
      page: "Teacher Report",
    },
  ];

  return (
    <aside
      className={`sidebar ${
        show ? "show" : "hide"
      } flex flex-col justify-between`}
      id="sideBar"
    >
      <div className="list-group">
        {links.map((link) => (
          <div key={link.page}>
            <Link
              to={link.path}
              className="list-group-item list-group-item-action text-gray-300 transition-all duration-300 tracking-wider  text-lg bg-[#1B1E25] border-b border-gray-500"
            >
              {link.label}
            </Link>
          </div>
        ))}
      </div>
      
        <button
          className="w-full bg-gray-300 py-3 hover:bg-opacity-50 hover:text-xl transition-all duration-400 text-lg font-semibold"
          onClick={handleLogout}
        >
          Log Out
        </button>
      
    </aside>
  );
};

export default Sidebar;
