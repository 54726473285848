import React, { memo, useRef, useState, useCallback, useEffect } from "react";
import Slider from "react-slick";

import PostError from "../../utils/errorlog";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReminderComponents from "./ReminderComponents";

const ReminderContainer = ({ reminderStudents }) => {
  const token = localStorage.getItem("authToken");
  const authToken = `${token}`;

  const [students, setStudents] = useState(reminderStudents);
  const [pendingRemoval, setPendingRemoval] = useState(null);
  const sliderRef = useRef(null);
  // Effect to update students when reminderStudents changes
  useEffect(() => {
    setStudents(reminderStudents);
  }, [reminderStudents]);

  // Function to delete a student
  const deleteStudent = async (id) => {
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/arival/removeArrival/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      if (response.ok) {
        setStudents((prevStudents) =>
          prevStudents.filter((student) => student._id !== id)
        );
      } else {
        console.error("Failed to delete:", response.statusText);
      }
    } catch (error) {
      PostError(error);
      console.error("Error fetching data:", error);
    }
  };

  // Callback for handling slide change
  const handleAfterChange = useCallback(
    (current) => {
      // Only trigger deletion if there are more than 5 students
      // console.log(pendingRemoval)
      if (students.length > 5 && pendingRemoval !== null) {
        deleteStudent(pendingRemoval);
        setPendingRemoval(null);
      }
    },
    [students, pendingRemoval, deleteStudent]
  );

  // Callback for handling slide visibility
  const handleBeforeChange = useCallback(
    (current, next) => {
      // If slide is going out of view, mark it for removal
      if (students.length > 5) {
        const idToRemove = students[current]?._id;
        if (idToRemove) {
          setPendingRemoval(idToRemove);
        }
      }
    },
    [students]
  );

  // Reset slider position if students count changes significantly
  useEffect(() => {
    if (students.length <= 5 && sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, [students]);

  const totalStudents = reminderStudents.length;
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: Math.min(totalStudents, 5),
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    vertical: false,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(totalStudents, 4),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(totalStudents, 4),
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: Math.min(totalStudents, 4),
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: Math.min(totalStudents, 3),
        },
      },
    ],
  };

  return (
    <div className="w-full text-center">
      {reminderStudents.length > 1 ? (
        <Slider
          ref={(slider) => {
            sliderRef.current = slider;
          }}
          {...settings}
        >
          {students.map((stu, index) => (
            <div
              key={index}
              className={`transition-opacity duration-500 ${
                pendingRemoval === stu._id ? "opacity-0" : "opacity-100"
              }`}
            >
              <ReminderComponents
                studentName={stu?.student?.name}
                classGrade={stu?.student?.classGrade}
                section={stu?.student?.section}
                id={stu?._id}
              />
            </div>
          ))}
        </Slider>
      ) : reminderStudents.length === 1 ? (
        reminderStudents.length === 1 && (
          <div className="">
            <ReminderComponents
              studentName={reminderStudents[0]?.student?.name}
              classGrade={reminderStudents[0]?.student?.classGrade}
              section={reminderStudents[0]?.student?.section}
              id={reminderStudents[0]?._id}
            />
          </div>
        )
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ReminderContainer;
