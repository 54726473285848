import React, { useEffect, useState } from "react";
import Navbar from "../components/announcementScreen/Navbar";
import Footer from "../components/announcementScreen/Footer";
import Mainright from "../components/announcementScreen/Mainright";
import MainLeft from "../components/announcementScreen/Mainleft";
import "./CSS/announcementScreen.css";

const AnnouncementScreen = () => {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    const popupInterval = setInterval(() => {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 10000);
    }, 20000);
  }, []);

  return (
    
    <div className="overflow-hidden h-screen w-full">
      <Navbar />
      <div className="h-[74vh] w-full overflow-hidden flex border-2 border-black">
        <MainLeft />
        <Mainright />
      </div>
      <Footer />
    </div>
  );
};

export default AnnouncementScreen;
