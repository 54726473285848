import React from "react";
import LogoComponent from "../components/arrivalScreen/Logo";
import Navbar from "../components/arrivalScreen/Navbar";
import Point from "../components/arrivalScreen/Point";
import "./CSS/arrivalScreen.css";

const ArrivalScreen = () => {
  return (
    <main className="arrival-screen">
      <Navbar />
      <Point />
    </main>
  );
};

export default ArrivalScreen;
