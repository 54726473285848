import React, { useState, useEffect, useRef, useContext } from "react";
import QrScanner from "qr-scanner";
import { ScannerQRContext } from "../../context/scannerqr";
import { FetchContext } from "../../context/fetch";
import PostErorr from "../../utils/errorlog";

function createParamsToObj(object){
  const searchParams = new URLSearchParams(object);
  const obj = {};
  searchParams.forEach((value, key) => {
    obj[key] = value;
  });
  return obj
}

function Scanner({ message, addstudenttoLeave, handleStudentSelection,button,getStudentById,VanDriverScan,addteacherAttendance }) {
  const { id, setId } = useContext(ScannerQRContext);
  const { state, setState } = useContext(FetchContext);
  const [qrData, setQrData] = useState("");
  const videoRef = useRef(null);
  const qrScannerRef = useRef(null);
  const audioRef = useRef(null);

  const handleScan = (result) => {
    if (result) {
      console.log(result)
      // const object = createParamsToObj(result?.data)
      setQrData(result?.data);
      // setId(result?.data);
      if(button == "Scan Attendance"){
        const object = createParamsToObj(result?.data)
        setId(object._id)
        addstudenttoLeave(object)
      }else if(button == "Scan Teacher"){
        const object = createParamsToObj(result?.data)
        setId(object?.userId)
        addteacherAttendance(object)
      }else if(button == "Scan me"){
        // console.log("Hello")
        const object = createParamsToObj(result?.data)
        setId(object._id)
        getStudentById(object._id)
      }else if(button == "Scan Driver"){
        const object = createParamsToObj(result?.data)
        setId(object._id)
        VanDriverScan(object._id)
      }
      // else if(button &&)
      audioRef.current.play();
      qrScannerRef.current.stop();
      setTimeout(() => {
        qrScannerRef.current.start();
      }, 300)
    }
  };

  const startCamera = async () => {
    const video = videoRef.current;
    try {
      qrScannerRef.current = new QrScanner(video, handleScan, {
        highlightScanRegion: true,
        highlightCodeOutline: true,
        inversionMode: "original",
      });
      qrScannerRef.current.start();
    } catch (error) {
      PostErorr(error);
      console.error("Error accessing the camera", error);
    }
  };

  useEffect(() => {
    startCamera();

    return () => {
      if (qrScannerRef.current) {
        qrScannerRef.current.stop();
        qrScannerRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    function sendToParent() {
      setState(true);
      setTimeout(() => {
        setId("");
        setQrData("");
        console.log("id set to empty");
      }, 500);
      setTimeout(() => {
        setState(false);
        console.log("state set to false");
      }, 3000);
    }
    if (qrData) {
      sendToParent();
    }
  }, [qrData]);

  // const refreshCamera = () => {
  //   try {
  //     if (qrScannerRef.current) {
  //       qrScannerRef.current.stop();
  //       qrScannerRef.current.destroy();
  //       qrScannerRef.current = null;
  //     }
  //     setQrData("");
  //     startCamera();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="my-5 flex flex-col gap-2 items-center">
      <span className={state ? "flex " : "hidden"}>
        {message ? `${message} !!` : "Loading..."}
      </span>
      <div className={`${state ? "hidden" : ""}`}>
        <div className={`flex flex-col gap-2 items-center`}>
          <h1 className="text-3xl font-bold"> QR Scanner</h1>
          <video ref={videoRef} className={`w-80`}></video>
          <audio ref={audioRef} src="/beep.mp3" className="hidden"></audio>
          {qrData && (
            <p className="text-lg">
              <span className="font-semibold text-xl">Scanned Data:</span>{" "}
              {qrData}
            </p>
          )}
          {/* <button
            onClick={refreshCamera}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Refresh Camera
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default Scanner;
