import React, { useEffect, useState } from "react";
import studentCARTOON from "../assets/CARTOON.png";
import busCartoon from "../assets/busCartoon.png";
import arrivalImg from "../assets/arrival.png";
import image from "../assets/loginImg.jpg";
import "./CSS/dashboard.css";
import { Link } from "react-router-dom";
import PostErorr from "../utils/errorlog";
import Footer from "../components/common/Footer";
import moment from "moment";

const mystyle = {
  activityFeed: {
    margin: "10px 0",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
};

const AdminDashboard = () => {
  const [summaryData, setSummaryData] = useState({
    totalStudents: 0,
    totalBuses: 0,
    totalArrivals: 0,
  });

  const [announcements, setAnnouncements] = useState([]); // New state for announcements
  const isAuthenticated = localStorage.getItem("authToken");
  const fetchTotalArrivals = async () => {
    try {
      const response = await fetch(
        "https://apischool.lineup.pk/arival/totalArrivals", // Fetch total arrivals
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${isAuthenticated}`,
          },
        }
      );
      const data = await response.json();
      setSummaryData((prevData) => ({
        ...prevData,
        totalArrivals: data.totalArrivals, // Update the state with total arrivals
      }));
    } catch (error) {
      PostErorr(error);
      console.error("Error fetching total arrivals:", error);
    }
  };

  const fetchTotalStudents = async () => {
    try {
      const response = await fetch(
        "https://apischool.lineup.pk/student/getstudents",
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${isAuthenticated}`,
          },
        }
      );
      const data = await response.json();
      setSummaryData((prevData) => ({
        ...prevData,
        totalStudents: data.length, // Assuming the API returns an array of students
      }));
      console.log(data);
    } catch (error) {
      PostErorr(error);

      console.error("Error fetching total students:", error);
    }
  };

  const fetchTotalBuses = async () => {
    try {
      const response = await fetch("https://apischool.lineup.pk/bus/getbuses", {
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${isAuthenticated}`,
        },
      });
      const data = await response.json();
      setSummaryData((prevData) => ({
        ...prevData,
        totalBuses: data.length, // Assuming the API returns an array of buses
      }));
    } catch (error) {
      PostErorr(error);

      console.error("Error fetching total buses:", error);
    }
  };
  const fetchAnnouncements = async () => {
    try {
      const response = await fetch(
        "https://apischool.lineup.pk/announcements/getannouncements/",
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${isAuthenticated}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json(); // Parse the response
        setAnnouncements(data); // Update the state with the fetched announcements
      } else {
        console.error("Failed to fetch announcements:", response.statusText);
      }
    } catch (error) {
      PostErorr(error);
      console.error("Error fetching announcements:", error);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      fetchTotalStudents();
      fetchTotalArrivals(); // Fetch total arrivals when component mounts
      fetchAnnouncements();
      fetchTotalBuses();
    }
  }, [isAuthenticated]); // Fetch data when component mounts

  if (!isAuthenticated) {
    return null; // Return nothing if not authenticated
  }
  let formattedDate;
  const splitDate = () => {
    return announcements.map((announcement, index) => {
      const dateTime = new Date(announcement.createdAt)
        .toLocaleString()
        .split(", ")[0]
        .split("/");
      formattedDate = dateTime;
      return formattedDate;
    });
  };

  splitDate();
  console.log(summaryData);
  return (
    <>
      <div
        className="min-h-[85vh] font-roboto flex flex-col max-w-[1000px] mx-auto p-4 bg-[#f5f5f5] rounded-lg"
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="Card flex w-full">
          <div className="basis-1/3 w-full m-2.5 p-4 rounded-lg bg-white flex flex-col items-center justify-center border border-gray-300">
            <Link to="/students">
              <img
                src={studentCARTOON}
                style={{ height: "100px" }}
                alt="Student-pic"
              />
              <div style={{ textAlign: "center" }}>
                <h3>Total Students</h3>
                <p>{summaryData.totalStudents}</p>
              </div>
            </Link>
          </div>

          <div className="basis-1/3 w-full m-2.5 p-4 rounded-lg bg-white flex flex-col items-center justify-center border border-gray-300">
            <Link to="/bus">
              <img src={busCartoon} style={{ height: "100px" }} alt="Bus-pic" />
              <div style={{ textAlign: "center" }}>
                <h3>Total Buses</h3>
                <p>{summaryData.totalBuses}</p>
              </div>
            </Link>
          </div>

          <div className="basis-1/3 w-full m-2.5 p-4 rounded-lg bg-white flex flex-col items-center justify-center border border-gray-300">
            <Link to="/arrivals">
              <img
                src={arrivalImg}
                style={{ height: "100px" }}
                alt="Arrival-pic"
              />
              <div style={{ textAlign: "center" }}>
                <h3>Total Arrivals</h3>
                <p>{summaryData.totalArrivals}</p>
              </div>
            </Link>
          </div>
        </div>
        {/* Announcement Section */}

        <div className="overflow-y-auto h-[31rem]" style={mystyle.activityFeed}>
          <h2 className="sticky top-0 text-2xl p-2 z-10 bg-white w-full   font-bold text-center mb-4 ">
            Announcements
          </h2>
          <div className="p-[10px]">
            {announcements.map((announcement, index) => {
              const formattedDate = new Date(announcement.createdAt)
                .toLocaleDateString("en-GB")
                .split("/");
              return (
                <div
                  className="flex justify-between items-center mb-2 p-2 border-gray-300"
                  key={index}
                >
                  <strong className="text-[12px] sm:text-[14px] md:text-[16px] lg:text-[18px] text-gray-800 w-fit">
                    {announcement.message}
                  </strong>
                  <div className="border-b md:w-[40%] lg:w-[60%] hidden sm:block"></div>
                  <p className="text-[12px] sm:text-[12px] md:text-[14px] lg:text-[16px]  text-gray-600">
                    {announcement.dateFrom && !announcement.dateTo ? (
                      `${moment(announcement.dateFrom).format("DD-MMM-YYYY")}`
                    ) : (
                      <>
                        {moment(announcement.dateFrom).format("DD-MMM-YYYY")}{" "}
                        <span className="font-semibold">-</span>{" "}
                        {moment(announcement.dateTo).format("DD-MMM-YYYY")}
                      </>
                    )}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminDashboard;
