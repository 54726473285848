import React, { useEffect, useState } from "react";
import ReportTitle from "../../components/ReportTitle";
import "../../pages/CSS/busPDF.css"; // Ensure to keep the CSS for page breaks

const rowsPerPage = 20;

const BusReportPdf = ({ details, reportRef }) => {
  const [pages, setPages] = useState([]);

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${adjustedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  useEffect(() => {
    const pageCount = Math.ceil(details.length / rowsPerPage);
    const newPages = [];
    for (let i = 0; i < pageCount; i++) {
      newPages.push(details.slice(i * rowsPerPage, (i + 1) * rowsPerPage));
    }
    setPages(newPages);
  }, [details]);

  return (
    <div ref={reportRef} className="w-[210mm]">
      {pages.map((pageDetails, pageIndex) => (
        <div
          key={pageIndex}
          className={`min-h-screen flex flex-col justify-between ${
            pageIndex !== pages.length - 1 ? "page-break" : ""
          }`}
        >
          <div className="flex flex-col items-center justify-center flex-grow">
            <table className="table-auto mt-3 w-11/12 mx-auto border-collapse">
              <thead>
                <tr className="bg-black text-white text-center">
                  <th className="px-4 pb-2">S.no</th>
                  <th className="px-4 pb-2">Van no</th>
                  <th className="px-4 pb-2">Driver Name</th>
                  <th className="px-4 pb-2">Van Reg</th>
                  <th className="px-4 pb-2">Departure Time</th>
                </tr>
              </thead>
              <tbody>
                {pageDetails.map((bus, index) => (
                  <tr
                    key={bus.vanNo}
                    className={`${
                      index % 2 === 0 ? "bg-gray-300" : "bg-white"
                    } text-center`}
                  >
                    <td className="px-4 pb-2">
                      {pageIndex * rowsPerPage + index + 1}
                    </td>
                    <td className="px-4 pb-2">{bus.vanNo}</td>
                    <td className="px-4 pb-2">{bus.driverName}</td>
                    <td className="px-4 pb-2">{bus.vanReg}</td>
                    <td className="px-4 pb-2">
                      {convertTo12HourFormat(bus.departureTime)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div className="footer mt-auto">
            <hr className="w-full" />
            <div className="text-center">
              Page {pageIndex + 1} of {pages.length}
            </div>
          </div> */}
        </div>
      ))}
    </div>
  );
};

export default BusReportPdf;
