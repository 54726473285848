
import React from "react";
import Navlogo from "../../assets/logoo.png";
import BG from "../../assets/banner.jpg";
import { useNavigate } from "react-router-dom";
import { RiLogoutBoxLine } from "react-icons/ri";

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  return (
    <div
      className="h-[13vh] w-full bg-cover bg-no-repeat flex justify-between items-center text-white"
      style={{ backgroundImage: `url(${BG})` }}
    >
      <div className=" bg-white h-full w-[10%]">
        <img src={Navlogo} alt="" className="m-auto w-[120px]" />
      </div>
      <div >
        <h1 className="text-4xl  uppercase tracking-wider">Tipu Sultan Branch</h1>
      </div>
      <div className="flex items-center gap-4 pr-4">
        <div className="text-[20px] flex flex-col justify-center leading-[25px] items-center">
          <span>Contact</span>
          <span>+92-3312284284</span>
          <span>+92-3312284284</span>
        </div>
        <button
          onClick={handleLogout}
          className="text-white focus:outline-none px-3 hover:text-gray-600 transition-all duration-300"
        >
           <RiLogoutBoxLine size={24} />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
