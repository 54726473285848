import React, { useState, useEffect, useRef } from "react";
import image from "../assets/loginImg.jpg";
import "../pages/CSS/bus.css";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaDownload, FaSearch } from "react-icons/fa";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CustomPdf from "./reports/Pdf";
import { message } from "antd";
import PostErorr from "../utils/errorlog";
import { sectionColors } from "../components/constants/constant";
import InfiniteScroll from "react-infinite-scroll-component";
import Footer from "../components/common/Footer";
function StudentManagement() {
  const contentRef = useRef();
  const [isClicked, setIsClicked] = useState(false);
  const [students, setStudents] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [classGrade, setClassGrade] = useState("");
  const [section, setSection] = useState("");
  const [vanNumber, setVanNumber] = useState("");
  const [parentName, setParentName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [fileInput, setFileInput] = useState(null);
  const token = localStorage.getItem("authToken");
  const fetchStudents = async () => {
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/student/getAllstudents?page=${page}&limit=15`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      setStudents([...students, ...data.students]);
      setPage(page + 1);
      // If no more students are available
      if (data.students.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };
  useEffect(() => {
    fetchStudents(); // Fetch students when the component mounts
  }, []);
  useEffect(() => {
    generatePdf();
  }, [isClicked]);

  const handleStudentSelect = (student) => {
    setSelectedStudent(student);
    setId(student.id.toString());
    setName(student.name);
    setClassGrade(student.classGrade);
    setSection(student.section);
    setVanNumber(student.vanNumber);
    setParentName(student.parentName);
  };

  const inputRef = useRef();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "id":
        setId(value);
        break;
      case "name":
        setName(value);
        break;
      case "classGrade":
        setClassGrade(value);
        break;
      case "section":
        setSection(value);
        break;
      case "vanNumber":
        setVanNumber(value);
        break;
      case "parentName":
        setParentName(value);
        break;
      default:
        break;
    }
  };
  const handleUpdateStudent = async () => {
    if (!selectedStudent) return;
    const updatedStudent = {
      id: parseInt(id),
      name,
      classGrade,
      section,
      vanNumber,
      parentName,
    };
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/student/editstudent/${selectedStudent._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
          body: JSON.stringify(updatedStudent),
        }
      );
      const data = await response.json();
      if (response.ok) {
        fetchStudents(); // Refresh the list
        setSelectedStudent(null);
        setId("");
        setName("");
        setClassGrade("");
        setSection("");
        setVanNumber("");
        setParentName("");
        message.success("Student updated successfully");
      } else {
        message.error(data.error);
      }
    } catch (error) {
      console.error("Error updating student:", error);
      PostErorr(error);
    }
  };
  const handleDeleteStudent = async (id) => {
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/student/deletestudent/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
        }
      );
      const data = await response.json();
      setStudents(students);
      if (response.ok) {
        // fetchStudents(); // Refresh the list
        if (selectedStudent && selectedStudent._id === id) {
          setSelectedStudent(null);
          setId("");
          setName("");
          setClassGrade("");
          setSection("");
          setVanNumber("");
          setParentName("");
          message.success("Student deleted successfully");
        }
      }
    } catch (error) {
      PostErorr(error);
      console.error("Error deleting student:", error);
    }
  };
  const handleAddStudent = async () => {
    if (!id || !name || !classGrade || !section || !parentName) {
      message.error("Please fill all the fields");
      return;
    }
    const newStudent = {
      id: parseInt(id),
      name,
      classGrade,
      section,
      vanNumber,
      parentName,
    };
    try {
      const response = await fetch(
        "https://apischool.lineup.pk/student/addstudent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
          body: JSON.stringify(newStudent),
        }
      );
      const data = await response.json();
      console.log(data);
      setStudents(() => [...students, data]);
      if (response.ok) {
        // fetchStudents(); // Refresh the list
        setId("");
        setName("");
        setClassGrade("");
        setSection("");
        setVanNumber("");
        setParentName("");
        message.success("Student added successfully");
      }
    } catch (error) {
      PostErorr(error);
      console.error("Error adding student:", error);
    }
  };
  const [arr, setArr] = useState([]);
  const isMobile = window.matchMedia("(max-width: 576px)").matches;
  const isAuthenticated = localStorage.getItem("authToken") !== null;

  if (!isAuthenticated) {
    // If the user is not authenticated, do not render the component
    return null;
  }
  const filteredStudents = students.filter((student) =>
    Object.values(student).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const handleFileUpload = async (event) => {
    try {
      const [file] = event.target.files;
      setFileInput(event.target.files[0]);
      const validFileTypes = ["text/csv", "application/json"];
      if (!validFileTypes.includes(file.type)) {
        alert("Please upload a CSV or JSON file");
        return;
      }
      const reader = new FileReader();
      reader.onload = ({ target: { result } }) => {
        let data;
        if (file.type === "text/csv") {
          const [headerLine, ...lines] = result
            .split("\n")
            .map((line) => line.trim())
            .filter((line) => line); // filter out any empty lines
          const headers = headerLine.split(",");
          data = lines.map((line) => {
            const values = line.split(",");
            return headers.reduce((entry, header, index) => {
              entry[header] = values[index];
              return entry;
            }, {});
          });
        } else if (file.type === "application/json") {
          data = JSON.parse(result);
        }
        setArr(data);
      };
      reader.readAsText(file);
    } catch (error) {
      PostErorr(error);
      console.error("Error uploading file:", error);
    }
  };
  const uploadfile = async () => {
    const authtoken = `${token}`;
    if (!authtoken) {
      console.error("Auth token not found.");
      return;
    }
    const uploadData = async (data) => {
      const newData = {
        id: parseInt(data.ID),
        name: data.StudentName,
        classGrade: data.Class,
        section: data.Section,
        vanNumber: data.VanNumber,
        parentName: data.ParentName,
      };
      try {
        setLoader(true);
        const response = await fetch(
          "https://apischool.lineup.pk/student/addstudent",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "auth-token": `${token}`,
            },
            body: JSON.stringify(newData),
          }
        );
        if (response.ok) {
          console.log(`Successfully uploaded data with ID: ${data.ID}`);
        } else {
          const errorMessage = await response.text();
          console.error(
            `Failed to upload data with ID: ${data.ID}. Server response: ${errorMessage}`
          );
        }
        setLoader(false);
        setFileInput(null);
        setArr([]);
      } catch (error) {
        PostErorr(error);
        setFileInput(null);
        console.error(`Error uploading data with ID: ${data.ID}`, error);
      }
    };
    for (const data of arr) {
      await uploadData(data);
    }
    fetchStudents(); // Refresh the list after all uploads
  };

  // this function should be there where ypu creates ref
  const generatePdf = async () => {
    if (selectedStudent !== null) {
      console.log("success", selectedStudent);
      const content = contentRef.current;
      const canvas = await html2canvas(content, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      const pdfBlob = pdf.output("blob");
      const pdfFile = new Blob([pdfBlob], { type: "application/pdf" });
      pdf.save(selectedStudent.name?.toUpperCase() + "- ID.pdf");
    }
  };
  const searchFunction = async (name) => {
    try {
      const trimmedValue = name.trim();
      const query = /^\d+$/.test(trimmedValue)
        ? `id=${trimmedValue}`
        : `name=${trimmedValue}`;
      const response = await fetch(
        `https://apischool.lineup.pk/student/searchstudent?${query}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
        }
      );
      const data = await response.json();
      setStudents(data);
    } catch (error) {}
  };
  const resetButton = async () => {
    setStudents([]);
    const response = await fetch(
      `https://apischool.lineup.pk/student/getAllstudents?page=1&limit=15`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${token}`,
        },
      }
    );
    const data = await response.json();
    setStudents(data.students);
  };
  return (
    <>
      <div
        className="d-flex flex-column align-items-center justify-content-evenly"
        style={mainDiv}
      >
        <div
          className="d-inline-block w-100 d-flex align-items-center justify-content-center inputs-div"
          style={mystyle}
        >
          {isMobile && (
            <div
              className="d-flex flex-column  justify-content-center align-items-center "
              style={{ height: "100%", overflow: "hidden" }}
            >
              <div>
                <label className="bg-black text-white text-center w-full rounded-sm">
                  ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="ID"
                  name="id"
                  value={id}
                  onChange={handleInputChange}
                />
                <label className="bg-black text-white text-center w-full mt-2 rounded-sm">
                  Student
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  value={name}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="bg-black text-white text-center w-full mt-2 rounded-sm">
                  Class
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Class"
                  name="classGrade"
                  value={classGrade}
                  onChange={handleInputChange}
                />
                <label className="bg-black text-white text-center w-full mt-2 rounded-sm">
                  Section
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Section"
                  name="section"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="bg-black text-white text-center w-full mt-2 rounded-sm">
                  Van
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Van Number"
                  name="vanNumber"
                  value={vanNumber}
                  onChange={handleInputChange}
                />
                <label className="bg-black text-white text-center w-full mt-2 rounded-sm">
                  Parent
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Parent Name"
                  name="parentName"
                  value={parentName}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                {selectedStudent && (
                  <>
                    <button
                      className="btn btn-dark mr-2"
                      onClick={handleUpdateStudent}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-dark mr-2"
                      onClick={() => handleDeleteStudent(selectedStudent._id)}
                    >
                      Delete
                    </button>
                  </>
                )}
                <button className="btn btn-dark" onClick={handleAddStudent}>
                  Add
                </button>
              </div>
            </div>
          )}
          {!isMobile && (
            <div className="flex w-[70%]">
              <div>
                <label className="bg-black text-white text-center w-full">
                  ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="ID"
                  name="id"
                  value={id}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="bg-black text-white text-center w-full">
                  Student
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  value={name}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="bg-black text-white text-center w-full">
                  Class
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Class"
                  name="classGrade"
                  value={classGrade}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="bg-black text-white text-center w-full">
                  Section
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Section"
                  name="section"
                  value={section}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="bg-black text-white text-center w-full">
                  Van
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Van Number"
                  name="vanNumber"
                  value={vanNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="bg-black text-white text-center w-full">
                  Parent
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Parent Name"
                  name="parentName"
                  value={parentName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex justify-content-end">
                {selectedStudent && (
                  <>
                    <button
                      style={{ marginTop: "30px", marginLeft: "10px" }}
                      className="btn btn-dark mr-2"
                      onClick={handleUpdateStudent}
                    >
                      Update
                    </button>
                    <button
                      style={{ marginTop: "30px", marginLeft: "10px" }}
                      className="btn btn-dark mr-2"
                      onClick={() => handleDeleteStudent(selectedStudent._id)}
                    >
                      Delete
                    </button>
                  </>
                )}
                <button
                  style={{ marginTop: "30px", marginLeft: "10px" }}
                  className="btn btn-dark"
                  onClick={handleAddStudent}
                >
                  Add
                </button>
              </div>
            </div>
          )}
        </div>
        <div class="w-3/4 flex justify-between">
          <div className="flex items-center gap-2  ">
            <input
              onChange={(event) => handleFileUpload(event)}
              type="file"
              className="hidden"
              id="file-input"
              accept=".csv, .json"
              value={""}
            />
            {
              <div className="relative bg-[#ffffff93]   rounded">
                {
                  <>
                    {fileInput ? (
                      <p className="py-[6px] px-4">
                        <IoMdCloseCircleOutline
                          onClick={() => {
                            setFileInput(null);
                            setArr([]);
                          }}
                          className="absolute -top-2 -left-2 hover:text-2xl text-red-500 hover:text-opacity-50 text-xl cursor-pointer"
                        />{" "}
                        {fileInput.name}{" "}
                      </p>
                    ) : (
                      <label
                        className="mt-[6px] px-1 md:px-4 font-semibold text-[10px] md:text-[16px]"
                        htmlFor="file-input"
                      >
                        Upload CSV File
                      </label>
                    )}
                  </>
                }
              </div>
            }
            {loader ? (
              <button onClick={() => uploadfile()} className="btn  btn-dark ">
                <div class="loader"></div>
              </button>
            ) : (
              <button
                onClick={() => uploadfile()}
                className="btn px-[26px]  btn-dark "
              >
                Upload
              </button>
            )}
          </div>
          <div className="flex w-1/4 items-center">
            <input
              class="w-[70%] border-2 border-black justify-end items-end form-control m-2"
              type="text"
              placeholder="Search..."
              name="search"
              ref={inputRef}
              onChange={handleInputChange}
            />
            <button
              className="border-2 border-black w-10 h-10 flex justify-center items-center rounded mr-2"
              onClick={() => searchFunction(inputRef.current.value)}
            >
              <FaSearch />
            </button>
            <button
              className="border-2 border-black w-20 h-10 flex justify-center items-center rounded"
              onClick={resetButton}
            >
              Reset
            </button>
          </div>
        </div>
        <div className="w-75 border border-secondary" style={tableDiv}>
          <InfiniteScroll
            dataLength={students.length}
            next={students.length >= 15 && fetchStudents}
            hasMore={hasMore}
            loader={
              students.length >= 15 && (
                <div className={`loader1 mx-auto my-2`}></div>
              )
            }
            endMessage={
              <p style={{ textAlign: "center" }}>All students loaded</p>
            }
          >
            <table
              className="table-main w-100 h-auto border border-secondary"
              style={table}
            >
              <thead style={tableHead}>
                <tr style={border}>
                  <th style={border}>ID</th>
                  <th style={border}>Student Name</th>
                  <th style={border}>Class</th>
                  <th style={border}> Section</th>
                  <th style={border}>Van Number</th>
                  <th style={border}>Parent Name</th>
                  <th style={border}>Generate ID Card</th>
                </tr>
              </thead>
              <tbody style={tableBody}>
                {students.map((student) => (
                  <tr
                    className="hover:bg-[#fff]"
                    key={student.id}
                    onClick={() => handleStudentSelect(student)}
                    style={{ cursor: "pointer" }}
                  >
                    <td style={border}>{student.id}</td>
                    <td className="capitalize" style={border}>
                      {student.name}
                    </td>
                    <td style={border}>{student.classGrade}</td>
                    <td
                      className={`uppercase ${sectionColors[student.section]}`}
                      style={border}
                    >
                      {student.section}
                    </td>
                    <td style={border}>{student.vanNumber}</td>
                    <td className="capitalize" style={border}>
                      {student.parentName}
                    </td>
                    <td
                      className="capitalize d-flex text-blue-800 w-100 justify-center gap-2 cursor-pointer hover:text-[#000]"
                      style={border}
                      onClick={() => setIsClicked(!isClicked)} // Pass the student object directly
                    >
                      <FaDownload className="h-8" />
                    </td>
                    <span style={{ position: "fixed", left: "-99999px" }}>
                      <CustomPdf
                        details={selectedStudent}
                        contentRef={contentRef}
                      />
                    </span>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mystyle = {
  height: "20vh",
};

const tableDiv = {
  minHeight: "565px",
};
const tableHead = {
  backgroundColor: "#1b1e25",
  color: "white",
  height: "10%",
};
const tableBody = {
  backgroundColor: "#E7E9E9",
};
const border = {
  border: "1px solid black",
  borderCollapse: "collapse",
};
const mainDiv = {
  backgroundImage: `url(${image})`,
  backgroundSize: "100vh",
  backgroundPosition: "center",
};
const table = {
  tableLayout: "fixed",
};

export default StudentManagement;
