import React, { useContext, useEffect, useState } from "react";
import image from "../assets/loginImg.jpg";
import { Select, message } from "antd";
import QRScanner from "../components/common/QrModal";
import Footer from "../components/common/Footer";
import { CiTrash } from "react-icons/ci";
import moment from "moment";
import PostErorr from "../utils/errorlog";
import ConfirmModal from "../components/Modal";
import { ScannerQRContext } from "../context/scannerqr";
const Attendance = () => {
  const { id, setId } = useContext(ScannerQRContext);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const authToken = localStorage.getItem("authToken");
  const [teacher, setTeachers] = useState();
  const [fetchAttendances, setFetchAttendances] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchOutQuery, setSearchOutQuery] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedOutTeacher, setSelectedOutTeacher] = useState(null);
  const [render, setRender] = useState(false);
  const localTime = new Date().toLocaleTimeString("en-US", {
    timeZone: "Asia/Karachi", // Change this to your desired time zone
  });
  console.log(localTime);
  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const response = await fetch(
          `https://apischool.lineup.pk/teacher/AllTeacher`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );
        const data = await response.json();
        console.log(data);
        setTeachers(data);
      } catch (error) {
        PostErorr(error);
        console.error("Error getting attendance:", error);
      }
    };
    fetchAttendance();
  }, [date, selectedTeacher, selectedOutTeacher, id]);
  const handleChange = (value, option) => {
    setSearchQuery(value);
    setSelectedTeacher(option?.teacher);
  };
  const handleoutChange = (value, option) => {
    setSearchOutQuery(value);
    setSelectedOutTeacher(option?.teacher);
  };

  const addteacherAttendance = async (teacher) => {
    console.log(teacher);
    if (!authToken) {
      console.error("Auth token not found.");
      return;
    }
    if (!teacher || searchQuery === "Select Student") {
      message.error("Please select a student to mark attendance");
      return;
    }
    try {
      const response = await fetch(
        "https://apischool.lineup.pk/teacherAttendance/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
          body: JSON.stringify({
            date,
            teachers: {
              userId: teacher.userId,
            },
          }),
        }
      );
      console.log(response);
      const data = await response.json();
      if (data.message === "Teacher already marked present on this date") {
        message.warning(
          `${teacher.name} is already marked present on this date`
        );
        return;
      }
      message.success("Attendance marked successfully");
      // name === "MarkOut" ? setSearchOutQuery("Select Student") : setSearchQuery("Select Student")
      setSearchOutQuery(null);
      setSearchQuery(null);
    } catch (error) {
      PostErorr(error);
      console.error("Error marking attendance:", error);
    }

    try {
      const response = await fetch(
        "https://apischool.lineup.pk/newteacherAttendance/addAttendances",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
          body: JSON.stringify({
            teacherId: teacher.userId,
            firstName: teacher.firstName,
            lastName: teacher.lastName,
            attendance: {
              date,
            },
          }),
        }
      );
      console.log(response);
    } catch (error) {
      console.error("Error marking attendance:", error);
    }
  };
  useEffect(() => {
    const fetchAttendance = async () => {
      console.log(teacher);
      if (!authToken) {
        console.error("Auth token not found.");
        return;
      }
      try {
        const response = await fetch(
          `https://apischool.lineup.pk/teacherAttendance/${date}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );

        const data = await response.json();
        setFetchAttendances(data);
        console.log(data);

        // console.log(data);
      } catch (error) {
        PostErorr(error);

        console.error("Error marking attendance:", error);
      }
    };
    fetchAttendance();
  }, [date, searchQuery, searchOutQuery, render, id]);

  const filteredTeachers = teacher?.filter(
    (teacher) =>
      !fetchAttendances?.some((teah) => teah.userId === teacher.userId)
  );
  const filtertimeIn = fetchAttendances?.filter(
    (ele, index) => ele.timeOut === "Still in"
  );
  const deleteAttendance = async (obj) => {
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/teacherAttendance/${date}/${obj?._id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      const data = await response.json();
      if (data.message === "No attendance record found for this date") {
        message.warning(data.message);
        return;
      }
      message.success("Teacher attendance record deleted successfully");
      setRender(!render);
      // console.log(data);
    } catch (error) {
      PostErorr(error);
      console.error("Error deleting student attendance record:", error);
    }
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/newteacherAttendance/${date}/${obj?.userId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
    } catch (error) {
      console.error("Error deleting student attendance record:", error);
    }
  };
  console.log(fetchAttendances);
  return (
    <>
      <div
        className="h-screen w-3/4 lg:w-2/3 xl:w-1/2 mx-auto rounded-lg overflow-hidden"
        style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
      >
        <div className="p-3">
          <div className="flex flex-col sm:flex-row sm:flex-wrap justify-between items-center">
            <QRScanner
              button="Scan Teacher"
              addteacherAttendance={addteacherAttendance}
            />
            <div>
              <input
                className="px-2 py-1 my-2 transition-all duration-300 focus:outline-none rounded text-[#000000D9] border bg-white"
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>
            <div className="flex items-center gap-2">
              <Select
                className="w-40"
                placeholder="Select Student"
                value={searchQuery}
                showSearch
                allowClear
                onChange={handleChange}
                options={
                  filteredTeachers?.map((teacher) => ({
                    value: `${teacher.userId}-${teacher.firstName}`,
                    label: `${teacher.userId}-${teacher.firstName}`,
                    key: teacher._id,
                    teacherId: teacher.userId,
                    teacher: teacher,
                  })) ?? []
                }
              />
              <button
                onClick={() => addteacherAttendance(selectedTeacher)}
                className="bg-gray-500 px-2 py-1 rounded ml-2 text-white tracking-widest"
                disabled={!selectedTeacher}
              >
                Mark
              </button>
            </div>
          </div>
          <div className="h-[80%] text-center">
            <h1 className="text-2xl font-semibold my-2">
              {moment(date).format("DD-MMM-YYYY")}
            </h1>
            <div className="h-[330px] p-3">
              <div className="flex justify-between">
                <h2 className="font-bold text-xl">Teacher List</h2>
                <div className="flex items-center gap-2"></div>
              </div>
              <div className="h-[100%] overflow-y-auto">
                <div className="sticky top-0 bg-white mx-3 z-10">
                  <table className="w-full border bg-white bg-opacity-25">
                    <thead>
                      <tr className="flex justify-evenly border">
                        <th className="border w-full px-2">First Name</th>
                        <th className="border w-full px-2">Last Name</th>
                        <th className="border w-full px-2">Subject</th>
                        <th className="border w-full px-2">Education</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="text-lg px-3">
                  <table className="w-full border bg-white bg-opacity-25">
                    <tbody>
                      {filteredTeachers &&
                        filteredTeachers.map((item, val) => (
                          <tr className="flex border justify-evenly">
                            <td className="capitalize w-full px-2">
                              {item.firstName}
                            </td>
                            <td className="w-full capitalize px-2">
                              {item.lastName}
                            </td>
                            <td className="w-full capitalize px-2">
                              {item.subjects}
                            </td>
                            <td className="w-full capitalize px-2">
                              {item.education}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[330px] p-3 my-2">
            <div className="flex justify-between">
              <h2 className="font-bold text-xl">Attendance List By Date</h2>
              <div className="flex items-center gap-2"></div>
            </div>
            <div className="flex items-center justify-end gap-2">
              <Select
                className="w-40"
                placeholder="Select Student"
                value={searchOutQuery}
                showSearch
                allowClear
                onChange={handleoutChange}
                options={
                  filtertimeIn?.map((teacher) => ({
                    value: `${teacher.userId}-${teacher.firstName}`,
                    label: `${teacher.userId}-${teacher.firstName}`,
                    key: teacher._id,
                    teacherId: teacher.userId,
                    teacher: teacher,
                  })) ?? []
                }
              />
              <button
                onClick={() => addteacherAttendance(selectedOutTeacher)}
                className="bg-gray-500 px-2 py-1 text-[15px] rounded ml-2 text-white tracking-widest"
                disabled={!selectedOutTeacher}
              >
                Mark Out
              </button>
            </div>
            <div className="h-[100%] overflow-y-auto mt-4">
              <div className="sticky top-0 bg-white mx-3 z-10">
                <table className="w-full border bg-white bg-opacity-25">
                  <thead>
                    <tr className="flex justify-evenly text-center">
                      <th className="border w-full px-2">First Name</th>
                      <th className="border w-full px-2">Last Name</th>
                      <th className="border w-full px-2">Time In</th>
                      <th className="border w-full px-2">Time Out</th>
                      <th className="border w-full px-2">Action</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="text-lg px-3">
                <table className="w-full border bg-white bg-opacity-25">
                  <tbody>
                    {fetchAttendances &&
                      fetchAttendances?.map((ele, val) => (
                        <tr className="flex border justify-evenly">
                          <td className="capitalize w-full px-2">
                            {ele.firstName}
                          </td>
                          <td className="w-full capitalize px-2">
                            {ele.lastName}
                          </td>
                          <td className="uppercase w-full px-2">
                            {ele.timeIn}
                          </td>
                          <td className="uppercase w-full px-2">
                            {ele.timeOut}
                          </td>
                          <td className="w-full flex justify-center">
                            <button
                              className="text-red-800 transition-all duration-300 hover:text-opacity-50"
                              onClick={() => deleteAttendance(ele)}
                            >
                              <CiTrash
                                className="text-red-800 transition-all duration-300 hover:text-opacity-50"
                                size={25}
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Attendance;
