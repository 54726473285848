import React, { useContext, useEffect, useState } from "react";
import image from "../assets/loginImg.jpg";
import { Select, message } from "antd";
import { useForm, Controller, set } from "react-hook-form";
import QRScanner from "../components/common/QrModal";
import { ScannerQRContext } from "../context/scannerqr";
import { CiTrash } from "react-icons/ci";
import PostErorr from "../utils/errorlog";
import { sectionColors } from "../components/constants/constant";
import Footer from "../components/common/Footer";
import moment from "moment";
const Attendance = () => {
  const { id, setId } = useContext(ScannerQRContext);
  const [students, setStudents] = useState([]);
  const [attendancedate, setAttendancedate] = useState([]);
  const [formData, setFormData] = useState({ classGrade: null, section: null });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const token = localStorage.getItem("authToken");
  const [render, setRender] = useState(false);
  const authToken = `${token}`;

  useEffect(() => {
    const fetchAttendance = async () => {
      if (date) {
        console.log("Fetching attendance for date:", date);
        try {
          const response = await fetch(
            `https://apischool.lineup.pk/leave/${date}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "auth-token": authToken,
              },
            }
          );
          const data = await response.json();
          console.log(data);
          setAttendancedate(data);
        } catch (error) {
          PostErorr(error);

          console.error("Error getting attendance:", error);
        }
      }
    };

    fetchAttendance();
  }, [date, authToken, searchQuery, id, render]);

  let isSubmitting = false;
  const addstudenttoLeave = async (student) => {
    console.log(isSubmitting);
    if (isSubmitting) return;
    if (!authToken) {
      console.error("Auth token not found.");
      return;
    }
    if (!student._id || searchQuery === "Select Student") {
      message.error("Please select a student to mark attendance");
      return;
    }
    isSubmitting = true;
    try {
      const response = await fetch("https://apischool.lineup.pk/leave", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
        body: JSON.stringify({
          date,
          students: {
            id: student._id,
            present: true,
          },
        }),
      });

      const data = await response.json();
      if (data.message === "Student already marked present on this date") {
        message.warning(
          `${student.name} is already marked present on this date`
        );
        isSubmitting = false;
        return;
      }
      message.success("Attendance marked successfully");
      setSearchQuery("Select Student");
      console.log(data);

      // console.log(data);
    } catch (error) {
      PostErorr(error);

      console.error("Error marking attendance:", error);
    }
    try {
      const response = await fetch(
        "https://apischool.lineup.pk/AttendanceNew/addAttendances",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
          body: JSON.stringify({
            studentId: student._id,
            S_No: student.id,
            studentName: student.name,
            parentName: student.parentName,
            className: student.classGrade,
            section: student.section,
            attendance: {
              date: date,
              present: true,
            },
          }),
        }
      );
      const data = await response.json();
      if (data.message === "Attendance Already Mark") {
        message.warning(data.message);
        return;
      }
    } catch (error) {
      console.error("Error marking attendance:", error);
    }
    isSubmitting = false;
  };

  useEffect(() => {
    const fetchStudents = async () => {
      if (!authToken) {
        console.error("Auth token not found.");
        return;
      }

      try {
        const response = await fetch(
          "https://apischool.lineup.pk/student/getstudents",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );

        const data = await response.json();

        if (Array.isArray(data)) {
          setStudents(data);
        } else {
          console.error("Expected an array but got:", data);
          setStudents([]);
        }
      } catch (error) {
        PostErorr(error);

        console.error("Error fetching students:", error);
        setStudents([]);
      }
    };

    fetchStudents();
  }, [authToken, selectedStudent, searchQuery, id]);

  const filteredAttendanceByDate = students
    .map((student) => {
      const studentAttendance = attendancedate.find((s) =>
        s.students.some((stu) => stu.id === student._id)
      );

      if (studentAttendance) {
        const attendanceDetails = studentAttendance.students.find(
          (stu) => stu.id === student._id
        );
        return {
          ...student,
          ...attendanceDetails,
        };
      }
      return null; // Return null if no attendance record is found
    })
    .filter((student) => {
      if (!student) return false; // Filter out null values

      if (formData.classGrade && formData.section) {
        return (
          student.classGrade === formData.classGrade &&
          student.section === formData.section
        );
      }
      if (formData.classGrade) {
        return student.classGrade === formData.classGrade;
      }
      if (formData.section) {
        return student.section === formData.section;
      }
      // return true; // If formData is not provided, include all students
    });

  const filteredStudents = students
    .filter(
      (student) =>
        !attendancedate.some((s) =>
          s.students.some((stu) => stu.id === student._id)
        )
    )
    .filter((student) => {
      if (formData.classGrade && formData.section) {
        return (
          student.classGrade === formData.classGrade &&
          student.section === formData.section
        );
      }
      if (formData.classGrade) {
        return student.classGrade === formData.classGrade;
      }
      if (formData.section) {
        return student.section === formData.section;
      }
      // return true; // If formData is not provided, include all students
    });

  const uniqueSections = [
    ...new Set(students.map((student) => student.section)),
  ];
  const uniqueclassGrades = [
    ...new Set(students.map((student) => student.classGrade)),
  ];

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // Filtering happens automatically whenever formData changes
  }, [formData]);

  const handleClassGradeChange = (value) => {
    setValue("classGrade", value);
    setFormData((prevData) => ({ ...prevData, classGrade: value }));
  };

  const handleSectionChange = (value) => {
    setValue("section", value);
    setFormData((prevData) => ({ ...prevData, section: value }));
  };
  const handleChange = (value, option) => {
    setSearchQuery(value);
    setSelectedStudent(option?.student);
  };

  async function deleteLeave(id) {
    // console.log("Deleting student attendance record:", id);
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/leave/${date}/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      const data = await response.json();
      if (data.message === "No attendance record found for this date") {
        message.warning(data.message);
        return;
      }
      message.success("Student attendance record deleted successfully");
      setRender(!render);
      // console.log(data);
    } catch (error) {
      PostErorr(error);

      console.error("Error deleting student attendance record:", error);
    }
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/AttendanceNew/${date}/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      const data = await response.json();
      if (data.message === "No attendance record found for this date") {
        message.warning(data.message);
        return;
      }
      setRender(!render);
    } catch (error) {
      console.error("Error deleting student attendance record:", error);
    }
  }

  return (
    <>
      <div
        className="h-screen w-3/4 lg:w-2/3 xl:w-1/2 mx-auto rounded-lg overflow-hidden"
        style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
      >
        <div className=" p-3">
          <div className="flex flex-col sm:flex-row sm:flex-wrap justify-between items-center">
            <QRScanner
              button="Scan Attendance"
              addstudenttoLeave={addstudenttoLeave}
              selectedStudent={selectedStudent}
            />
            <div>
              <input
                className=" px-2 py-1 my-2 transition-all duration-300 focus:outline-none rounded text-[#000000D9] border bg-white "
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
            </div>
            <div className="flex items-center gap-2">
              <Select
                className="w-40"
                placeholder="Select Teacher"
                value={searchQuery}
                showSearch
                allowClear
                onChange={handleChange}
                options={
                  filteredStudents.map((student) => ({
                    value: `${student.name} ( ${student.classGrade} - ${student.section} )`,
                    label: `${student.name} ( ${student.classGrade} - ${student.section} )`,
                    key: student._id,
                    studentId: student._id,
                    student: student,
                  })) ?? []
                }
              />
              <button
                onClick={() => addstudenttoLeave(selectedStudent)}
                className="bg-gray-500  px-2 py-1 rounded ml-2 text-white tracking-widest"
                disabled={!searchQuery}
              >
                Mark
              </button>
            </div>
          </div>

          <div className="w-[80%] mt-3 flex mx-auto">
            <Controller
              name="classGrade"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  showSearch
                  placeholder={errors.classGrade?.message ?? "Select Class"}
                  className="w-1/2"
                  allowClear
                  filterSort={(optionA, optionB) => {
                    const valueA = parseInt(optionA?.label ?? "", 10);
                    const valueB = parseInt(optionB?.label ?? "", 10);
                    return valueA - valueB;
                  }}
                  options={uniqueclassGrades.map((classGrade) => ({
                    value: classGrade,
                    label: classGrade,
                  }))}
                  onChange={handleClassGradeChange}
                />
              )}
            />

            <Controller
              name="section"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  showSearch
                  placeholder={errors.section?.message ?? "Select Section"}
                  className="w-1/2"
                  allowClear
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={uniqueSections.map((section) => ({
                    value: section,
                    label: section,
                  }))}
                  onChange={handleSectionChange}
                />
              )}
            />
          </div>

          <div className="h-[80%] text-center">
            <h1 className="text-2xl font-semibold my-2">
              {moment(date).format("DD-MMM-YYYY")}
            </h1>
            <div className="h-[330px] p-3">
              <div className="flex justify-between">
                <h2 className="font-bold text-xl">Students List</h2>
                <div className="flex items-center gap-2"></div>
              </div>
              <div className="h-[100%] overflow-y-auto">
                <div className="sticky top-0 bg-white mx-3 z-10">
                  <table className="w-full border bg-white bg-opacity-25">
                    <thead>
                      <tr className="flex justify-evenly border">
                        <th className="border w-full px-2">Student Name</th>
                        <th className="border w-full px-2">Parent Name</th>
                        <th className="border w-full px-2">Class & Section</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="text-lg px-3">
                  <table className="w-full border bg-white bg-opacity-25">
                    <tbody>
                      {filteredStudents
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        .map((student) => (
                          <tr
                            key={student.id}
                            className="flex border justify-evenly"
                          >
                            <td className="capitalize w-full px-2">
                              {student.name}
                            </td>
                            <td className="w-full capitalize px-2">
                              {student.parentName}
                            </td>
                            <td className="uppercase w-full px-2">
                              {student.classGrade} -{" "}
                              <span
                                className={`${sectionColors[student.section]}`}
                              >
                                {student.section}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="h-[330px] p-3 my-2">
            <div className="flex justify-between">
              <h2 className="font-bold text-xl">Attendance List By Date</h2>
              <div className="flex items-center gap-2"></div>
            </div>
            <div className="h-[100%] overflow-y-auto">
              <div className="sticky top-0 bg-white mx-3 z-10">
                <table className="w-full border bg-white bg-opacity-25">
                  <thead>
                    <tr className="flex justify-evenly text-center">
                      <th className="border w-full px-2">Student Name</th>
                      <th className="border w-full px-2">Parent Name</th>
                      <th className="border w-full px-2">Class & Section</th>
                      <th className="border w-full px-2">Action</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="text-lg px-3">
                <table className="w-full border bg-white bg-opacity-25">
                  <tbody>
                    {filteredAttendanceByDate
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((student) => (
                        <tr
                          key={student?.id}
                          className="flex border justify-evenly"
                        >
                          <td className="capitalize w-full px-2">
                            {student?.name}
                          </td>
                          <td className="w-full capitalize px-2">
                            {student?.parentName}
                          </td>
                          <td className="uppercase w-full px-2">
                            {student?.classGrade} -{" "}
                            <span
                              className={`${sectionColors[student?.section]}`}
                            >
                              {student?.section}
                            </span>
                          </td>
                          <td className="w-full flex justify-center">
                            <button
                              className="text-red-800 transition-all duration-300 hover:text-opacity-50"
                              onClick={() => deleteLeave(student?.id)}
                            >
                              <CiTrash
                                className="text-red-800 transition-all duration-300 hover:text-opacity-50"
                                size={25}
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Attendance;
