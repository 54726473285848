import React from "react";

const ReminderComponents = React.memo(({
  studentName,
  classGrade,
  section,
  id,
  fetch,
  
}) => {
  // fetch(id)
  return (
    <>
      <div
        className="w-fit text-center font-semibold rounded-bl-[30px] rounded-tr-[30px] bg-white m-auto flex flex-col text-[10px] sm:text-[12px] justify-center py-4 px-4 
        md:text-base md:py-5 md:px-8 md:min-h-24  
        lg:min-h-30 lg:w-60 lg:m-0 lg:text-lg lg:font-bold"  
      >
        <h3> {studentName} </h3>
        <h3> {`Class ${classGrade} -${section}`} </h3>
      </div>
    </>
  );
});

export default ReminderComponents;
