import React, { useState, useEffect, useRef, useMemo } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import schoolLogo from "../../assets/logoo.png";
import { IoMdCloudDownload } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClassReportPdf from "../../components/common/classReportpdf";
import StudentTable from "../../components/StudentTable";
import PostErorr from "../../utils/errorlog";
import AttendanceTable from "../../components/AttendanceTable";
import { sectionColors } from "../../components/constants/constant";
import ReportTitle from "../../components/ReportTitle";
import Footer from "../../components/common/Footer";
function AttendanceReports() {
  // const [students, setStudents] = useState([]);
  const [allStudents, setAllStudents] = useState([]);
  const [chooseAttendance, setChooseAttendance] = useState("both");
  const [showPresent, setShowPresent] = useState(true);
  const [showAbsent, setShowAbsent] = useState(true);
  const [sections, setSections] = useState([]);
  const [classSection, setclassSection] = useState([]);
  const [selectedClass, setSelectedClass] = useState();
  const [selectedSection, setSelectedSection] = useState();
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [dates, setDates] = useState(new Date().toLocaleDateString("en-GB"));
  const [dateAttendance, setDateAttendance] = useState([]);
  const token = localStorage.getItem("authToken");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const reportRef = useRef();
  const headerRef = useRef();
  const headerRef2 = useRef();
  const footerRef = useRef();
  const [showClasses, setShowClasses] = useState();
  const users = JSON.parse(localStorage.getItem("userdetails"));
  // Date Format Changes
  const handleDateChange = (event) => {
    const formatDate = event.target.value.split("-").reverse().join("/");
    console.log(formatDate);
    setDates(formatDate);
    setDate(event.target.value);
    setShowClasses();
  };

  useEffect(() => {
    fetchStudents();
  }, []);
  useEffect(() => {
    const getSection = allStudents.filter(
      (student) => student.classGrade === selectedClass
    );
    const uniqueSections = [
      ...new Set(getSection.map((student) => student.section)),
    ];
    setSections(uniqueSections);
  }, [selectedClass]);
  // console.log(sections)

  const fetchStudents = async () => {
    try {
      const response = await fetch(
        "https://apischool.lineup.pk/student/getstudents",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
        }
      );
      const data = await response.json();
      setAllStudents(data);
      setclassSection(data);
    } catch (error) {
      console.error("Error fetching buses:", error.message);
      PostErorr(error);
    }
  };

  const fetchAttendance = async () => {
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/AttendanceNew/getAttendances?date=${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      setDateAttendance(data);
    } catch (error) {
      console.error("Error fetching leave students:", error.message);
      PostErorr(error);
    }
  };

  const uniqueClassSection = classSection.map(({ classGrade, section }) => {
    return { classGrade, section };
  });

  uniqueClassSection.sort((a, b) => {
    const gradeA = parseInt(a.classGrade);
    const gradeB = parseInt(b.classGrade);

    if (gradeA === gradeB) {
      return a.section.localeCompare(b.section);
    }
    return gradeA - gradeB;
  });
  let uniqueSections = {};
  let filteredData = uniqueClassSection.filter((item) => {
    if (!uniqueSections[item.classGrade]) {
      uniqueSections[item.classGrade] = new Set();
      uniqueSections[item.classGrade].add(item.section);
      return true;
    } else {
      if (!uniqueSections[item.classGrade].has(item.section)) {
        uniqueSections[item.classGrade].add(item.section);
        return true;
      }
    }
    return false;
  });
  const submithandler = async (dates, selectedClass, selectedSection) => {
    console.log(chooseAttendance);
    if (chooseAttendance == "absent") {
      setShowPresent(false);
      setShowAbsent(true);
    } else if (chooseAttendance == "present") {
      setShowAbsent(false);
      setShowPresent(true);
    } else if (chooseAttendance == "both") {
      setShowPresent(true);
      setShowAbsent(true);
    }

    if (selectedClass === "All") {
      setShowClasses(filteredData);
      fetchAttendance();
    } else {
      if (selectedSection) {
        if (selectedSection === "All") {
          const filterClass = filteredData.filter((classItem) => {
            return classItem.classGrade === selectedClass;
          });
          setShowClasses(filterClass);
          fetchAttendance();
        } else {
          const filterClass = filteredData.filter((classItem) => {
            return (
              classItem.classGrade === selectedClass &&
              classItem.section === selectedSection
            );
          });
          setShowClasses(filterClass);
          fetchAttendance();
        }
      } else {
        const filterClass = filteredData.filter((classItem) => {
          return classItem.classGrade === selectedClass;
        });
        setShowClasses(filterClass);
        fetchAttendance();
      }
    }
  };

  const handleSelectedClass = (e) => {
    setSelectedClass(e.target.value);
    // setSelectedSection('')
  };

  const handleDownloadPdf = async () => {
    const input = reportRef.current;
    const footervalues = footerRef.current;
    const headerValues = headerRef.current;
    const header2Values = headerRef2.current;

    // Capture the header and footer as images
    const headerCanvas = await html2canvas(headerValues, { scale: 2 });
    const headerData = headerCanvas.toDataURL("image/png");
    const header2Canvas = await html2canvas(header2Values, { scale: 2 });
    const header2Data = header2Canvas.toDataURL("image/png");
    const footerCanvas = await html2canvas(footervalues, { scale: 2 });
    const footerData = footerCanvas.toDataURL("image/png");

    // Create a new PDF document
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const headerHeight = 35; // Adjust based on your header height
    const footerHeight = 20; // Adjust based on your footer height
    const contentHeight = pageHeight - headerHeight - footerHeight;

    // Get all rows from the table
    const rows = Array.from(input.querySelectorAll("tr"));
    const totalRows = rows.length;
    const rowsPerPage = 22;
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    for (let i = 0; i < totalPages; i++) {
      const startRow = i * rowsPerPage;
      const endRow = Math.min(startRow + rowsPerPage, totalRows);
      const tableChunk = document.createElement("table");
      tableChunk.style.width = input.offsetWidth + "px"; // Ensure the table width matches the original table

      rows.slice(startRow, endRow).forEach((row) => {
        const rowClone = row.cloneNode(true);
        tableChunk.appendChild(rowClone);
      });

      document.body.appendChild(tableChunk);
      const chunkCanvas = await html2canvas(tableChunk, { scale: 2 });
      document.body.removeChild(tableChunk);

      const imgData = chunkCanvas.toDataURL("image/png");

      // Add header
      if (i === 0) {
        pdf.addImage(headerData, "PNG", 0, 0, imgWidth, headerHeight);
      } else {
        pdf.addImage(header2Data, "PNG", 0, 0, imgWidth, headerHeight);
      }

      // Add main content
      const imgHeight = (chunkCanvas.height * imgWidth) / chunkCanvas.width;
      console.log(chunkCanvas.height);
      const contentY = headerHeight + (contentHeight - imgHeight) / 2; // Center the content vertically

      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      // Add footer
      pdf.setFillColor(255, 255, 255);
      pdf.rect(0, pageHeight - footerHeight, imgWidth, footerHeight, "F");
      pdf.addImage(
        footerData,
        "PNG",
        0,
        pageHeight - footerHeight,
        imgWidth,
        footerHeight
      );
      pdf.setFontSize(8);
      const pageNumText = `Page ${i + 1} of ${totalPages}`;
      const pageNumTextWidth = pdf.getTextWidth(pageNumText);
      pdf.text(pageNumText, imgWidth - pageNumTextWidth - 4, pageHeight - 5);

      if (i < totalPages - 1) {
        pdf.addPage();
      }
    }
    pdf.save(`atendance_report-${dates}.pdf`);
  };

  console.log(dateAttendance);
  return (
    <>
      <div className="container min-h-screen">
        <div className="flex justify-between items-center">
          <div className="flex items-center py-2 gap-2">
            <img src={schoolLogo} alt="" className="w-[50px] sm:w-[75px]" />
            <div className="flex flex-col">
              <h2 className="font-bold text-[lg] sm:text-[20px]">
                The Avicenna School
              </h2>
              <p className="text-[12px] sm:text-[16px]">
                XYZ Road Karachi Pakistan
              </p>
              <p className="text-[12px] sm:text-[16px]">
                Phone: <span>021-333456789</span>
              </p>
            </div>
          </div>
          <button
            disabled={!showClasses}
            className="w-16 h-12"
            style={{ cursor: showClasses ? "pointer" : "not-allowed" }}
          >
            <IoMdCloudDownload
              className="w-full h-full"
              onClick={handleDownloadPdf}
            />
          </button>
        </div>
        <hr />
        {/* <div className="flex items-center justify-between p-3 flex-col"> */}
        <div className="mt-3 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-5  lg:grid-cols-5 xl:grid-cols-6 gap-3 items-center">
          <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <h2 className="font-bold text-[lg] lg:text-xl">
              Students Attendance Report
            </h2>
          </div>
          <div className="flex justify-end items-center col-span-1 sm:col-span-2 lg:col-span-1">
            <span className="mr-3 font-semibold">
              As On:&nbsp;&nbsp;{dates}
            </span>
            <input
              type="date"
              id="todayDate"
              onChange={handleDateChange}
              className="date-input w-[20px] h-[24px] outline-none"
            />
          </div>

          <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <select
              id=""
              className="w-full h-8 border"
              onChange={handleSelectedClass}
            >
              <option selected disabled>
                Selected Class
              </option>
              <option value="All">All</option>
              {Array.from(new Set(classSection.map((x) => x.classGrade)))
                .sort((a, b) => parseInt(a) - parseInt(b))
                .map((grade, index) => (
                  <option key={index} value={grade}>
                    {grade}
                  </option>
                ))}
            </select>
          </div>

          <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <select
              id=""
              className="w-full h-8 border"
              onChange={(e) => setSelectedSection(e.target.value)}
            >
              <option disabled selected>
                Selected Section
              </option>
              <option value="All">All</option>
              {sections &&
                sections
                  .sort((a, b) => a.localeCompare(b))
                  .map((section, index) => (
                    <option key={index} value={section}>
                      {section}
                    </option>
                  ))}
            </select>
          </div>

          <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <select
              className="w-full h-8 border"
              onChange={(e) => setChooseAttendance(e.target.value)}
            >
              <option value="both">Both</option>
              <option value="present">Present</option>
              <option value="absent">Absent</option>
            </select>
          </div>

          <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <button
              className="w-full border p-1 text-white"
              disabled={!selectedClass}
              onClick={() =>
                submithandler(dates, selectedClass, selectedSection)
              }
              style={{
                cursor: !selectedClass ? "not-allowed" : "pointer",
                backgroundColor: !selectedClass ? "gray" : "#212529",
              }}
            >
              Search
            </button>
          </div>
        </div>
        {/* </div> */}
        <div>
          {showClasses &&
            showClasses.map((totalClass, index) => {
              const find = dateAttendance.filter(
                ({ className, section }, index) => {
                  const classWiseFilter =
                    className === totalClass.classGrade &&
                    section === totalClass.section;
                  // console.log(classWiseFilter)
                  return classWiseFilter;
                }
              );
              const attendanceResult = find.reduce(
                (result, student) => {
                  student.attendance.forEach((att) => {
                    const attendanceDate = new Date(
                      att.date
                    ).toLocaleDateString("en-GB");
                    const selectedDate = new Date(date).toLocaleDateString(
                      "en-GB"
                    );
                    if (attendanceDate === selectedDate) {
                      if (att.present) {
                        result.present.push(student);
                      } else {
                        result.absent.push(student);
                      }
                    }
                  });
                  return result;
                },
                { present: [], absent: [] }
              );
              console.log(find);
              const { present: presentStudents, absent: absentStudents } =
                attendanceResult;
              console.log(presentStudents);
              console.log(absentStudents);
              return (
                <div className="border-black border-1 my-4">
                  <table className="table-auto mx-auto w-12/12 border-collapse text-sm sm:text-base md:text-lg">
                    <thead>
                      <tr className="border-none">
                        <th className="py-2" colSpan="3">
                          <span className="bg-black text-white p-2">
                            Class: {totalClass.classGrade} ({totalClass.section}
                            )
                          </span>
                        </th>
                        <th className="py-2" colSpan="3">
                          <span className="p-2">
                            Month of {months[new Date(date).getMonth()]}
                          </span>
                        </th>
                      </tr>
                      <tr className="py-2 border-white">
                        {/* <td colSpan="3" className='font-semibold border-white text-[20px]'>Total Students - {filterSection.length}</td> */}
                        {/* <td colSpan="3" className='font-semibold border-white text-[20px]'><span>Present - {attendanceSection.length}</span>&nbsp;&nbsp;&nbsp;<span>Absent - {filteredAttendance.length}</span></td> */}
                      </tr>
                      <tr className="text-center border-none bg-dark text-white text-[10px] sm:text-[16px] md:text-[18px]">
                        <th className="sm:py-2">Student ID</th>
                        <th className="sm:py-2">Student Name</th>
                        <th className="sm:py-2">Parent Name</th>
                        <th className="sm:py-2">Last Present</th>
                        <th className="sm:py-2">Total Present</th>
                        <th className="sm:py-2">Total Absents</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showPresent && (
                        <>
                          <tr className="text-center border-none">
                            <th className="border-b-2 border-r-2 border-t-2 border-black bg-gray-400 text-black sm:py-2 text-[10px] sm:text-[16px] md:text-[18px]">
                              Present
                            </th>
                            {presentStudents.length > 0 && (
                              <th className="sm:py-2" colSpan={3}>
                                {presentStudents.length} Student Present
                              </th>
                            )}
                          </tr>
                          {showPresent &&
                            presentStudents &&
                            presentStudents.map((student, index) => {
                              let totalPresents = 0;
                              let totalAbsents = 0;
                              const findLastPresent = student.attendance
                                .filter((attendance) => attendance.present)
                                .reduce(
                                  (max, current) => {
                                    return new Date(max.date) >
                                      new Date(current.date)
                                      ? max
                                      : current;
                                  },
                                  { date: "1970-01-01T00:00:00.000Z" }
                                );
                              student.attendance.forEach((attendance) => {
                                if (
                                  attendance.present &&
                                  new Date(date).getMonth() ===
                                    new Date(attendance.date).getMonth()
                                ) {
                                  totalPresents++;
                                }
                              });
                              student.attendance.forEach((attendance) => {
                                if (
                                  !attendance.present &&
                                  new Date(date).getMonth() ===
                                    new Date(attendance.date).getMonth()
                                ) {
                                  totalAbsents++;
                                }
                              });

                              return (
                                <tr
                                  className={`text-center border-none ${
                                    index % 2 === 0 ? "bg-gray-200" : "bg-white"
                                  } text-center text-[10px] sm:text-[16px] md:text-[18px]`}
                                >
                                  <td>{student.S_No}</td>
                                  <td>{student.studentName}</td>
                                  <td>{student.parentName}</td>
                                  <td>
                                    {new Date(
                                      findLastPresent.date
                                    ).toLocaleDateString("en-GB")}
                                  </td>
                                  <td>{totalPresents}</td>
                                  <td>{totalAbsents}</td>
                                </tr>
                              );
                            })}
                        </>
                      )}
                      {showAbsent && (
                        <>
                          <tr className="text-center border-none ">
                            <th className="border-b-2 border-r-2 border-t-2 border-black bg-gray-400 text-black sm:py-2 text-[10px] sm:text-[16px] md:text-[18px]">
                              Absent
                            </th>
                            {absentStudents.length > 0 && (
                              <th className="sm:py-2" colSpan={3}>
                                {absentStudents.length} Student Absent
                              </th>
                            )}
                          </tr>
                          {absentStudents &&
                            absentStudents.map((student, index) => {
                              let totalPresents = 0;
                              let totalAbsents = 0;

                              const findLastPresent = student.attendance
                                .filter((attendance) => attendance.present)
                                .reduce(
                                  (max, current) => {
                                    return new Date(max.date) >
                                      new Date(current.date)
                                      ? max
                                      : current;
                                  },
                                  { date: "1970-01-01T00:00:00.000Z" }
                                );
                              console.log(findLastPresent);
                              student.attendance.forEach((attendance) => {
                                if (
                                  attendance.present &&
                                  new Date(date).getMonth() ===
                                    new Date(attendance.date).getMonth()
                                ) {
                                  totalPresents++;
                                }
                              });
                              student.attendance.forEach((attendance) => {
                                if (
                                  !attendance.present &&
                                  new Date(date).getMonth() ===
                                    new Date(attendance.date).getMonth()
                                ) {
                                  totalAbsents++;
                                }
                              });

                              return (
                                <tr
                                  className={`text-center border-none ${
                                    index % 2 === 0 ? "bg-gray-200" : "bg-white"
                                  } text-center text-[10px] sm:text-[16px] md:text-[18px]`}
                                >
                                  <td>{student.S_No}</td>
                                  <td>{student.studentName}</td>
                                  <td>{student.parentName}</td>
                                  <td>
                                    {findLastPresent.date ==
                                    "1970-01-01T00:00:00.000Z"
                                      ? "-------"
                                      : new Date(
                                          findLastPresent?.date
                                        ).toLocaleDateString("en-GB")}
                                  </td>
                                  <td>{totalPresents}</td>
                                  <td>{totalAbsents}</td>
                                </tr>
                              );
                            })}
                          <tr></tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              );
            })}
        </div>
        <span
          style={{
            // position:'absolute',
            // top:'3000px',
            position: "fixed",
            left: "-9999px",
            width: "210mm",
          }}
        >
          <ReportTitle
            title="Attendance Report"
            headerRef={headerRef}
            headerRef2={headerRef2}
            date={dates}
          />
          <div ref={reportRef}>
            {showClasses &&
              showClasses.map((totalClass, index) => {
                const find = dateAttendance.filter(
                  ({ className, section }, index) => {
                    const classWiseFilter =
                      className === totalClass.classGrade &&
                      section === totalClass.section;
                    // console.log(classWiseFilter)
                    return classWiseFilter;
                  }
                );
                const attendanceResult = find.reduce(
                  (result, student) => {
                    student.attendance.forEach((att) => {
                      const attendanceDate = new Date(
                        att.date
                      ).toLocaleDateString("en-GB");
                      const selectedDate = new Date(date).toLocaleDateString(
                        "en-GB"
                      );
                      if (attendanceDate === selectedDate) {
                        if (att.present) {
                          result.present.push(student);
                        } else {
                          result.absent.push(student);
                        }
                      }
                    });
                    return result;
                  },
                  { present: [], absent: [] }
                );
                console.log(find);
                const { present: presentStudents, absent: absentStudents } =
                  attendanceResult;
                console.log(presentStudents);
                console.log(absentStudents);
                return (
                  <div className="border-black border-1 my-4">
                    <table className="table-auto mx-auto w-12/12 border-collapse">
                      <thead>
                        <tr className=" border-none h-[40px] ">
                          <th className="" colSpan="3">
                            <span className="pb-2 bg-gray-400 text-black">
                              Class: {totalClass.classGrade} (
                              {totalClass.section})
                            </span>
                          </th>
                          <th className="" colSpan="3">
                            <span className="pb-2">
                              Month of {months[new Date(date).getMonth()]}
                            </span>
                          </th>
                        </tr>
                        <tr className="p-1 text-center border-none  bg-gray-300 text-black">
                          <th className="pb-2">Student ID</th>
                          <th className="pb-2">Student Name</th>
                          <th className="pb-2">Parent Name</th>
                          <th className="pb-2">Last Present</th>
                          <th className="pb-2">Total Present</th>
                          <th className="pb-2">Total Absents</th>
                        </tr>
                      </thead>
                      <tbody>
                        {showPresent && (
                          <>
                            <tr className="text-center border-none p-1">
                              <th className="border-b-2 border-r-2 border-t-2 border-black bg-gray-300 pb-2 text-black">
                                Present
                              </th>
                              {presentStudents.length > 0 && (
                                <th className="pb-2">
                                  {presentStudents.length} Student Present
                                </th>
                              )}
                            </tr>
                            {showPresent &&
                              presentStudents &&
                              presentStudents.map((student, index) => {
                                let totalPresents = 0;
                                let totalAbsents = 0;
                                const findLastPresent = student.attendance
                                  .filter((attendance) => attendance.present)
                                  .reduce(
                                    (max, current) => {
                                      return new Date(max.date) >
                                        new Date(current.date)
                                        ? max
                                        : current;
                                    },
                                    { date: "1970-01-01T00:00:00.000Z" }
                                  );
                                student.attendance.forEach((attendance) => {
                                  if (
                                    attendance.present &&
                                    new Date(date).getMonth() ===
                                      new Date(attendance.date).getMonth()
                                  ) {
                                    totalPresents++;
                                  }
                                });
                                student.attendance.forEach((attendance) => {
                                  if (
                                    !attendance.present &&
                                    new Date(date).getMonth() ===
                                      new Date(attendance.date).getMonth()
                                  ) {
                                    totalAbsents++;
                                  }
                                });

                                return (
                                  <tr
                                    className={`text-center border-none ${
                                      index % 2 === 0
                                        ? "bg-gray-200"
                                        : "bg-white"
                                    } text-center`}
                                  >
                                    <td className="pb-2">{student.S_No}</td>
                                    <td className="pb-2">
                                      {student.studentName}
                                    </td>
                                    <td className="pb-2">
                                      {student.parentName}
                                    </td>
                                    <td className="pb-2">
                                      {new Date(
                                        findLastPresent.date
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td className="pb-2">{totalPresents}</td>
                                    <td className="pb-2">{totalAbsents}</td>
                                  </tr>
                                );
                              })}
                          </>
                        )}
                        {showAbsent && (
                          <>
                            <tr className="text-center border-none ">
                              <th className="border-b-2 border-r-2 border-t-2 border-black bg-gray-300 pb-2 text-black">
                                Absent
                              </th>
                              {absentStudents.length > 0 && (
                                <th className="pb-2">
                                  {absentStudents.length} Student Absent
                                </th>
                              )}
                            </tr>
                            {absentStudents &&
                              absentStudents.map((student, index) => {
                                let totalPresents = 0;
                                let totalAbsents = 0;

                                const findLastPresent = student.attendance
                                  .filter((attendance) => attendance.present)
                                  .reduce(
                                    (max, current) => {
                                      return new Date(max.date) >
                                        new Date(current.date)
                                        ? max
                                        : current;
                                    },
                                    { date: "1970-01-01T00:00:00.000Z" }
                                  );
                                console.log(findLastPresent);
                                student.attendance.forEach((attendance) => {
                                  if (
                                    attendance.present &&
                                    new Date(date).getMonth() ===
                                      new Date(attendance.date).getMonth()
                                  ) {
                                    totalPresents++;
                                  }
                                });
                                student.attendance.forEach((attendance) => {
                                  if (
                                    !attendance.present &&
                                    new Date(date).getMonth() ===
                                      new Date(attendance.date).getMonth()
                                  ) {
                                    totalAbsents++;
                                  }
                                });

                                return (
                                  <tr
                                    className={`text-center border-none ${
                                      index % 2 === 0
                                        ? "bg-gray-200"
                                        : "bg-white"
                                    } text-center`}
                                  >
                                    <td className="pb-2">{student.S_No}</td>
                                    <td className="pb-2">
                                      {student.studentName}
                                    </td>
                                    <td className="pb-2">
                                      {student.parentName}
                                    </td>
                                    <td className="pb-2">
                                      {findLastPresent.date ==
                                      "1970-01-01T00:00:00.000Z"
                                        ? "-------"
                                        : new Date(
                                            findLastPresent?.date
                                          ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td className="pb-2">{totalPresents}</td>
                                    <td className="pb-2">{totalAbsents}</td>
                                  </tr>
                                );
                              })}
                            <tr className="h-[40px] border-black border-t-2"></tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                );
              })}
          </div>
          <PDFFooter footerRef={footerRef} />
        </span>
      </div>
      <Footer />
    </>
  );
}

export default AttendanceReports;

const PDFFooter = ({ footerRef }) => {
  const user = JSON.parse(localStorage.getItem("userdetails"));

  const today = useMemo(() => {
    const today = new Date();
    const date = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    return { date, month, year };
  }, []);
  return (
    <div
      ref={footerRef}
      className="w-[95vw] mx-auto fixed bottom-10 left-[-9999px] right-0"
    >
      <div className="w-full flex justify-between items-start p-3 px-4 border-t">
        <div>
          <div className="flex items-center gap-2">
            <h3>Printed On:</h3>
            <p>
              <b>{`${new Date().toLocaleDateString(
                "en-GB"
              )}-${new Date().toLocaleTimeString()}`}</b>
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <h3>printed by:</h3>
            <p className="font-bold">{user.name}</p>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};
