const sectionColors = {
    // Red: 'text-red-500',
    // Blue: 'text-blue-500',
    // Green: 'text-green-500',
    // Yellow: 'text-yellow-500',
    // Purple: 'text-purple-500',
    // Pink: 'text-pink-500',
    // Orange: 'text-orange-500',
    // Teal: 'text-teal-500',
    // Indigo: 'text-indigo-500',
    // Gray: 'text-gray-500',
    // Lime: 'text-lime-500',
    // Cyan: 'text-cyan-500',
    // Amber: 'text-amber-500',
    // Fuchsia: 'text-fuchsia-500',
    // Rose: 'text-rose-500',
    // Violet: 'text-violet-500',
    // Sky: 'text-sky-500',
    // Emerald: 'text-emerald-500',
    // Slate: 'text-slate-500',
    // Stone: 'text-stone-500',
  };

  export {
    sectionColors,
  }