import React, { useContext, useState } from "react";
import { Modal, QRCode } from "antd";
import Scanner from "./scanner";
const QRScanner = ({button, message,addstudenttoLeave,handleStudentSelection,getStudentById,VanDriverScan,addteacherAttendance}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        className="bg-gray-500  px-2 py-1 rounded text-white tracking-widest"
        type="primary"
        onClick={showModal}
      >
        {button}
      </button>
      <Modal
        title={null}
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        // className="!w-[400px] "
      >
        <div className="">
        <Scanner message={message} 
        button={button} 
        addstudenttoLeave={addstudenttoLeave} 
        handleStudentSelection={handleStudentSelection}
        getStudentById={getStudentById}
        VanDriverScan={VanDriverScan}
        addteacherAttendance={addteacherAttendance}
        />
        </div>
      </Modal>
    </>
  );
};
export default QRScanner;
