import React from "react";
import Footerlogo from "../../assets/logo.png";
import BG from "../../assets/banner.jpg";

function Footer() {
  return (
    <div
      className="flex  items-stretch h-[13vh] w-full bg-cover bg-no-repeat text-white"
      style={{ backgroundImage: `url(${BG})` }}
    >
      <div className="w-[330px] flex items-center gap-2 px-6 justify-center h-full bg-white text-black">
        <span> Powered By</span>
        <img src={Footerlogo} alt="..." className="w-[100px]" />
      </div>
      <div className="w-full h-full overflow-hidden flex items-center justify-center text-center">
        <h2 className="animate-slide whitespace-nowrap w-full">
          We learned about honesty and integrity - that the truth matters...
          that you don't take shortcuts or play by your own set of rules... and
          success doesn't count unless you earn it fair and square.
        </h2>
      </div>
    </div>
  );
}

export default Footer;
