import { createContext, useEffect, useState } from "react";
import PostErorr from "../../utils/errorlog";
export const ArrivalContext = createContext();
export const ArrivalProvider = ({ children }) => {
  const [arrivals, setArrivals] = useState([]);

  const fetchData = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      const response = await fetch(
        "https://apischool.lineup.pk/arival/getAllArrivals",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setArrivals(result);
    } catch (error) {
      PostErorr(error);

      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Fetch data every 30 seconds (adjust as needed)
    const intervalId = setInterval(fetchData, 5000);

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <ArrivalContext.Provider value={{ arrivals }}>
      {children}
    </ArrivalContext.Provider>
  );
};
