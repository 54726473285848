import React, { createContext, useState } from 'react';

// Create a new context
export const ScannerQRContext = createContext();

// Create a context provider component
export const ScannerQRProvider = ({ children }) => {
    // State to store the ID
    const [id, setId] = useState('');

    // console.log(id)
    

    return (
        <ScannerQRContext.Provider value={{ id, setId }}>
            {children}
        </ScannerQRContext.Provider>
    );
};