// import React, { useState, useEffect } from "react";
// import moment from "moment";

// const Clock = () => {
//   const [currentTime, setCurrentTime] = useState(moment());

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentTime(moment());
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="w-[20%]" >
//       <div className="font-bold  lg:text-2xl  w-full text-white text-[0.536rem] text-center md:text-xl md:-mr-14 ">{currentTime.format("LTS")}</div>
//       <div className="font-bold  lg:text-2xl  w-full text-white text-[0.536rem] text-center md:text-xl md:-mr-14">{currentTime.format("LL")} </div>


//     </div>
//   );
// };

// export default Clock;

import React, { useState, useEffect } from "react";
import "./css/Clock.css";
import moment from "moment";

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div >
      <div className="cityzone">{currentTime.format("LTS")}</div>
      {/* <div className="cityzone">{currentTime.format("LL")} </div> */}


    </div>
  );
};

export default Clock;
