import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import BG from "../assets/loginImg.jpg";
import { CiTrash } from "react-icons/ci";
import { GrAnnounce } from "react-icons/gr";
import { DatePicker, Space, message } from "antd";
import PostErorr from "../utils/errorlog";
import Footer from "../components/common/Footer";
const { RangePicker } = DatePicker;

const BASE_URL = "https://apischool.lineup.pk/announcements/";
const authToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjYyMGZhMmE5NjQwNzE5NGJmN2IwN2U3In0sImlhdCI6MTcxMzUyMDQ0Nn0.TaYBF_icMBvi9m5IA5WbbjNogtDniixAKyqgKHHSsGM";

const AnnouncementManagement = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState({
    message: "",
    author: "",
    dateFrom: "",
    dateTo: "",
    holiday: false,
  });
  const handleDateChange = (dates, dateStrings) => {
    if (dateStrings[0] === dateStrings[1]) {
      setNewAnnouncement({
        ...newAnnouncement,
        dateFrom: dateStrings[0],
      });
      return;
    }
    setNewAnnouncement({
      ...newAnnouncement,
      dateFrom: dateStrings[0],
      dateTo: dateStrings[1],
    });
  };
  const fetchAnnouncements = async () => {
    try {
      const response = await axios.get(BASE_URL + "getannouncements/", {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });
      setAnnouncements(response.data);
    } catch (error) {
      PostErorr(error);
    }
  };

  const handleCreateAnnouncement = async () => {
    if (
      !newAnnouncement.message ||
      !newAnnouncement.author ||
      !newAnnouncement.dateFrom === "" ||
      !newAnnouncement.dateTo === ""
    ) {
      message.error("Please fill all the fields!");
      return;
    }
    try {
      const response = await axios.post(
        BASE_URL + "createannouncements/",
        newAnnouncement,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      setAnnouncements((prev) => [response.data, ...prev]);
      setNewAnnouncement({
        message: "",
        author: "",
        dateFrom: "",
        dateTo: "",
        holiday: false,
      });
      message.success("Announcement created successfully!");
    } catch (error) {
      PostErorr(error);

      message.error("Error creating announcement!");
      console.error("Error creating announcement:", error);
    }
  };

  const handleDeleteAnnouncement = async (announcementId) => {
    try {
      await axios.delete(BASE_URL + `deleteannouncements/${announcementId}`, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": authToken,
        },
      });

      setAnnouncements((prev) => prev.filter((a) => a._id !== announcementId));
    } catch (error) {
      PostErorr(error);

      console.error("Error deleting announcement:", error);
      PostErorr(error);
    }
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return (
    <>
      <div
        className="flex flex-col gap-3 py-4 min-h-[86vh]"
        style={{
          backgroundImage: `url(${BG})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex flex-col space-y-3">
          <h2 className="text-xl uppercase tracking-wider font-semibold text-center">
            Announcement Management
          </h2>
          <div className="flex flex-col items-center gap-3">
            <input
              type="text"
              className="w-1/3 rounded px-2 py-2"
              placeholder="Author"
              value={newAnnouncement.author}
              onChange={(e) =>
                setNewAnnouncement({
                  ...newAnnouncement,
                  author: e.target.value,
                })
              }
              required
            />
            <RangePicker onChange={handleDateChange} />
            <div className="flex items-center gap-2 justify-center">
              <input
                type="checkbox"
                name="holiday"
                id="holiday"
                checked={newAnnouncement.holiday}
                onChange={(e) =>
                  setNewAnnouncement({
                    ...newAnnouncement,
                    holiday: e.target.checked,
                  })
                }
              />
              <span className="text-lg tracking-wider font-semibold">
                Holiday?
              </span>
            </div>
            <textarea
              className="w-2/3 rounded px-2 py-1"
              placeholder="Message..."
              rows={5}
              value={newAnnouncement.message}
              onChange={(e) =>
                setNewAnnouncement({
                  ...newAnnouncement,
                  message: e.target.value,
                })
              }
              required
            ></textarea>

            <button
              className="bg-gray-500 px-3 py-2 rounded text-white transition-all duration-300 hover:bg-opacity-50"
              onClick={handleCreateAnnouncement}
            >
              Add Announcement
            </button>
          </div>
        </div>
        <div className="flex flex-col space-y-3 w-3/4 mx-auto">
          <h2 className="text-xl uppercase tracking-wider font-semibold text-center">
            Existing Announcements
          </h2>
          <ul className="px-4">
            {announcements.map((announcement) => (
              <li
                key={announcement._id}
                className="flex justify-between gap-3 items-center bg-gray-200 p-3 rounded my-1"
              >
                <div className="flex flex-col">
                  <div className="flex items-start gap-2">
                    <span>
                      {<GrAnnounce style={{ marginTop: "2px" }} size={20} />}
                    </span>
                    <span className="text-xl font-bold">
                      {announcement.message}
                    </span>
                  </div>
                  <span className="font-semibold">{`Author - ${announcement.author}`}</span>
                  <span className="">
                    {announcement.dateFrom && !announcement.dateTo ? (
                      `${moment(announcement.dateFrom).format("DD-MMM-YYYY")}`
                    ) : (
                      <>
                        <span className="font-semibold">From</span>{" "}
                        {moment(announcement.dateFrom).format("DD-MMM-YYYY")}{" "}
                        <span className="font-semibold">to</span>{" "}
                        {moment(announcement.dateTo).format("DD-MMM-YYYY")}
                      </>
                    )}
                  </span>

                  <span className="font-semibold">
                    {" "}
                    {announcement.holiday && "(Holiday)"}
                  </span>
                  <span>
                    Announcement Created at: (
                    {moment(announcement.createdAt).format("DD-MMM-YYYY")})
                  </span>
                </div>
                <button
                  className="bg-red-700 p-1 hover:bg-opacity-50 transition-all duration-300 rounded text-white"
                  onClick={() => handleDeleteAnnouncement(announcement._id)}
                >
                  <CiTrash size={25} />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AnnouncementManagement;
