import React, { useState, useEffect, useRef, useMemo } from "react";
import { FaDownload } from "react-icons/fa";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import BusPdf from "./busPdf"; // Assuming you have this component for generating PDF content
import { MdDownloadForOffline } from "react-icons/md";
import BusReportPdf from "./busReportpdf";
import ReportTitle from "../../components/ReportTitle";
import { IoMdCloudDownload } from "react-icons/io";
import schoolLogo from "../../assets/schoollogo.png";
import defaultProfile from "../../assets/defaultprofile.jpeg";
import footerLogo from "../../assets/logo.png";
import "../CSS/busPDF.css";
import PostErorr from "../../utils/errorlog";
import Footer from "../../components/common/Footer";
function BusReports() {
  const [buses, setBuses] = useState([]);
  const [date, setDate] = useState(new Date().toLocaleDateString("en-GB"));
  // const [fdate, setFDate] = useState(new Date().toLocaleDateString("en-US"));
  const [fdate, setFDate] = useState(
    new Date().toISOString().split("T")[0] + "T00:00:00.000Z"
  );

  const user = JSON.parse(localStorage.getItem("userdetails"));

  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    setDate(selectedDate.toLocaleDateString("en-GB"));
    setFDate(selectedDate.toISOString().split("T")[0] + "T00:00:00.000Z");
  };
  const filterBuses = buses.filter((item) => {
    const busDate =
      new Date(item.createdAt).toISOString().split("T")[0] + "T00:00:00.000Z";
    return busDate <= fdate;
  });
  console.log(filterBuses);

  // Convert Time in 12 Hours Format
  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${adjustedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  const reportRef = useRef();
  const footerRef = useRef();
  const headerRef = useRef();
  const headerRef2 = useRef();

  useEffect(() => {
    fetchBuses();
  }, []);

  const token = localStorage.getItem("authToken");

  const fetchBuses = async () => {
    try {
      const response = await fetch("https://apischool.lineup.pk/bus/getbuses", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${token}`,
        },
      });
      const data = await response.json();
      setBuses(data);
    } catch (error) {
      console.error("Error fetching buses:", error.message);
      PostErorr(error);
    }
  };

  // Generate PDF
  const handleDownloadPdf = async () => {
    const input = reportRef.current;
    const footervalues = footerRef.current;
    const headerValues = headerRef.current;
    const header2Values = headerRef2.current;

    // Capture the header and footer as images
    const headerCanvas = await html2canvas(headerValues, { scale: 2 });
    const headerData = headerCanvas.toDataURL("image/png");
    const header2Canvas = await html2canvas(header2Values, { scale: 2 });
    const header2Data = header2Canvas.toDataURL("image/png");
    const footerCanvas = await html2canvas(footervalues, { scale: 2 });
    const footerData = footerCanvas.toDataURL("image/png");

    // Create a new PDF document
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const headerHeight = 35; // Adjust based on your header height
    const footerHeight = 20; // Adjust based on your footer height
    const contentHeight = pageHeight - headerHeight - footerHeight;

    // Get all rows from the table
    const rows = Array.from(input.querySelectorAll("tr"));
    const totalRows = rows.length;
    const rowsPerPage = 21;
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    for (let i = 0; i < totalPages; i++) {
      const startRow = i * rowsPerPage;
      const endRow = Math.min(startRow + rowsPerPage, totalRows);
      const tableChunk = document.createElement("table");
      tableChunk.style.width = input.offsetWidth + "px"; // Ensure the table width matches the original table

      rows.slice(startRow, endRow).forEach((row) => {
        const rowClone = row.cloneNode(true);
        tableChunk.appendChild(rowClone);
      });

      document.body.appendChild(tableChunk);
      const chunkCanvas = await html2canvas(tableChunk, { scale: 2 });
      document.body.removeChild(tableChunk);

      const imgData = chunkCanvas.toDataURL("image/png");

      // Add header
      if (i === 0) {
        pdf.addImage(headerData, "PNG", 0, 0, imgWidth, headerHeight);
      } else {
        pdf.addImage(header2Data, "PNG", 0, 0, imgWidth, headerHeight);
      }

      // Add main content
      const imgHeight = (chunkCanvas.height * imgWidth) / chunkCanvas.width;
      console.log(chunkCanvas.height);
      const contentY = headerHeight + (contentHeight - imgHeight) / 2; // Center the content vertically

      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      // Add footer
      pdf.setFillColor(255, 255, 255);
      pdf.rect(0, pageHeight - footerHeight, imgWidth, footerHeight, "F");
      pdf.addImage(
        footerData,
        "PNG",
        0,
        pageHeight - footerHeight,
        imgWidth,
        footerHeight
      );
      pdf.setFontSize(8);
      const pageNumText = `Page ${i + 1} of ${totalPages}`;
      const pageNumTextWidth = pdf.getTextWidth(pageNumText);
      pdf.text(pageNumText, imgWidth - pageNumTextWidth - 4, pageHeight - 5);

      if (i < totalPages - 1) {
        pdf.addPage();
      }
    }
    pdf.save("bus_report.pdf");
  };
  const list = [];
  return (
    <>
      <div className="container min-h-screen">
        <div className="min-h-screen">
          <div className="flex justify-between items-center">
            <div className="flex items-center py-2">
              <img src={schoolLogo} alt="" width="100px" />
              <div className="flex flex-col">
                <h2 className="font-bold text-2xl">The Avicenna School</h2>
                <p>XYZ Road Karachi Pakistan</p>
                <p>
                  Phone:<span>021-333456789</span>
                </p>
              </div>
            </div>
            <button className="w-16 h-12" onClick={handleDownloadPdf}>
              <IoMdCloudDownload className="w-full h-full" />
            </button>
          </div>
          <hr />
          <div className="flex justify-between items-center mt-3">
            <div></div>
            <h2 className="font-bold text-2xl text-center">Bus List</h2>
            <div className="flex justify-end items-center">
              <span className="mr-3 font-semibold">
                As On:&nbsp;&nbsp;{date}
              </span>
              <input
                type="date"
                id="todayDate"
                onChange={handleDateChange}
                className="date-input w-[20px] h-[24px] outline-none"
              />
            </div>
          </div>
          <table className="table-auto mt-2 w-full border-collapse text-[10px] sm:text-[16px] md:text-[18px]">
            <thead>
              <tr className="bg-black text-white text-center sm:text-[-10px]">
                <th className="w-12 py-2">S.no</th>
                <th className="w-12 py-2 ">Van no</th>
                <th className="w-24 py-2">Driver Name</th>
                <th className="w-24 py-2 ">Van Reg</th>
                <th className="w-24 py-2 ">Departure Time</th>
              </tr>
            </thead>
            <tbody>
              {filterBuses.map((bus, index) => (
                <tr
                  key={bus.vanNo}
                  className={`cursor-pointer text-center ${
                    index % 2 === 0 ? "bg-gray-300" : "bg-white"
                  } text-center`}
                >
                  <td className="font-semibold">{index + 1}</td>
                  <td className="text-center">{bus.vanNo}</td>
                  <td className="text-center">{bus.driverName}</td>
                  <td className="text-center">{bus.vanReg}</td>
                  <td className="text-center">
                    {convertTo12HourFormat(bus.departureTime)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-full flex justify-between items-start p-3 border-t">
          <div>
            <div className="flex items-center gap-2">
              <h3>Printed On:</h3>
              <p>
                <b>{new Date().toLocaleDateString("en-GB")}</b>
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <h3>printed by:</h3>
              <p className="font-bold">{user.name}</p>
            </div>
          </div>
        </div>
        <span
          style={{
            position: "fixed",
            left: "-99999px",
            // position: 'absolute',
            // top: '2200px'
          }}
        >
          <ReportTitle
            headerRef={headerRef}
            headerRef2={headerRef2}
            date={date}
            title="Bus Report"
          />
          <BusReportPdf details={filterBuses} reportRef={reportRef} />
          <PDFFooter footerRef={footerRef} />
        </span>
      </div>
      <Footer />
    </>
  );
}

export default BusReports;

const PDFFooter = ({ page, totalPages, footerRef }) => {
  const user = JSON.parse(localStorage.getItem("userdetails"));
  return (
    <div
      ref={footerRef}
      className="w-[95vw] mx-auto fixed bottom-10 left-[-9999px] right-0"
    >
      <div className="w-full flex justify-between items-start p-3 px-4 border-t">
        <div>
          <div className="flex items-center gap-2">
            <h3>Printed On:</h3>
            <p>
              <b>{`${new Date().toLocaleDateString(
                "en-GB"
              )}-${new Date().toLocaleTimeString()}`}</b>
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <h3>printed by:</h3>
            <p className="font-bold">{user.name}</p>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};
