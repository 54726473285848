import React, { useState, useEffect } from "react";
import "./css/Clock.css";
import moment from "moment";

const Clock = () => {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div >
      <div className="cityzone">{currentTime.format("LTS")}</div>
      {/* <div className="cityzone">{currentTime.format("LL")} </div> */}


    </div>
  );
};

export default Clock;
