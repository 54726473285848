import React, { useEffect, useState } from "react";
import Footer from "./components/common/Footer";
import Router from "./router/router";
import "./App.css";


function App() {
  const location = window.location.pathname;
  return (
    <div>
      <Router />
      {/* {(location === "/" || location === "/arrivalScreen" || location === "/arrivalScreens" || location === "/announcementScreen") ? null : <Footer />}  */}
      
    </div>
  );
}

export default App;
