import React, { useState } from "react";
import { Modal, message } from "antd";
import PostErorr from "../../utils/errorlog";

const ChangePasswordModal = () => {
  const [token, setToken] = useState(localStorage.getItem("authToken") || "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };
  console.log(newPassword);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword) {
      message.error("Password and confirm password must not be empty");
      return;
    }

    if (newPassword.length < 5) {
      message.error("Password should be at least 5 characters long");
      return;
    }

    if (newPassword !== confirmPassword) {
      message.error("Passwords do not match");
      return;
    }

    try {
      const response = await fetch(
        "https://apischool.lineup.pk/auth/changepassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, newPassword }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        message.success(data.message);
        setIsModalOpen(false);
        setNewPassword("");
        setConfirmPassword("");
        console.log("Password updated successfully");
      } else {
        console.log("Error:", data.error);
        message.error(data.error);
      }
    } catch (error) {
      PostErorr(error);

      console.error("Error:", error);
      message.error("Something went wrong. Please try again later.");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <div>
      <button onClick={showModal} className="text-white focus:outline-none">
        Change Password
      </button>
      <Modal footer={null} open={isModalOpen} onCancel={handleCancel}>
        <div className="flex flex-col gap-2 w-2/3 mx-auto justify-evenly min-h-64">
          <div>
            <h1 className="text-center text-2xl">Change Password</h1>
          </div>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-2 w-full mx-auto justify-center"
          >
            <input
              type="password"
              placeholder="Input password"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-3.5 text-lg border border-gray-200 rounded bg-gray-100 custom-number-input"
            />
            <input
              type="password"
              placeholder="Confirm password"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full p-3.5 text-lg border border-gray-200 rounded bg-gray-100 custom-number-input"
            />
            <div className="flex justify-end">
              <button
                type="button"
                className="bg-gray-500 px-2 py-1 rounded ml-2 text-white tracking-widest"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-gray-500 px-2 py-1 rounded ml-2 text-white tracking-widest"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;
