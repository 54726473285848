import React, { useState, useEffect, useRef } from "react";
import image from "../assets/loginImg.jpg";
import "../pages/CSS/bus.css";
import { FaDownload } from "react-icons/fa";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import BusPdf from "./reports/busPdf";
import { message } from "antd";
import PostErorr from "../utils/errorlog";
import Footer from "../components/common/Footer";

function BusManagement() {
  const [selectedBus, setSelectedBus] = useState(null);
  const [vanNo, setVanNo] = useState("");
  const [vanReg, setVanReg] = useState("");
  const [driverName, setDriverName] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [buses, setBuses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const contentRef = useRef();
  const token = localStorage.getItem("authToken");
  // console.log(departureTime);

  const filteredBuses = buses.filter((bus) =>
    Object.values(bus).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  useEffect(() => {
    fetchBuses();
  }, []);
  const fetchBuses = async () => {
    const authtoken = localStorage.getItem("authToken");

    if (!authtoken) {
      console.error("Auth token not found.");
      return;
    }

    try {
      const response = await fetch("https://apischool.lineup.pk/bus/getbuses", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setBuses(data);
        // console.log(data);
      } else {
        console.error("Failed to fetch buses:", response.statusText);
      }
    } catch (error) {
      PostErorr(error);

      console.error("Error fetching buses:", error.message);
    }
  };
  const handleBusClick = (bus) => {
    setSelectedBus(bus); // Set the selected bus
    setVanNo(bus.vanNo); // Populate vanNo input field with bus's vanNo
    setVanReg(bus.vanReg); // Populate vanReg input field with bus's vanReg
    setDriverName(bus.driverName); // Populate driverName input field with bus's driverName
    setDepartureTime(bus.departureTime); // Populate departureTime input field with bus's departureTime
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "vanNo":
        setVanNo(value);
        break;
      case "vanReg":
        setVanReg(value);
        break;
      case "driverName":
        setDriverName(value);
        break;
      case "departureTime":
        setDepartureTime(value);
        break;
      case "search":
        setSearchQuery(value);
        break;
      default:
        break;
    }
  };
  const handleUpdateBus = async () => {
    if (!selectedBus) return;
    const updatedBus = {
      vanNo: vanNo,
      vanReg: vanReg,
      driverName: driverName,
      departureTime: departureTime,
    };
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/bus/editbus/${selectedBus._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
          body: JSON.stringify(updatedBus),
        }
      );
      if (response.ok) {
        fetchBuses();
        setSelectedBus(null);
        setVanNo("");
        setVanReg("");
        setDriverName("");
        setDepartureTime("");
        message.success("Bus updated successfully");
      } else {
        message.error("Failed to update bus");
        console.error("Failed to update bus:", response.statusText);
      }
    } catch (error) {
      PostErorr(error);

      console.error("Error updating bus:", error.message);
    }
  };
  const handleDeleteBus = async (_id) => {
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/bus/deletebus/${selectedBus._id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
        }
      );
      if (response.ok) {
        fetchBuses();
        setSelectedBus(null);
        setVanNo("");
        setVanReg("");
        setDriverName("");
        setDepartureTime("");
        message.success("Bus deleted successfully");
      }
    } catch (error) {
      PostErorr(error);

      message.error("Failed to delete bus");
      console.error("Error deleting bus:", error.message);
    }
  };

  const handleAddBus = async () => {
    const newBus = {
      vanNo: vanNo,
      vanReg: vanReg,
      driverName: driverName,
      departureTime: departureTime,
    };

    try {
      const response = await fetch("https://apischool.lineup.pk/bus/addbus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${token}`,
        },
        body: JSON.stringify(newBus),
      });
      if (response.ok) {
        fetchBuses();
        setVanNo("");
        setVanReg("");
        setDriverName("");
        setDepartureTime("");
        message.success("Bus added successfully");
      } else {
        message.error("Failed to add bus");
        console.error("Failed to add bus:", response.statusText);
      }
    } catch (error) {
      PostErorr(error);

      console.error("Error adding bus:", error.message);
    }
  };

  const isMobile = window.matchMedia("(max-width: 576px)").matches;

  const generatePdf = async () => {
    if (selectedBus !== null) {
      const content = contentRef.current;
      const canvas = await html2canvas(content, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      const pdfBlob = pdf.output("blob");
      const pdfFile = new Blob([pdfBlob], { type: "application/pdf" });
      pdf.save(selectedBus.driverName?.toUpperCase() + "- ID.pdf");
    }
  };

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${adjustedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  useEffect(() => {
    generatePdf();
  }, [isClicked]);
  return (
    <>
      <div
        className="flex flex-col items-center justify-evenly"
        style={mainDiv}
      >
        <div
          className="d-inline-block w-100 d-flex align-items-center justify-content-center bus-input-div"
          style={mystyle}
        >
          <div className="input-div input-group mb-3 w-75 d-flex justify-content-center ">
            {isMobile ? (
              <div className="w-full d-flex flex-column justify-content-center align-items-center mt-2">
                <div>
                  <label className="bg-black text-white text-center w-full">
                    Van No:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="vanNo"
                    value={vanNo}
                    onChange={handleInputChange}
                  />
                  <label className="bg-black text-white text-center w-full mt-2">
                    Van Reg:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="vanReg"
                    value={vanReg}
                    onChange={handleInputChange}
                  />
                </div>
                <div>
                  <label className="bg-black text-white text-center w-full mt-2">
                    Driver Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="driverName"
                    value={driverName}
                    onChange={handleInputChange}
                  />
                  <label className="bg-black text-white text-center w-full mt-2">
                    Departure Time:
                  </label>
                  <input
                    type="time"
                    className="form-control"
                    name="departureTime"
                    value={departureTime}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  {selectedBus && (
                    <>
                      <button
                        className="btn btn-dark m-2"
                        onClick={handleUpdateBus}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-dark m-2"
                        onClick={() => handleDeleteBus(selectedBus.vanNo)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                  <button className="btn btn-dark m-2" onClick={handleAddBus}>
                    Add
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="flex w-[100%] sm:w-[90%] ">
                  <div className="basis-1/4">
                    <label className="bg-black text-white text-center w-full">
                      Van No
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="vanNo"
                      value={vanNo}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="basis-1/4">
                    <label className="bg-black text-white text-center w-full">
                      Van Reg
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="vanReg"
                      value={vanReg}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="basis-1/4">
                    <label className="bg-black text-white text-center w-full">
                      Driver Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="driverName"
                      value={driverName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="basis-1/4">
                    <label className="bg-black text-white text-center w-full">
                      Departure Time
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      name="departureTime"
                      value={departureTime}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    {selectedBus && (
                      <>
                        <button
                          className="btn btn-dark mt-8 ml-2.5"
                          onClick={handleUpdateBus}
                        >
                          Update
                        </button>
                        <button
                          className="btn btn-dark mt-8 ml-2.5"
                          onClick={() => handleDeleteBus(selectedBus.vanNo)}
                        >
                          Delete
                        </button>
                      </>
                    )}
                    <button
                      className="btn btn-dark mt-8 ml-2.5"
                      onClick={handleAddBus}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className="mt-[-50px]"
          style={{ width: "75%", display: "flex", justifyContent: "end" }}
        >
          <input
            type="text"
            className="form-control w-1/4 border-2 border-black"
            placeholder="Search..."
            value={searchQuery}
            name="search"
            onChange={handleInputChange}
          />
        </div>
        <div
          className="w-75 overflow-y-scroll border border-secondary"
          style={tableDiv}
        >
          <table
            className="table-main w-100 h-auto border border-secondary"
            style={table}
          >
            <thead style={tableHead}>
              <tr style={border}>
                <th style={border}>VAN NO</th>
                <th style={border}>VAN REG</th>
                <th style={border}>DRIVER NAME</th>
                <th style={border}>DEPARTURE TIME</th>
                <th style={border}>Generate ID Card</th>
              </tr>
            </thead>
            <tbody style={tableBody}>
              {filteredBuses
                .sort((a, b) => a.vanNo - b.vanNo)
                .map((bus) => (
                  <tr
                    key={bus.vanNo}
                    onClick={() => handleBusClick(bus)}
                    style={{ cursor: "pointer" }}
                  >
                    <td style={border}>{bus.vanNo}</td>
                    <td style={border}>{bus.vanReg}</td>
                    <td style={border}>{bus.driverName}</td>
                    <td style={border}>
                      {convertTo12HourFormat(bus.departureTime)}
                    </td>
                    <td
                      className="capitalize d-flex text-blue-800 w-100 justify-center gap-2 cursor-pointer hover:text-[#000]"
                      style={border}
                      onClick={() => setIsClicked(!isClicked)} // Pass the student object directly
                    >
                      <FaDownload className="h-8" />
                    </td>
                    <span style={{ position: "fixed", left: "-99999px" }}>
                      <BusPdf details={selectedBus} contentRef={contentRef} />
                    </span>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BusManagement;

const mystyle = {
  height: "15vh",
};

const tableDiv = {
  height: "60%",
  textAlign: "center",
  backgroundColor: "#E7E9E9",
};
const tableHead = {
  backgroundColor: "#1b1e25",
  color: "white",
};
const tableBody = {
  backgroundColor: "#E7E9E9",
};
const border = {
  border: "1px solid black",
  borderCollapse: "collapse",
};
const mainDiv = {
  height: "100vh",
  backgroundImage: `url(${image})`,
  backgroundPosition: "center",
};
const table = {
  tableLayout: "fixed",
};
