import React, { createContext, useState, useEffect } from 'react';

// Create the fetch context
const FetchContext = createContext();

// Create a provider component for the fetch context
const FetchProvider = ({ children }) => {
    const [state , setState] = useState(false);
    

    return (
        <FetchContext.Provider value={{ setState, state }}>
            {children}
        </FetchContext.Provider>
    );
};

export { FetchContext, FetchProvider };