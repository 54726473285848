import React from 'react'
import './css/Mainleft.css'

const MainLeft = () => {
    return (
        <>
           <div className='main-left'></div>

        </>
    )
}

export default MainLeft