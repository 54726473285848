// import React, { useContext, useEffect, useState } from "react";
// import Slider from "react-slick";
// import CardComponent from "./cardComponent";
// import { ArrivalContext } from "../../context/arrivalScreen/arrivalcontext";

// const VanContainer = () => {
//   const { arrivals } = useContext(ArrivalContext);
//   const [bus, setBus] = useState([]);

//   useEffect(() => {
//     if (Array.isArray(arrivals)) {
//       const filteredBus = arrivals.filter((v) => v.arrivalType === "Bus");
//       setBus(filteredBus);
//     }
//   }, [arrivals]);
//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 1000,
//     slidesToShow: 6,
//     slidesToScroll: 1,
//     swipeToSlide: true,
//     pauseOnHover: false,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     vertical: true, // Default horizontal
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 4,
//           vertical: false,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 4,
//           vertical: false,
//         },
//       },
//       {
//         breakpoint: 500,
//         settings: {
//           slidesToShow: 3,
//           vertical: false,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="text-center h-[17vh] lg:h-full lg:flex lg:flex-col ">
//       <h2 className="font-bold text-2xl md:text-4xl text-white lg:text-xl lg:h-[5%] text-center">
//         Van Arrival
//       </h2>
//       <Slider
//         {...settings}
//         className="mt-3 md:mt-5 lg:mt-0 lg:h-[85%] lg:flex lg:justify-center  "
//       >
//         {bus.map((van, index) => {
//           const arrivalDateTime = new Date(van.arrivalTime);
//           const hour = arrivalDateTime.getHours();
//           const minute = arrivalDateTime.getMinutes();
//           const formattedTime = `${hour}:${
//             minute < 10 ? "0" + minute : minute
//           }`;

//           return (
//             <div
//               key={index}
//               className="lg:mt-2 lg:flex lg:flex-col lg:items-center lg:justify-center"
//             >
//               <CardComponent
//                 vanNo={`Van No ${van.bus?.vanNo}`}
//                 description={`Depart at ${formattedTime}`}
//               />
//             </div>
//           );
//         })}
//       </Slider>
//     </div>
//   );
// };

// export default VanContainer;

import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import CardComponent from "./cardComponent";
import { ArrivalContext } from "../../context/arrivalScreen/arrivalcontext";

const VanContainer = () => {
  const { arrivals } = useContext(ArrivalContext);
  const [bus, setBus] = useState([]);

  useEffect(() => {
    if (Array.isArray(arrivals)) {
      const filteredBus = arrivals.filter((v) => v.arrivalType === "Bus");
      setBus(filteredBus);
    }
  }, [arrivals]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: Math.min(6, bus.length), // Adjust slidesToShow dynamically
    slidesToScroll: 1,
    swipeToSlide: true,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 3000,
    vertical: true, // Default horizontal
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(4, bus.length), // Adjust slidesToShow dynamically
          vertical: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(4, bus.length), // Adjust slidesToShow dynamically
          vertical: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: Math.min(3, bus.length), // Adjust slidesToShow dynamically
          vertical: false,
        },
      },
    ],
  };

  return (
    <div className="text-center h-[17vh] lg:h-full lg:flex lg:flex-col ">
      <h2 className="font-bold text-2xl md:text-4xl text-white lg:text-xl lg:h-[5%] text-center">
        Van Arrival
      </h2>
      <Slider
        {...settings}
        className="mt-3 md:mt-5 lg:mt-0 lg:h-[85%] lg:flex lg:justify-center"
      >
        {bus.map((van, index) => {
          const arrivalDateTime = new Date();
          const dada = van.bus?.departureTime?.split(":")
          arrivalDateTime.setHours(dada[0], dada[1], 0, 0);
          const options = {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          };
          const formattedTime = new Intl.DateTimeFormat(
            "en-US",
            options
          ).format(arrivalDateTime);

          return (
            <div
              key={index}
              className="lg:mt-2 lg:flex lg:flex-col lg:items-center lg:justify-center"
            >
              <CardComponent
                vanNo={`Van No ${van.bus?.vanNo}`}
                description={`Depart at ${formattedTime}`}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default VanContainer;
