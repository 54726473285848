import React, { useState } from 'react';
import schoolLogo from '../assets/schoollogo.png'
import defaultProfile from '../assets/defaultprofile.jpeg'
function ReportTitle({ headerRef, headerRef2, date,title }) {
    return (
        <>
        <div className="container h-[25mm]" ref={headerRef}>
            <div className='flex justify-between items-center'>
                <div className='flex'>
                    <img src={schoolLogo} alt="" width="100px" />
                    <div className='flex flex-col'>
                        <h2 className='font-bold text-2xl'>The Avicenna School</h2>
                        <p className='text-[12px]'>XYZ Road Karachi Pakistan</p>
                        <p className='text-[12px]'>Phone: <span>021-333456789</span></p>
                    </div>
                </div>
                <div className='flex justify-between items-center'>
                    <span className='mr-3 font-semibold text-[12px]'>As On:&nbsp;{date}</span>
                </div>
            </div>
            <div className='mt-[-25px]'>
                <h2 className='font-bold text-center text-2xl'>{title}</h2>
            </div>
        </div>
        <div className="container h-[25mm]" ref={headerRef2}>
            <div className='flex justify-between items-center'>
                <div className='flex'>
                    <img src={schoolLogo} alt="" width="100px" />
                    <div className='flex flex-col'>
                        <h2 className='font-bold text-2xl'>The Avicenna School</h2>
                        <p className='text-[12px]'>XYZ Road Karachi Pakistan</p>
                        <p className='text-[12px]'>Phone: <span>021-333456789</span></p>
                    </div>
                </div>
                <div className='flex justify-between items-center'>
                    <span className='mr-3 font-semibold text-[12px]'>As On:&nbsp;{date}</span>
                </div>
            </div>
        </div>
        </>
    );
}
export default ReportTitle;


