import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import image from "../assets/bglogin.jpg";
import footerLogo from "../assets/logo.png";
import { Select, message } from "antd";
import PostErorr from "../utils/errorlog";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    try {
      const response = await fetch("https://apischool.lineup.pk/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Login successful:", data);
        message.success("Login successful");
        localStorage.setItem("authToken", data.authtoken);
        localStorage.setItem("userdetails", JSON.stringify(data.user));
        //check if the userid in user is 001 so it will navigate to google.com
        setTimeout(() => {
          if (data.user?.userId === "001") {
            navigate("/arrivalScreens");
          } else if (data.user?.userId === "002") {
            navigate("/announcementScreen");
          } else if (data.user?.userType === "teacher") {
            navigate("/attendance");
          } else if (data.user?.userType === "gatekeeper") {
            navigate("/arrivals");
          } else {
            navigate("/dashboard");
          }
        }, 2500);
      } else {
        message.error(data.error);
        console.log("Login failed:", data.error);
      }
    } catch (error) {
      console.error("Error:", error);
      PostErorr(error);
    }
  };

  return (
    <div
      className="relative flex flex-col justify-center  space-y-10 items-center h-[100vh] overflow-hidden bg-cover bg-center"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="absolute inset-0 w-full h-full bg-black opacity-40"></div>
      <h1 className="text-5xl z-10 text-white tracking-wider font-bold shadow-text">
        Student Lineup !
      </h1>
      <div className="relative z-10  w-full max-w-[30rem] p-6 rounded-lg bg-white">
        <h2 className="pb-4 font-semibold tracking-wider uppercase text-3xl text-center">
          Login
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <input
              type="number" // Change the type from "email" to "text"
              placeholder={errors.userId ? errors.userId.message : "User ID"} // Update the placeholder
              className={`${
                errors.userId &&
                "placeholder:text-red-700 placeholder:text-base"
              } w-full p-3.5 text-lg border border-gray-200 rounded bg-gray-100 custom-number-input`}
              {...register("userId", { required: "User ID is required" })} // Update the field name to "userId"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              placeholder={
                errors.password ? errors.password.message : "Password"
              }
              className={`${
                errors.password &&
                "placeholder:text-red-700 placeholder:text-base"
              } w-full p-3.5 text-lg border border-gray-200 rounded bg-gray-100`}
              {...register("password", { required: "Password is required" })}
            />
          </div>
          <button
            type="submit"
            className="w-full mt-2 p-3 text-lg bg-gray-800 text-white rounded hover:bg-gray-600 transition-all duration-300"
          >
            Login
          </button>
          <div className="flex items-center mt-4 justify-center h-full">
            <img src={footerLogo} alt="Logo" className="h-16" />
          </div>
        </form>
      </div>
      <style jsx>{`
        .shadow-text {
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adjust values as needed */
        }
      `}</style>
    </div>
  );
};

export default Login;
