import React, { useContext, useEffect, useState } from "react";
import "./css/Point.css";
import "./css/classes.css";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import Class1 from "./classes/Class1";
import Class2 from "./classes/Class2";
import Class3 from "./classes/Class3";
import Class4 from "./classes/Class4";
import Class5 from "./classes/Class5";
import Class6 from "./classes/Class6";
import Class7 from "./classes/Class7";
import Class8 from "./classes/Class8";
import Class9 from "./classes/Class9";
import Class10 from "./classes/Class10";
import { ArrivalContext } from "../../context/arrivalScreen/arrivalcontext";
import PostErorr from "../../utils/errorlog";
const Point = () => {
  const [students, setStudents] = useState([]);
  const { arrivals } = useContext(ArrivalContext);
  const [bus, setBus] = useState([]);

  const hasClassData = (classGrade) =>
    arrivals.some(
      (arrival) =>
        arrival.arrivalType === "Student" &&
        arrival.student?.classGrade === classGrade
    );
  let authToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjYyMGZhMmE5NjQwNzE5NGJmN2IwN2U3In0sImlhdCI6MTcxMzUyMDQ0Nn0.TaYBF_icMBvi9m5IA5WbbjNogtDniixAKyqgKHHSsGM";

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(
          "https://apischool.lineup.pk/reminder/allReminders",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token": authToken,
            },
          }
        );

        const data = await response.json();

        if (Array.isArray(data)) {
          setStudents(data);
          // console.log(data)
        } else {
          console.error("Expected an array but got:", data);
          setStudents([]);
        }
      } catch (error) {
        PostErorr(error);

        console.error("Error fetching students:", error);
        setStudents([]);
      }
    };

    fetchStudents();

    const intervalId = setInterval(fetchStudents, 3000);

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setBus(
      Array.isArray(arrivals) &&
        arrivals.length &&
        arrivals.filter((v) => v.arrivalType != "Student")
    );
  }, [arrivals]);

  return (
    <>
      <div className="bottom-main">
        <div className="bus-point">
          <div className="swiper-div-left">
            <div className="bus-head">
              <h1>Van Arrivals</h1>
            </div>
            {arrivals.length && (
              <div>
                <Swiper
                  spaceBetween={20}
                  slidesPerView={4}
                  direction={"vertical"}
                  modules={[Autoplay]}
                  className="mySwiper"
                  loop={true}
                  autoplay={{ delay: 0, disableOnInteraction: false }} // Adjust delay as needed
                  speed={3000}
                >
                  {arrivals
                    .filter((item) => item.arrivalType === "Bus")
                    .map((arrival) => (
                      <SwiperSlide className="swiper-slide">
                        <div className="right-text1">
                          <h4 className="blink">
                            Van Number {arrival.bus?.vanNo}
                          </h4>
                          <h4 className="blink">
                            Van Departs At&nbsp;
                            {moment(arrival.arrivalTime).format("h:mm A")}{" "}
                          </h4>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            )}
          </div>
          <div className="swiper-div-right">
            <h1 style={{ color: "white" }}>Parents Arrivals</h1>

            {arrivals.length && (
              <Swiper
                spaceBetween={20}
                slidesPerView={1}
                direction={"vertical"}
                modules={[Pagination, Autoplay]}
                className="mySwiper1"
                loop={true}
                speed={500}
                autoplay={{ delay: 10000, disableOnInteraction: false }}
              >
                {(hasClassData("1") || hasClassData("2")) && (
                  <SwiperSlide className="swiper-slide">
                    {hasClassData("1") && <Class1 />}
                    {hasClassData("2") && <Class2 />}
                  </SwiperSlide>
                )}

                {(hasClassData("3") || hasClassData("4")) && (
                  <SwiperSlide className="swiper-slide">
                    {hasClassData("3") && <Class3 />}
                    {hasClassData("4") && <Class4 />}
                  </SwiperSlide>
                )}

                {(hasClassData("5") || hasClassData("6")) && (
                  <SwiperSlide className="swiper-slide">
                    {hasClassData("5") && <Class5 />}
                    {hasClassData("6") && <Class6 />}
                  </SwiperSlide>
                )}

                {(hasClassData("7") || hasClassData("8")) && (
                  <SwiperSlide className="swiper-slide">
                    {hasClassData("7") && <Class7 />}
                    {hasClassData("8") && <Class8 />}
                  </SwiperSlide>
                )}

                {(hasClassData("9") || hasClassData("10")) && (
                  <SwiperSlide className="swiper-slide">
                    {hasClassData("9") && <Class9 />}
                    {hasClassData("10") && <Class10 />}
                  </SwiperSlide>
                )}
              </Swiper>
            )}

            <div className="slider">
              {" "}
              <Swiper
                spaceBetween={18}
                slidesPerView={4}
                direction={"horizontal"}
                initialSlide={0} // Start from the first slide
                rtl={false} // Ensure it is left to right
                modules={[Pagination, Autoplay]}
                className="slidermain"
                loop={true}
                autoplay={{ delay: 0, disableOnInteraction: false }} // Adjust delay as needed
                speed={3000}
              >
                {students.map((arrival, index) => (
                  <SwiperSlide key={index} className="swiper-slide">
                    <div className="right-text1">
                      <h4 className="blink">{arrival.student?.name}</h4>
                      <h4 className="blink">{`${arrival.student?.classGrade} - ${arrival.student?.section}`}</h4>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Point;
