// import React, { useEffect, useState } from 'react'
// import './css/Mainright.css'

// const Mainright = () => {
// const [date , setDate]=useState([])

// useEffect(() => {
//   const fetchData = () => {
//     const requestOptions = {
//       method: "GET",
//       redirect: "follow"
//     };

//     fetch("https://apischool.lineup.pk/announcements/getannouncements", requestOptions)
//       .then((response) => response.json())
//       .then((result) => setDate(result))
//       .catch((error) => console.error('Error fetching announcements:', error));
//   };
//   fetchData(); // Initial fetch

//   const intervalId = setInterval(fetchData, 2000); // Fetch every 5 seconds

//   return () => clearInterval(intervalId); // Cleanup on unmount
// }, []);

// // console.log(date[0].holiday);

//   return (
//     <>
//       <div className='main-right'>
//         <table className='table'>
//           <thead className='thead'>

//             <th>
//               <p className='date'>Date</p>
//               <div className='from-to'>
//                 <td className='from'>From</td>
//                 <td className='to'>To</td>
//               </div>
//             </th>
//             <th>Event</th>

//           </thead>
//           <tbody>
//             {date.map((event, index) => (
//               <tr key={index}>
//                 {event.dateTo=='' ? (
//                   <>
//                     {/* <td >{event.day}</td> */}
//                     <div className='from-to'>
//                       <td colSpan={2} className='from'>{event.dateFrom}</td>

//                     </div>
//                     <td>{event.message}&nbsp;{event.holiday && "(Holiday)"}</td>
//                     {/* <td>{event.message}</td> */}
//                   </>
//                 ) : (
//                   <>
//                     {/* <td >{event.day}</td> */}
//                     <div className='from-to'>
//                       <td className='from'>{event.dateFrom}</td>
//                       <td className='to'>{event.dateTo}</td>
//                     </div>
//                     <td>{event.message}&nbsp;{event.holiday && " (Holiday)"}</td>

//                   </>
//                 )}
//               </tr>
//             ))}
//           </tbody>
//         </table>

//       </div>

//     </>
//   )
// }

// export default Mainright

import React, { useEffect, useState } from "react";
import PostErorr from "../../utils/errorlog";
import moment from "moment";
const Mainright = () => {
  const [date, setDate] = useState([]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  useEffect(() => {
    const fetchData = () => {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        "https://apischool.lineup.pk/announcements/getannouncements",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => setDate(result))
        .catch((error) => {
          console.error("Error fetching announcements:", error);
          PostErorr(error);
        });
    };

    fetchData(); // Initial fetch

    const intervalId = setInterval(fetchData, 2000); // Fetch every 2 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);
  console.log(date);
  return (
    <div className="h-full w-1/2 overflow-hidden flex flex-col">
      <table className="border-collapse w-full">
        <thead className="border border-black bg-gray-200 text-center text-lg">
          <tr>
            <th className="border-r border-black p-2">
              <p className="  border-b border-black">Date</p>
              <div className="flex justify-evenly items-center">
                <span className="flex-1 border-r border-black">From</span>
                <span className="flex-1">To</span>
              </div>
            </th>
            <th className="">Event</th>
          </tr>
        </thead>
        <tbody>
          {date.map((event, index) => (
            <tr
              key={index}
              className="border border-black text-center text-lg hover:bg-gray-100"
            >
              {event.dateTo === "" ? (
                <>
                  <td
                    colSpan={2}
                    className="flex justify-evenly items-center border-r border-black p-2"
                  >
                    <span className="flex-1">
                      {moment(event.dateFrom).format("DD-MMM-YYYY")}
                    </span>
                  </td>
                  <td className="p-2">
                    {event.message}&nbsp;{event.holiday && "(Holiday)"}
                  </td>
                </>
              ) : (
                <>
                  <td className="flex justify-evenly items-center border-r border-black p-2">
                    <span className="flex-1 border-r border-black">
                      {moment(event.dateFrom).format("DD-MMM-YYYY")}
                    </span>
                    <span className="flex-1">
                      {moment(event.dateTo).format("DD-MMM-YYYY")}
                    </span>
                  </td>
                  <td className="capitalize p-2">
                    {event.message}&nbsp;{event.holiday && "(Holiday)"}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Mainright;
