// import React, { useContext, useEffect, useState } from "react";
// import { ArrivalContext } from "../../context/arrivalScreen/arrivalcontext";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Pagination, Autoplay } from "swiper/modules";
// import "swiper/css";

// const renderStudentNames = (arrivals, grade) => {
//   return arrivals
//     .filter(
//       (item) =>
//         item.arrivalType === "Student" && item.student?.classGrade === grade
//     )
//     .map((arrival) => (
//       <td
//         key={arrival.student?.id}
//         className="inline-block border-r border-b border-black w-1/2 text-lg px-2 py-1"
//       >
//         {`${arrival.student?.name} (${arrival.student?.classGrade} - ${arrival.student?.section})`}
//       </td>
//     ));
// };
// // console.log(renderStudentNames())
// const ClassTable = () => {
//   const { arrivals } = useContext(ArrivalContext);
//   const [classes, setClasses] = useState([]);

//   useEffect(() => {
//     if (arrivals.length > 0) {
//       const uniqueClasses = [
//         ...new Set(
//           arrivals
//             .filter((item) => item.arrivalType === "Student" && item.student)
//             .map((item) => item.student.classGrade)
//         ),
//       ];

//       uniqueClasses.sort((a, b) => a - b);

//       setClasses(uniqueClasses);
//     }
//   }, [arrivals]);
// console.log(arrivals)
//   return (
//     <div className="h-[50vh] mt-5 overflow-hidden mx-5 lg:h-full lg:w-full">
//       <div className="overflow-hidden h-full flex justify-center w-full lg:h-full">
//         <Swiper
//           spaceBetween={25}
//           slidesPerView={1}
//           direction="vertical"
//           modules={[Pagination, Autoplay]}
//           loop
//           speed={5000}
//           autoplay={{ delay: 5000, disableOnInteraction: false }}
//           centeredSlides
//           className="h-full flex justify-center w-full items-center lg:h-full"
//         >
//           {classes.reduce((acc, grade, index) => {
//             if (index % 2 === 0) {
//               acc.push(
//                 <SwiperSlide key={`slide-${index}`} className="lg:h-full">
//                   <div className="flex w-full overflow-hidden">
//                     <div className="lg:w-5/12 m-2 bg-white w-full md:w-[calc(50%-1rem)] rounded-br-[4rem] h-[50vh] rounded-tr-[4rem]">
//                       <h1 className="text-center text-3xl font-semibold border-b border-black p-2">
//                         Class {classes[index]}
//                       </h1>
//                       <div className="class-student-name">
//                         <table className="px-2 w-full overflow-hidden">
//                           <tbody>
//                             <tr>{renderStudentNames(arrivals, classes[index])}</tr>
//                           </tbody>
//                         </table>
//                       </div>
//                     </div>
//                     {classes[index + 1] && (
//                       <div className="lg:w-5/12 m-2 bg-white w-full md:w-[calc(50%-1rem)] rounded-br-[4rem] h-[50vh] rounded-tr-[4rem]">

//                         <div className="class-student-name overflow-hidden rounded-br-[4rem] h-[50vh] rounded-tr-[4rem]">
//                           <table className="px-2 w-full">
//                             <tbody>
//                               <tr>{renderStudentNames(arrivals, classes[index + 1])}</tr>
//                             </tbody>
//                           </table>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </SwiperSlide>
//               );
//             }
//             return acc;
//           }, [])}
//         </Swiper>
//       </div>
//     </div>
//   );
// };

// export default ClassTable;

// import React, { useContext, useEffect, useState } from "react";
// import { ArrivalContext } from "../../context/arrivalScreen/arrivalcontext";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Pagination } from "swiper/modules";
// import "swiper/css";

// const renderStudentNames = (students = []) => {
//   return students.map((student) => (
//     <td
//       key={student.id}
//       className="inline-block border-r border-b border-black w-1/2 text-lg px-2 py-1"
//     >
//       {`${student.name} (${student.classGrade} - ${student.section})`}
//     </td>
//   ));
// };

// const ClassTable = ({ setExcessStudents }) => {
//   const { arrivals } = useContext(ArrivalContext);
//   const [classes, setClasses] = useState([]);
//   const [classStudents, setClassStudents] = useState({});

//   useEffect(() => {
//     if (arrivals.length > 0) {
//       const uniqueClasses = [
//         ...new Set(
//           arrivals
//             .filter((item) => item.arrivalType === "Student" && item.student)
//             .map((item) => item.student.classGrade)
//         ),
//       ];

//       uniqueClasses.sort((a, b) => a - b);

//       setClasses(uniqueClasses);
//     }
//   }, [arrivals]);

//   useEffect(() => {
//     if (classes.length > 0) {
//       const excess = [];
//       const newClassStudents = {};

//       classes.forEach((grade) => {
//         const studentsInGrade = arrivals
//           .filter(
//             (item) =>
//               item.arrivalType === "Student" &&
//               item.student?.classGrade === grade
//           )
//           .map((item) => item.student);

//         if (studentsInGrade.length > 12) {
//           excess.push(...studentsInGrade.slice(14));
//           newClassStudents[grade] = studentsInGrade.slice(0, 12);
//         } else {
//           newClassStudents[grade] = studentsInGrade;
//         }
//       });

//       setExcessStudents(excess);
//       setClassStudents(newClassStudents);
//     }
//   }, [arrivals, classes, setExcessStudents]);

//   return (
//     <div className="h-[50vh] mt-5 overflow-hidden mx-5 lg:h-full lg:w-full">
//       <div className="overflow-hidden h-full flex justify-center w-full lg:h-full">
//         <Swiper
//           spaceBetween={25}
//           slidesPerView={1}
//           direction="vertical"
//           modules={[Autoplay, Pagination]}
//           loop
//           speed={5000}
//           autoplay={{ delay: 5000, disableOnInteraction: false }}
//           centeredSlides
//           className="h-full flex justify-center w-full items-center lg:h-full"
//         >
//           {classes.reduce((acc, grade, index) => {
//             if (index % 2 === 0) {
//               acc.push(
//                 <SwiperSlide key={`slide-${index}`} className="lg:h-full">
//                   <div className="flex w-full overflow-hidden">
//                     <div className="overflow-hidden lg:w-5/12 m-2 bg-white w-full md:w-[calc(50%-1rem)] rounded-br-[4rem] h-[50vh] rounded-tr-[4rem]">
//                       <h1 className="text-center text-3xl font-semibold border-b border-black p-2">
//                         Class {grade}
//                       </h1>
//                       <div className="class-student-name">
//                         <table className="px-2 w-full overflow-hidden">
//                           <tbody>
//                             <tr>{renderStudentNames(classStudents[grade])}</tr>
//                           </tbody>
//                         </table>
//                       </div>
//                     </div>
//                     {classes[index + 1] && (
//                       <div className="overflow-hidden lg:w-5/12 m-2 bg-white w-full md:w-[calc(50%-1rem)] rounded-br-[4rem] h-[50vh] rounded-tr-[4rem]">
//                         <h1 className="text-center text-3xl font-semibold border-b border-black p-2">
//                           Class {classes[index + 1]}
//                         </h1>
//                         <div className="class-student-name overflow-hidden rounded-br-[4rem] h-[50vh] rounded-tr-[4rem]">
//                           <table className="px-2 w-full">
//                             <tbody>
//                               <tr>
//                                 {renderStudentNames(
//                                   classStudents[classes[index + 1]]
//                                 )}
//                               </tr>
//                             </tbody>
//                           </table>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </SwiperSlide>
//               );
//             }
//             return acc;
//           }, [])}
//         </Swiper>
//       </div>
//     </div>
//   );
// };

// export default ClassTable;

// import React, { useContext, useEffect, useState } from "react";
// import { ArrivalContext } from "../../context/arrivalScreen/arrivalcontext";
// import Slider from "react-slick";

// const renderStudentNames = (students = []) => {
//   return students.map((student) => (
//     <td
//       key={student.id}
//       className="inline-block border-r border-b border-black w-1/2 text-lg px-2 py-1"
//     >
//       {`${student.name} (${student.classGrade} - ${student.section})`}
//     </td>
//   ));
// };

// const ClassTable = ({ setExcessStudents }) => {
//   const { arrivals } = useContext(ArrivalContext);
//   const [classes, setClasses] = useState([]);
//   const [classStudents, setClassStudents] = useState({});

//   useEffect(() => {
//     if (arrivals.length > 0) {
//       const uniqueClasses = [
//         ...new Set(
//           arrivals
//             .filter((item) => item.arrivalType === "Student" && item.student)
//             .map((item) => item.student.classGrade)
//         ),
//       ];

//       uniqueClasses.sort((a, b) => a - b);

//       setClasses(uniqueClasses);
//     }
//   }, [arrivals]);

//   useEffect(() => {
//     if (classes.length > 0) {
//       const excess = [];
//       const newClassStudents = {};

//       classes.forEach((grade) => {
//         const studentsInGrade = arrivals
//           .filter(
//             (item) =>
//               item.arrivalType === "Student" &&
//               item.student?.classGrade === grade
//           )
//           .map((item) => item.student);

//         if (studentsInGrade.length > 12) {
//           excess.push(...studentsInGrade.slice(14));
//           newClassStudents[grade] = studentsInGrade.slice(0, 12);
//         } else {
//           newClassStudents[grade] = studentsInGrade;
//         }
//       });

//       setExcessStudents(excess);
//       setClassStudents(newClassStudents);
//     }
//   }, [arrivals, classes, setExcessStudents]);

//   const settings1 = {
//     slidesToShow: 1,
//   };

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 1000,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     swipeToSlide: true,
//     pauseOnHover: false,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     vertical: true, // Default horizontal
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 5,
//           vertical: true,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 4,
//           vertical: false,
//         },
//       },
//       {
//         breakpoint: 576,
//         settings: {
//           slidesToShow: 3,
//           vertical: false,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="h-[50vh] mt-5 overflow-hidden mx-5 lg:h-full lg:w-full">
//       <div className="overflow-hidden h-full flex justify-center w-full lg:h-full">
//         <div className="h-full flex justify-center w-full items-center lg:h-full">
//           <Slider {...settings}>
//             {classes.reduce((acc, grade, index) => {
//               if (index % 2 === 0) {
//                 acc.push(
//                   <div key={`slide-${index}`} className="lg:h-full">
//                     <div className="flex w-full overflow-hidden">
//                       <div className="overflow-hidden lg:w-5/12 m-2 bg-white w-full md:w-[calc(50%-1rem)] rounded-br-[4rem] h-[50vh] rounded-tr-[4rem]">
//                         <h1 className="text-center text-3xl font-semibold border-b border-black p-2">
//                           Class {grade}
//                         </h1>
//                         <div className="class-student-name">
//                           <table className="px-2 w-full overflow-hidden">
//                             <tbody>
//                               <tr>
//                                 {renderStudentNames(classStudents[grade])}
//                               </tr>
//                             </tbody>
//                           </table>
//                         </div>
//                       </div>
//                       {classes[index + 1] && (
//                         <div className="overflow-hidden lg:w-5/12 m-2 bg-white w-full md:w-[calc(50%-1rem)] rounded-br-[4rem] h-[50vh] rounded-tr-[4rem]">
//                           <h1 className="text-center text-3xl font-semibold border-b border-black p-2">
//                             Class {classes[index + 1]}
//                           </h1>
//                           <div className="class-student-name overflow-hidden rounded-br-[4rem] h-[50vh] rounded-tr-[4rem]">
//                             <table className="px-2 w-full">
//                               <tbody>
//                                 <tr>
//                                   {renderStudentNames(
//                                     classStudents[classes[index + 1]]
//                                   )}
//                                 </tr>
//                               </tbody>
//                             </table>
//                           </div>
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 );
//               }

//               return acc;
//             }, [])}
//           </Slider>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default ClassTable;

import React, { useContext, useEffect, useState } from "react";
import { ArrivalContext } from "../../context/arrivalScreen/arrivalcontext";
import Slider from "react-slick";

const renderStudentNames = (students = []) => {
  const sortedStudents = [...students].sort((a, b) =>
    a.student.name.localeCompare(b.student.name)
  );

  return sortedStudents.map((stu) => (
    <td
      key={stu.id}
      className="inline-block border-r border-b border-black w-1/2 text-sm px-2 py-1 md:text-lg"
    >
      {`${stu.student.name} (${stu.student.classGrade} - ${stu.student.section})`}
    </td>
  ));
};

const ClassTable = ({ setExcessStudents }) => {
  const { arrivals } = useContext(ArrivalContext);
  const [arrival, setArrival] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classStudents, setClassStudents] = useState({});
  useEffect(() => {
    if (arrivals.length > 0) {
      const uniqueClasses = [
        ...new Set(
          arrivals
            .filter((item) => item.arrivalType === "Student" && item.student)
            .map((item) => item.student.classGrade)
        ),
      ];

      uniqueClasses.sort((a, b) => a - b);

      setClasses(uniqueClasses);
    }
  }, [arrivals]);

  const ArrivalCon = async () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      const response = await fetch(
        "https://apischool.lineup.pk/arival/getAllArrivals",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setArrival(result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (classes.length > 0) {
      const excess = [];
      const newClassStudents = {};

      classes.forEach((grade) => {
        const studentsInGrade = arrivals
          .filter(
            (item) =>
              item.arrivalType === "Student" &&
              item.student?.classGrade === grade
          )
          .map((item) => item);
        if (studentsInGrade.length > 12) {
          // excess.push(...studentsInGrade.slice(14));
          // newClassStudents[grade] = studentsInGrade.slice(0, 12);
          const latestStudents = studentsInGrade.slice(-12);
          excess.push(...studentsInGrade.slice(0, -12));
          newClassStudents[grade] = latestStudents;
        } else {
          newClassStudents[grade] = studentsInGrade;
        }
      });

      setExcessStudents(excess);
      setClassStudents(newClassStudents);
    }
  }, [arrivals, classes, setExcessStudents]);

  useEffect(() => {
    // ArrivalCon()
  }, [arrivals]);
  const settings1 = {
    slidesToShow: 1,
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    pauseOnHover: false,
    autoplay: true,
    autoplaySpeed: 8000,
    vertical: true, // Default horizontal
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: true,
        },
      },
    ],
  };
  return (
    <div className="h-[52vh] mt-5 overflow-hidden mx-2 lg:mx-5 lg:h-full lg:w-full">
      <Slider {...settings}>
        {classes.reduce((acc, grade, index) => {
          if (index % 2 === 0) {
            acc.push(
              <div key={`slide-${index}`} className="lg:h-full">
                <div className="flex flex-col md:flex-row w-full overflow-hidden">
                  <div
                    className="overflow-hidden m-2 bg-white rounded-br-[4rem] h-[50vh] rounded-tr-[4rem] md:w-[50%] lg:w-[50%]
                      xl:w-[50%]"
                  >
                    <h1 className="text-center text-xl font-semibold border-b border-black p-2">
                      Class {grade}
                    </h1>
                    <div className="class-student-name">
                      <table className="px-2 w-full overflow-hidden">
                        <tbody>
                          <tr>{renderStudentNames(classStudents[grade])}</tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {classes[index + 1] && (
                    <div className="m-2 bg-white w-full rounded-br-[4rem] h-[50vh] rounded-tr-[4rem] md:w-[calc(50%)] lg:w-[50%] xl:w-[50%]">
                      <h1 className="text-center text-xl font-semibold border-b border-black p-2">
                        Class {classes[index + 1]}
                      </h1>
                      <div className="class-student-name overflow-hidden rounded-br-[4rem] h-[50vh] rounded-tr-[4rem]">
                        <table className="px-2 w-full">
                          <tbody>
                            <tr>
                              {renderStudentNames(
                                classStudents[classes[index + 1]]
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          }

          return acc;
        }, [])}
      </Slider>
    </div>
  );
};
export default ClassTable;
