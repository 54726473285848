import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import SCLLOGO from "../../assets/schoollogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { RiLogoutBoxLine } from "react-icons/ri";
import ChangePasswordModal from "./changePasswordModal";

const Header = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  let title = "School Management System";
  switch (location.pathname) {
    case "/":
      title = "School Management System";
      break;
    case "/dashboard":
      title = "Student Lineup (ver. 1.1.0)";
      break;
    case "/students":
      title = "Student Management";
      break;
    case "/bus":
      title = "Bus Management";
      break;
    case "/arrivals":
      title = "Arrival Management";
      break;
    case "/announcements":
      title = "Announcements";
      break;
    case "/registerEmployee":
      title = "User Registration";
      break;
    case "/attendance":
      title = "Student Attendance";
      break;
    case "/teachers":
        title = "Teachers";
        break;
    case "/teacherattendance":
        title = "Teachers Attendance";
        break;
    case "/busReport":
      title = "Bus Report";
      break;
    case "/studentReport":
      title = "Student Report";
      break;
    case "/attendanceReport":
      title = "Attendance Report";
      break;
    case "/teacherReport":
      title = "Teacher Report";
      break;
    default:
      title = "Page Not Found";
      break;
  }
  const isUser = JSON.parse(localStorage.getItem("userdetails"));
  const findAdmin = isUser?.userType === "admin";
  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };
  return (
    <header className="sticky top-0 w-full bg-dark z-50">
      <div className="mx-auto flex items-center justify-between h-16 ">
        {findAdmin ? (
          <button
            className="text-white focus:outline-none px-3"
            type="button"
            onClick={toggleSidebar}
          >
            <FaBars size={24} />
          </button>
        ) : (
          <div className="flex items-center gap-2">
            <button
              className="text-white focus:outline-none px-3"
              onClick={handleLogout}
            >
              <RiLogoutBoxLine size={24} />
            </button>
            <ChangePasswordModal />
          </div>
        )}

        <h1 className=" text-white text-lg tracking-widest uppercase font-semibold mx-auto">
          {title}
        </h1>

        <div className="flex items-center gap-3">
          {findAdmin && <ChangePasswordModal />}

          {/* <ChangePasswordModal /> */}
          <img
            className="object-cover h-16   "
            src={SCLLOGO}
            alt="School Logo"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
