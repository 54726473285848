import React, { useState, useEffect, useRef } from "react";
import image from "../assets/loginImg.jpg";
import "../pages/CSS/bus.css";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaDownload, FaSearch } from "react-icons/fa";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CustomPdf from "./reports/Pdf";
import { message } from "antd";
import PostErorr from "../utils/errorlog";
import { sectionColors } from "../components/constants/constant";
import InfiniteScroll from "react-infinite-scroll-component";
import Footer from "../components/common/Footer";
import TeacherPDFCard from "./reports/teacherPDFCard";
function TeachersAttendance() {
  const contentRef = useRef();
  const [isClicked, setIsClicked] = useState(false);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [education, setEducation] = useState("");
  const [subjects, setSubjects] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const token = localStorage.getItem("authToken");
  console.log(selectedTeacher);
  const fetchStudents = async () => {
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/teacher/getAllTeacher?page=${page}&limit=15`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
        }
      );
      const data = await response.json();
      setTeachers([...teachers, ...data.teachers]);
      setPage(page + 1);
      // If no more students are available
      if (data.teachers.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };
  useEffect(() => {
    fetchStudents(page); // Fetch students when the component mounts
  }, []);
  useEffect(() => {
    generatePdf();
  }, [isClicked]);

  const handleStudentSelect = (teacher) => {
    setSelectedTeacher(teacher);
    setFirstName(teacher.firstName);
    setLastName(teacher.lastName);
    setEducation(teacher.education);
    setSubjects(teacher.subjects);
  };

  const inputRef = useRef();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "education":
        setEducation(value);
        break;
      case "subjects":
        setSubjects(value);
        break;
      default:
        break;
    }
  };
  const handleUpdateStudent = async () => {
    if (!selectedTeacher) return;
    const updatedStudent = {
      firstName,
      lastName,
      education,
      subjects,
    };
    try {
      const response = await fetch(
        `https://apischool.lineup.pk/teacher/editteacher/${selectedTeacher._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
          body: JSON.stringify(updatedStudent),
        }
      );
      const data = await response.json();
      if (response.ok) {
        fetchStudents(); // Refresh the list
        setSelectedTeacher(null);
        setFirstName("");
        setLastName("");
        setEducation("");
        setSubjects("");
        setTimeout(() => {
          resetButton();
        }, [2000]);
        message.success("Teacher updated successfully");
      } else {
        message.error(data.error);
      }
    } catch (error) {
      console.error("Error updating student:", error);
      PostErorr(error);
    }
  };
  const isAuthenticated = localStorage.getItem("authToken") !== null;

  if (!isAuthenticated) {
    // If the user is not authenticated, do not render the component
    return null;
  }

  // this function should be there where ypu creates ref
  const generatePdf = async () => {
    if (selectedTeacher !== null) {
      const content = contentRef.current;
      const canvas = await html2canvas(content, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      const pdfBlob = pdf.output("blob");
      const pdfFile = new Blob([pdfBlob], { type: "application/pdf" });
      pdf.save(selectedTeacher.firstName?.toUpperCase() + "- ID.pdf");
    }
  };
  const searchFunction = async (name) => {
    try {
      const trimmedValue = name.trim();
      const query = `firstName=${trimmedValue}`;
      const response = await fetch(
        `https://apischool.lineup.pk/teacher/searchteacher?${query}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
        }
      );
      const data = await response.json();
      setTeachers(data);
    } catch (error) {}
  };
  const resetButton = async () => {
    setTeachers([]);
    const response = await fetch(
      `https://apischool.lineup.pk/teacher/getAllTeacher?page=1&limit=15`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${token}`,
        },
      }
    );
    const data = await response.json();
    setTeachers(data.teachers);
  };
  return (
    <>
      <div className="min-h-screen flex flex-col items-center" style={mainDiv}>
        {selectedTeacher && (
          <div className="w-100 flex items-center justify-center mt-4">
            <div className="flex flex-col md:flex-row w-[70%] justify-center gap-2">
              <div w>
                <label className="bg-black text-white text-center w-full">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="firstName"
                  value={firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="bg-black text-white text-center w-full">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Class"
                  name="lastName"
                  value={lastName}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="bg-black text-white text-center w-full">
                  Education
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Section"
                  name="education"
                  value={education}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="bg-black text-white text-center w-full">
                  Subjects
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Section"
                  name="subjects"
                  value={subjects}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex justify-end items-end">
                <button className="btn btn-dark" onClick={handleUpdateStudent}>
                  Update
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          class={`w-full md:w-3/4 flex justify-end ${
            !selectedTeacher && "mt-10"
          }`}
        >
          <div className="flex w-full items-center md:w-2/4 lg:w-1/4">
            <input
              class="border-2 border-black justify-end items-end form-control m-2"
              type="text"
              placeholder="Search..."
              name="search"
              ref={inputRef}
              onChange={handleInputChange}
            />
            <button
              className="border-2 border-black w-20 h-10 flex justify-center items-center rounded mr-2"
              onClick={() => searchFunction(inputRef.current.value)}
            >
              <FaSearch />
            </button>
            <button
              className="border-2 border-black w-20 h-10 flex justify-center items-center rounded"
              onClick={resetButton}
            >
              Reset
            </button>
          </div>
        </div>
        <div className="w-full md:w-3/4 border border-secondary mt-2">
          <InfiniteScroll
            dataLength={teachers.length}
            next={teachers.length >= 15 && fetchStudents}
            hasMore={hasMore}
            loader={
              teachers.length >= 15 && (
                <div className={`loader1 mx-auto my-2`}></div>
              )
            }
            endMessage={
              <p style={{ textAlign: "center" }}>All Teachers loaded</p>
            }
          >
            <table
              className="table-main w-100 h-auto border border-secondary text-center"
              style={table}
            >
              <thead style={tableHead}>
                <tr className="text-[12px] md:text-[16px]" style={border}>
                  <th style={border}>ID</th>
                  <th style={border}>First Name</th>
                  <th style={border}>Last Name</th>
                  <th style={border}>Education</th>
                  <th style={border}>Generate ID Card</th>
                </tr>
              </thead>
              <tbody style={tableBody}>
                {teachers.map((teacher) => (
                  <tr
                    className="hover:bg-[#fff] text-[12px] md:text-[16px]"
                    key={teacher.id}
                    onClick={() => handleStudentSelect(teacher)}
                    style={{ cursor: "pointer" }}
                  >
                    <td style={border}>{teacher.userId}</td>
                    <td className="capitalize" style={border}>
                      {teacher.firstName}
                    </td>
                    <td className="capitalize" style={border}>
                      {teacher.lastName}
                    </td>
                    <td style={border}>{teacher.education}</td>
                    <td
                      className="capitalize flex text-blue-800 w-100 justify-center gap-2 cursor-pointer hover:text-[#000]"
                      style={border}
                      onClick={() => setIsClicked(!isClicked)} // Pass the student object directly
                    >
                      <FaDownload className="h-8" />
                    </td>
                    <span style={{ position: "fixed", left: "-99999px" }}>
                      <TeacherPDFCard
                        details={selectedTeacher}
                        contentRef={contentRef}
                      />
                    </span>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
      <Footer />
    </>
  );
}

const tableHead = {
  backgroundColor: "#1b1e25",
  color: "white",
  height: "10%",
};
const tableBody = {
  backgroundColor: "#E7E9E9",
};
const border = {
  border: "1px solid black",
  borderCollapse: "collapse",
};
const mainDiv = {
  backgroundImage: `url(${image})`,
  backgroundSize: "100vh",
  backgroundPosition: "center",
};
const table = {
  tableLayout: "fixed",
};

export default TeachersAttendance;
