import React from "react";
import footerLogo from "../../assets/logo.png";

const Footer = () => {
  return (
    <footer className="flex items-center justify-center bg-dark text-light py-2 w-full h-20">
      <div className="flex items-center justify-center h-full">
        <p className="my-auto">Powered By</p>
        &nbsp;&nbsp;
        <img src={footerLogo} alt="Logo" className="h-10" />
      </div>
    </footer>
  );
};

export default Footer;
