import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import image from "../assets/loginImg.jpg";
import { Select, message } from "antd";
import Swal from "sweetalert2";
import PostErorr from "../utils/errorlog";
import Footer from "../components/common/Footer";
const Registration = () => {
  const [selectedRole, setSelectedRole] = useState("");
  function capitalizeName(name) {
    return name.replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await fetch(
        "https://apischool.lineup.pk/auth/createuser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        console.log("Registration successful:", responseData);
        Swal.fire({
          title: `User ID ${responseData.userSave.userId} Registered Successfully!`,
          text: `${capitalizeName(
            responseData.userSave.firstName
          )} has been registered successfully and User ID is ${
            responseData.userSave.userId
          }, please note it down for login purpose.`,
          icon: "success",
        });
        reset();
        setSelectedRole("");
      } else {
        if (responseData.errors && Array.isArray(responseData.errors)) {
          // Iterate over each error and display it
          responseData.errors.forEach((error) => {
            console.log("Registration failed:", error.msg);
            message.error(error.msg);
          });
        } else {
          console.log("Registration failed:", responseData.error);
          message.error(responseData.error || "Something went wrong");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Something went wrong");
      PostErorr(error);
    }
  };
  return (
    <>
      <div
        className="flex justify-center items-center h-[100vh] overflow-hidden bg-cover bg-center"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="w-[450px] p-4  border border-gray-300 rounded-lg bg-white">
          <h2 className="pb-4 font-semibold tracking-wider uppercase text-xl text-center ">
            Employee Registration
          </h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <Controller
                name="userType"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="w-full text-lg"
                    size="large"
                    placeholder="Select Role"
                    // defaultValue={"Employee"}
                    value={field.value || "Select Role"}
                    showSearch
                    allowClear
                    options={[
                      { label: "Admin", value: "admin" },
                      { label: "Teacher", value: "teacher" },
                      { label: "Gate Keeper", value: "gatekeeper" },
                    ]}
                    onChange={(value) => {
                      field.onChange(value);
                      setSelectedRole(value);
                    }}
                  />
                )}
              />
              {errors.userType && (
                <p className="mt-2 text-red-500 text-sm">
                  * Please select who you are ?
                </p>
              )}
            </div>
            <div className="mb-4">
              <input
                type="text"
                placeholder={
                  errors.name ? "firstName is required" : "Full Name"
                }
                className={`w-full p-3 text-lg border rounded-md ${
                  errors.name
                    ? "border-red-500 placeholder-red-500 placeholder:text-base"
                    : "border-gray-300 bg-gray-100"
                }`}
                {...register("firstName", { required: true })}
              />
            </div>
            {selectedRole == "teacher" && (
              <div className="mb-4">
                <input
                  type="text"
                  placeholder={errors.name ? "Name is required" : "Last Name"}
                  className={`w-full p-3 text-lg border rounded-md ${
                    errors.name
                      ? "border-red-500 placeholder-red-500 placeholder:text-base"
                      : "border-gray-300 bg-gray-100"
                  }`}
                  {...register("lastName", { required: true })}
                />
              </div>
            )}
            <div className="mb-4">
              <input
                type="email"
                placeholder={
                  errors.email ? "Valid email is required" : "Email Address"
                }
                className={`w-full p-3 text-lg border rounded-md ${
                  errors.email
                    ? "border-red-500 placeholder-red-500 placeholder:text-base"
                    : "border-gray-300 bg-gray-100"
                }`}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Enter a valid email",
                  },
                })}
              />
            </div>
            {selectedRole == "teacher" && (
              <div className="mb-4">
                <input
                  type="text"
                  placeholder={
                    errors.name ? "Education is required" : "Enter Education"
                  }
                  className={`w-full p-3 text-lg border rounded-md ${
                    errors.name
                      ? "border-red-500 placeholder-red-500 placeholder:text-base"
                      : "border-gray-300 bg-gray-100"
                  }`}
                  {...register("education", { required: true })}
                />
              </div>
            )}
            {/* {selectedRole == "teacher" && <div className="mb-4">
              <input
                type="text"
                placeholder={errors.name ? "Subject is required" : "Enter Subject"}
                className={`w-full p-3 text-lg border rounded-md ${
                  errors.name
                    ? "border-red-500 placeholder-red-500 placeholder:text-base"
                    : "border-gray-300 bg-gray-100"
                }`}
                {...register("subjects", { required: true })}
              />
            </div>} */}
            <div className="mb-4">
              <input
                type="password"
                placeholder={
                  errors.password ? "Password is required" : "Password"
                }
                className={`w-full p-3 text-lg border rounded-md ${
                  errors.password
                    ? "border-red-500 placeholder-red-500 placeholder:text-base"
                    : "border-gray-300 bg-gray-100"
                }`}
                {...register("password", { required: true })}
              />
            </div>
            {selectedRole == "teacher" && (
              <div className="mb-4">
                <Controller
                  name="subjects"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w-full text-lg h-[60px] bg-gray-100 !important"
                      size="large"
                      placeholder="Select Subject"
                      // defaultValue={"Employee"}
                      value={field.value || "Select Subject"}
                      showSearch
                      allowClear
                      options={[
                        { label: "English", value: "English" },
                        { label: "Urdu", value: "Urdu" },
                        { label: "Math", value: "Math" },
                        { label: "Computer", value: "Computer" },
                        { label: "Sceince", value: "Sceince" },
                        { label: "General Knowlege", value: "GeneralKnowlege" },
                        { label: "Islamiat", value: "Islamiat" },
                      ]}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    />
                  )}
                />
                {errors.userType && (
                  <p className="mt-2 text-red-500 text-sm">
                    * Please select who you are ?
                  </p>
                )}
              </div>
            )}
            <button
              type="submit"
              className="w-full py-3 text-lg bg-gray-800 text-white rounded-md transition duration-300 hover:bg-gray-700"
            >
              Register
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Registration;
