import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import schoolLogo from "../../assets/logoo.png";
import { IoMdCloudDownload } from "react-icons/io";
import Footer from "../../components/common/Footer";
import PostErorr from "../../utils/errorlog";
import ReportTitle from "../../components/ReportTitle";
const TeacherReport = () => {
  const [allTeachers, setAllTeachers] = useState([]);
  const [chooseAttendance, setChooseAttendance] = useState("both");
  const [dates, setDates] = useState(new Date().toLocaleDateString("en-GB"));
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [dateAttendance, setDateAttendance] = useState([]);
  const token = localStorage.getItem("authToken");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const reportRef = useRef();
  const headerRef = useRef();
  const headerRef2 = useRef();
  const footerRef = useRef();
  const handleDateChange = (event) => {
    const formatDate = event.target.value.split("-").reverse().join("/");
    console.log(formatDate);
    setDates(formatDate);
    setDate(event.target.value);
  };
  useEffect(() => {
    fetchTeachers();
  }, []);
  const fetchTeachers = async () => {
    try {
      const response = await fetch("http://localhost:3001/teacher/AllTeacher", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${token}`,
        },
      });
      const data = await response.json();
      setAllTeachers(data);
    } catch (error) {
      console.error("Error fetching buses:", error.message);
      PostErorr(error);
    }
  };
  // const fetchAttendance = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://apischool.lineup.pk/AttendanceNew/getAttendances?date=${date}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "auth-token": `${token}`,
  //         },
  //       }
  //     );
  //     const data = await response.json();
  //     console.log(data);
  //     setDateAttendance(data);
  //   } catch (error) {
  //     console.error("Error fetching leave students:", error.message);
  //     PostErorr(error);
  //   }
  // };

  const handleDownloadPdf = async () => {
    const input = reportRef.current;
    const footervalues = footerRef.current;
    const headerValues = headerRef.current;
    const header2Values = headerRef2.current;

    // Capture the header and footer as images
    const headerCanvas = await html2canvas(headerValues, { scale: 2 });
    const headerData = headerCanvas.toDataURL("image/png");
    const header2Canvas = await html2canvas(header2Values, { scale: 2 });
    const header2Data = header2Canvas.toDataURL("image/png");
    const footerCanvas = await html2canvas(footervalues, { scale: 2 });
    const footerData = footerCanvas.toDataURL("image/png");

    // Create a new PDF document
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const headerHeight = 35; // Adjust based on your header height
    const footerHeight = 20; // Adjust based on your footer height
    const contentHeight = pageHeight - headerHeight - footerHeight;

    // Get all rows from the table
    const rows = Array.from(input.querySelectorAll("tr"));
    const totalRows = rows.length;
    const rowsPerPage = 22;
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    for (let i = 0; i < totalPages; i++) {
      const startRow = i * rowsPerPage;
      const endRow = Math.min(startRow + rowsPerPage, totalRows);
      const tableChunk = document.createElement("table");
      tableChunk.style.width = input.offsetWidth + "px"; // Ensure the table width matches the original table

      rows.slice(startRow, endRow).forEach((row) => {
        const rowClone = row.cloneNode(true);
        tableChunk.appendChild(rowClone);
      });

      document.body.appendChild(tableChunk);
      const chunkCanvas = await html2canvas(tableChunk, { scale: 2 });
      document.body.removeChild(tableChunk);

      const imgData = chunkCanvas.toDataURL("image/png");

      // Add header
      if (i === 0) {
        pdf.addImage(headerData, "PNG", 0, 0, imgWidth, headerHeight);
      } else {
        pdf.addImage(header2Data, "PNG", 0, 0, imgWidth, headerHeight);
      }

      // Add main content
      const imgHeight = (chunkCanvas.height * imgWidth) / chunkCanvas.width;
      console.log(chunkCanvas.height);
      const contentY = headerHeight + (contentHeight - imgHeight) / 2; // Center the content vertically

      pdf.addImage(imgData, "PNG", 0, headerHeight, imgWidth, imgHeight);

      // Add footer
      pdf.setFillColor(255, 255, 255);
      pdf.rect(0, pageHeight - footerHeight, imgWidth, footerHeight, "F");
      pdf.addImage(
        footerData,
        "PNG",
        0,
        pageHeight - footerHeight,
        imgWidth,
        footerHeight
      );
      pdf.setFontSize(8);
      const pageNumText = `Page ${i + 1} of ${totalPages}`;
      const pageNumTextWidth = pdf.getTextWidth(pageNumText);
      pdf.text(pageNumText, imgWidth - pageNumTextWidth - 4, pageHeight - 5);

      if (i < totalPages - 1) {
        pdf.addPage();
      }
    }
    pdf.save(`atendance_report-${dates}.pdf`);
  };
  const submithandler = async (date) => {
    console.log(date);
    try {
      const response = await fetch(
        `http://localhost:3001/newteacherAttendance/TeacherAttendancesbyDate?date=${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": `${token}`,
          },
        }
      );
      const data = await response.json();
      setDateAttendance(data);
    } catch (error) {
      console.error("Error fetching leave students:", error.message);
      PostErorr(error);
    }
  };
  let presentTeacher = [];
  let absentTeacher = [];
  const filterPresentAbsent = dateAttendance.filter((ele, index) => {
    if (ele.attendance[0].present === true) {
      presentTeacher.push(ele);
    } else {
      absentTeacher.push(ele);
    }
  });
  console.log(presentTeacher);
  console.log(absentTeacher);
  return (
    <>
      <div className="container min-h-screen">
        <div className="flex justify-between items-center">
          <div className="flex items-center py-2 gap-2">
            <img src={schoolLogo} alt="" className="w-[50px] sm:w-[75px]" />
            <div className="flex flex-col">
              <h2 className="font-bold text-[lg] sm:text-[20px]">
                The Avicenna School
              </h2>
              <p className="text-[12px] sm:text-[16px]">
                XYZ Road Karachi Pakistan
              </p>
              <p className="text-[12px] sm:text-[16px]">
                Phone: <span>021-333456789</span>
              </p>
            </div>
          </div>
          <button
            className="w-16 h-12"
            style={{
              cursor: dateAttendance === "[]" ? "pointer" : "not-allowed",
            }}
            disabled={dateAttendance === "[]"}
          >
            <IoMdCloudDownload
              className="w-full h-full"
              onClick={handleDownloadPdf}
            />
          </button>
        </div>
        <hr />
        <div className="mt-3 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-8  lg:grid-cols-4 xl:grid-cols-4 gap-3 items-center">
          <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <h2 className="font-bold text-[lg] lg:text-xl">
              Teacher Attendance Report
            </h2>
          </div>
          <div className="flex justify-end items-center col-span-1 sm:col-span-4 lg:col-span-2">
            <span className="mr-3 font-semibold">
              As On:&nbsp;&nbsp;{dates}
            </span>
            <input
              type="date"
              id="todayDate"
              onChange={handleDateChange}
              className="date-input w-[20px] h-[24px] outline-none"
            />
          </div>

          {/* <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <select
              className="w-full h-8 border"
              onChange={(e) => setChooseAttendance(e.target.value)}
            >
              <option value="both">Both</option>
              <option value="present">Present</option>
              <option value="absent">Absent</option>
            </select>
          </div> */}

          <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <button
              className="w-full border p-1 text-white"
              onClick={() => submithandler(date)}
              style={{
                cursor: "pointer",
                backgroundColor: "#212529",
              }}
            >
              Search
            </button>
          </div>
        </div>
        <div>
          <div className="border-black border-1 my-4">
            <table className="table-auto mx-auto w-12/12 border-collapse text-sm sm:text-base md:text-lg">
              <thead>
                <tr className="border-none">
                  <th className="py-2" colSpan="3"></th>
                  <th className="py-2" colSpan="3">
                    <span className="p-2">
                      Month of {months[new Date(date).getMonth()]}
                    </span>
                  </th>
                </tr>
                <tr className="py-2 border-white"></tr>
                <tr className="text-center border-none bg-dark text-white text-[10px] sm:text-[16px] md:text-[18px]">
                  <th className="sm:py-2">Teacher ID</th>
                  <th className="sm:py-2">First Name</th>
                  <th className="sm:py-2">Last Name</th>
                  <th className="sm:py-2">Time In</th>
                  <th className="sm:py-2">Time Out</th>
                  <th className="sm:py-2">Last Present</th>
                  <th className="sm:py-2">Total Present</th>
                  <th className="sm:py-2">Total Absents</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center border-none">
                  <th className="border-b-2 border-r-2 border-t-2 border-black bg-gray-400 text-black sm:py-2 text-[10px] sm:text-[16px] md:text-[18px]">
                    Present
                  </th>
                  {presentTeacher.length > 0 && (
                    <th className="sm:py-2" colSpan={3}>
                      {presentTeacher.length} Teacher Present
                    </th>
                  )}
                </tr>
                {presentTeacher.map((teacher, index) => {
                  return (
                    <tr
                      className={`text-center border-none ${
                        index % 2 === 0 ? "bg-gray-200" : "bg-white"
                      } text-center text-[10px] sm:text-[16px] md:text-[18px]`}
                    >
                      <td>{teacher.teacherId}</td>
                      <td>{teacher.firstName}</td>
                      <td>{teacher.lastName}</td>
                      <td>{teacher.attendance[0].timeIn}</td>
                      <td>{teacher.attendance[0].timeOut}</td>
                      <td>
                        {new Date(teacher.lastPresent).toLocaleDateString(
                          "en-GB"
                        )}
                      </td>
                      <td>{teacher.totalPresent}</td>
                      <td>{teacher.totalAbsents}</td>
                    </tr>
                  );
                })}
                <tr className="text-center border-none ">
                  <th className="border-b-2 border-r-2 border-t-2 border-black bg-gray-400 text-black sm:py-2 text-[10px] sm:text-[16px] md:text-[18px]">
                    Absent
                  </th>
                  {absentTeacher.length > 0 && (
                    <th className="sm:py-2" colSpan={3}>
                      {absentTeacher.length} Teacher Absent
                    </th>
                  )}
                </tr>
                {absentTeacher.map((teacher, index) => {
                  return (
                    <tr
                      className={`text-center border-none ${
                        index % 2 === 0 ? "bg-gray-200" : "bg-white"
                      } text-center text-[10px] sm:text-[16px] md:text-[18px]`}
                    >
                      <td>{teacher.teacherId}</td>
                      <td>{teacher.firstName}</td>
                      <td>{teacher.lastName}</td>
                      <td>{teacher.attendance[0].timeIn}</td>
                      <td>{teacher.attendance[0].timeOut}</td>
                      <td>
                        {teacher.lastPresent == "1970-01-01T00:00:00.000Z"
                          ? "-------"
                          : new Date(teacher.lastPresent).toLocaleDateString(
                              "en-GB"
                            )}
                      </td>
                      <td>{teacher.totalPresent}</td>
                      <td>{teacher.totalAbsents}</td>
                    </tr>
                  );
                })}
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>
        <span
          style={{
            // position:'absolute',
            // top:'3000px',
            position: "fixed",
            left: "-9999px",
            width: "210mm",
          }}
        >
          <ReportTitle
            title="Teacher Attendance Report"
            headerRef={headerRef}
            headerRef2={headerRef2}
            date={dates}
          />
          <div ref={reportRef}>
            <div className="border-black border-1 my-4">
              <table className="table-auto mx-auto w-12/12 border-collapse text-sm sm:text-base md:text-lg">
                <thead>
                  <tr className="border-none">
                    <th className="py-2" colSpan="3"></th>
                    <th className="py-2" colSpan="3">
                      <span className="p-2">
                        Month of {months[new Date(date).getMonth()]}
                      </span>
                    </th>
                  </tr>
                  <tr className="p-1 text-center border-none  bg-gray-300 text-black">
                    <th className="sm:py-2">Teacher ID</th>
                    <th className="sm:py-2">First Name</th>
                    <th className="sm:py-2">Last Name</th>
                    <th className="sm:py-2">Time In</th>
                    <th className="sm:py-2">Time Out</th>
                    <th className="sm:py-2">Last Present</th>
                    <th className="sm:py-2">Total Present</th>
                    <th className="sm:py-2">Total Absents</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center border-none p-1">
                    <th className="border-b-2 border-r-2 border-t-2 border-black bg-gray-300 pb-2 text-black">
                      Present
                    </th>
                    {presentTeacher.length > 0 && (
                      <th className="pb-2">
                        {presentTeacher.length} Teacher Present
                      </th>
                    )}
                  </tr>
                  {presentTeacher.map((teacher, index) => {
                    return (
                      <tr
                        className={`text-center border-none ${
                          index % 2 === 0 ? "bg-gray-200" : "bg-white"
                        } text-center`}
                      >
                        <td className="pb-2">{teacher.teacherId}</td>
                        <td className="pb-2">{teacher.firstName}</td>
                        <td className="pb-2">{teacher.lastName}</td>
                        <td className="pb-2">{teacher.attendance[0].timeIn}</td>
                        <td className="pb-2">
                          {teacher.attendance[0].timeOut}
                        </td>
                        <td className="pb-2">
                          {new Date(teacher.lastPresent).toLocaleDateString(
                            "en-GB"
                          )}
                        </td>
                        <td className="pb-2">{teacher.totalPresent}</td>
                        <td className="pb-2">{teacher.totalAbsents}</td>
                      </tr>
                    );
                  })}
                  <tr className="text-center border-none ">
                    <th className="border-b-2 border-r-2 border-t-2 border-black bg-gray-300 pb-2 text-black">
                      Absent
                    </th>
                    {absentTeacher.length > 0 && (
                      <th className="pb-2" colSpan={3}>
                        {absentTeacher.length} Teacher Absent
                      </th>
                    )}
                  </tr>
                  {absentTeacher.map((teacher, index) => {
                    return (
                      <tr
                        className={`text-center border-none ${
                          index % 2 === 0 ? "bg-gray-200" : "bg-white"
                        } text-center text-[10px] sm:text-[16px] md:text-[18px]`}
                      >
                        <td className="pb-2">{teacher.teacherId}</td>
                        <td className="pb-2">{teacher.firstName}</td>
                        <td className="pb-2">{teacher.lastName}</td>
                        <td className="pb-2">{teacher.attendance[0].timeIn}</td>
                        <td className="pb-2">
                          {teacher.attendance[0].timeOut}
                        </td>
                        <td className="pb-2">
                          {teacher.lastPresent == "1970-01-01T00:00:00.000Z"
                            ? "-------"
                            : new Date(teacher.lastPresent).toLocaleDateString(
                                "en-GB"
                              )}
                        </td>
                        <td className="pb-2">{teacher.totalPresent}</td>
                        <td className="pb-2">{teacher.totalAbsents}</td>
                      </tr>
                    );
                  })}
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
          <PDFFooter footerRef={footerRef} />
        </span>
      </div>
      <Footer />
    </>
  );
};

export default TeacherReport;

const PDFFooter = ({ footerRef }) => {
  const user = JSON.parse(localStorage.getItem("userdetails"));
  return (
    <div
      ref={footerRef}
      className="w-[95vw] mx-auto fixed bottom-10 left-[-9999px] right-0"
    >
      <div className="w-full flex justify-between items-start p-3 px-4 border-t">
        <div>
          <div className="flex items-center gap-2">
            <h3>Printed On:</h3>
            <p>
              <b>{`${new Date().toLocaleDateString(
                "en-GB"
              )}-${new Date().toLocaleTimeString()}`}</b>
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <h3>printed by:</h3>
            <p className="font-bold">{user.name}</p>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};
