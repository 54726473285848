const PostErorr = async (error) => {
  try {
    const requestBody = {
      error: error,
      user: JSON.parse(localStorage.getItem("userdetails")),
    };
    const response = await fetch("https://apischool.lineup.pk/error/errors", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": "authToken",
      },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error("Error fetching students:", error);
  }
};

export default PostErorr;
