import React from "react";
import Clock from "../../components/arrivalScreen/Clock";
import { useNavigate } from "react-router-dom";
import { RiLogoutBoxLine } from "react-icons/ri";
import Logo from "../../assets/schoollogo.png";
import BG from "../../assets/banner.jpg";

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };
  return (
    <div
      className="h-[13vh] w-full m-0 bg-cover bg-no-repeat flex justify-between items-center text-white"
      style={{ backgroundImage: `url(${BG})` }}
    >
      <div  className=" bg-white h-full w-[10%]">
        <img src={Logo}  className="m-auto w-[full] h-full"alt="" />
      </div>
      <div>
        <h1 className="flex justify-center items-center overflow-hidden text-white">
          Tipu Sultan Branch
        </h1>
      </div>
      <div className="flex ">
        <Clock />
        <button
          onClick={handleLogout}
          className="text-white focus:outline-none px-3 hover:text-gray-600 transition-all duration-300"
        >
          <RiLogoutBoxLine size={24} />
        </button>
      </div>
    </div>
  );
};
export default Navbar;
